import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  tempWindowSizeState
} from '#state';

export function TempWindowSize() {
  const [windowSize, setWindowSize] =
    useRecoilState(tempWindowSizeState);

  useEffect(() => {
    localforage.setItem('tempWindowSizeState', windowSize);
  }, [windowSize]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const v = parseFloat(newValue)
    if (v >= 1) {
      setWindowSize(v);
    } else {
      setWindowSize(10);
    }
  };

  return (
    <TextField
      size='small'
      sx={{ width: '5rem' }}
      label="T Mnt"
      type="number"
      inputProps={{ min: 1 }} // set the minimum value to 1
      value={windowSize}
      onChange={handleChange}
    />
  );
}

export default TempWindowSize;