
import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, List, ListItem, ListItemText, Button } from '@mui/material';

import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { showControlsState } from '#state';
import { exchangeInfoState } from '#state/data';
import { StateFilterStrat } from './controls/StateFilterStrat'
import { StateFilterReal } from './controls/StateFilterReal'
import {
  getWsActions,
  authorizedState,
  tradingStatusState,
  tradesDetailsState,
  tradesUpdateState,
  miniTickersState,
  miniTickersUpdateState,
} from '#state/trades';
import {
  stateFilterStratState,
  stateFilterRealState,
} from '#state/gui'

// const defaultFee = 0.001 // 0.00065 // 0.00026
const marketFee = 0.0005
const limitFee = 0.0002
const cashbackRate = 0.35

export function State(props) {
  const tradesDetails = useRecoilValue(tradesDetailsState);
  const tradesUpdate = useRecoilValue(tradesUpdateState);
  const miniTickers = useRecoilValue(miniTickersState);
  const miniUpdate = useRecoilValue(miniTickersUpdateState);
  const filterStrat = useRecoilValue(stateFilterStratState)
  const filterReal = useRecoilValue(stateFilterRealState)

  const [items, setItems] = useState([]);
  const [totalResult, setTotalResult] = useState(0)
  const [totalPnl, setTotalPnl] = useState(0)
  const [totalFee, setTotalFee] = useState(0)
  const [totalCashback, setTotalCashback] = useState(0)
  const [positions, setPositions] = useState(0)

  useEffect(() => {
    const run = async () => {
      const entries = tradesDetails.entries()
      let tPnl = 0
      let tFee = 0
      let tCashback = 0
      let positionCount = 0

      const items = []
      for (const [key, state] of entries) {
        const parts = key.split(':')
        const side = parts[0]
        const symbol = parts[1]

        const ticker = miniTickers.get(symbol)

        const strat = state?.strategy || state?.selectedStrategy
        const strategy = (strat === 'breakout' || strat === 'bounce:hedge') ? 'breakout' : 'bounce';
        const allowStrat = filterStrat === 'all' || filterStrat === strategy

        const allowReal = filterReal === 'all'
          || (filterReal === 'real' && state?.position)
          || (filterReal === 'paper' && state?.activeOrder)

        if (allowStrat && allowReal) {
          if (state?.activeOrder) {
            const info = getPNL(state, ticker)
            const { pnl, total, fee, cashback } = info
            tPnl += pnl
            tFee += fee
            tCashback += cashback
            positionCount++
          }
        }

        const item = <StateItem key={key} symbol={symbol} side={side} state={state} ticker={ticker} tradesUpdate={tradesUpdate} />
        items.push(item)
      }


      setTotalPnl(tPnl)
      setTotalFee(tFee)
      setTotalCashback(tCashback)
      setTotalResult(tPnl - tFee + tCashback)
      setPositions(positionCount)
      setItems(items)
    }
    run();
  }, [tradesDetails, tradesUpdate, miniTickers, miniUpdate, filterStrat, filterReal]);

  return (
    <>
      <Box sx={{
        mt: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        <StateControls />

        <Box sx={{
          mt: 1,
          ml: 2,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: 1,
        }}>

          <Box sx={{
            mt: { xs: 1, lg: 0 },
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1,
          }}>
            <StateFilterStrat />
            <StateFilterReal />
          </Box>

          <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
            // flexBasis: { xs: '100%', lg: '10rem' },
          }}>
            <Box sx={{
              ml: { xs: 1, lg: 1 },
              // flexBasis: { xs: '100%', lg: '10rem' },
            }}>
              Positions: {positions}
            </Box>
            <Box sx={{
              ml: { xs: 1, lg: 1 },
              // flexBasis: { xs: '100%', lg: '10rem' },
              color: totalResult > 0 ? 'lime' : 'red'
            }}>
              Total: ${formatNumber(totalResult)}
            </Box>
            <Box sx={{
              ml: { xs: 1, lg: 1 },
              // flexBasis: { xs: '100%', lg: '10rem' },
              color: totalPnl > 0 ? 'lime' : 'red'
            }}>
              PNL: ${formatNumber(totalPnl)}
            </Box>
            <Box sx={{
              ml: { xs: 1, lg: 1 },
              // flexBasis: { xs: '100%', lg: '10rem' },
              color: totalPnl > totalFee ? 'lime' : 'red'
            }}>
              Fee: ${formatNumber(totalFee)}
            </Box>
            <Box sx={{
              ml: { xs: 1, lg: 1 },
              // flexBasis: { xs: '100%', lg: '10rem' },
              // color: totalCashback > 0 ? 'lime' : 'red'
            }}>
              Cashback: ${formatNumber(totalCashback)}
            </Box>
          </Box>

          <PositionsControls />
        </Box>

        <List sx={{ overflowY: 'auto', flexBasis: '100%', width: '100%' }}>
          {items}
        </List>
      </Box>
    </>
  );
}

export default State;

function StateItem({ symbol, side, state, ticker, tradesUpdate }) {
  const filterStrat = useRecoilValue(stateFilterStratState)
  const filterReal = useRecoilValue(stateFilterRealState)

  const strat = state?.strategy || state?.selectedStrategy
  const strategy = !strat
    ? 'any'
    : (strat === 'breakout' || strat === 'bounce:hedge')
      ? 'breakout'
      : 'bounce'

  const allowReal = filterReal === 'all'
    || (filterReal === 'real' && state?.position)
    || (filterReal === 'paper' && state?.activeOrder)

  if (strategy === 'any' || filterStrat === 'all' || filterStrat === strategy) {
    if (allowReal && (state?.activeOrder || state?.position)) { //  || state?.position
      return <PositionItem symbol={symbol} side={side} state={state} ticker={ticker} tradesUpdate={tradesUpdate} />
    } else if (state?.delayedEnter && !state?.activeOrder) {
      return <ReadyItem symbol={symbol} side={side} state={state} ticker={ticker} tradesUpdate={tradesUpdate} />
    } else {
      return null
    }
  } else {
    return null
  }
}

function PositionItem({ symbol, side, state, ticker, tradesUpdate }) {
  const exchangeInfo = useRecoilValue(exchangeInfoState);

  const now = Date.now()
  const entryTime = state.history[0]?.time || now
  const entryAge = now - entryTime
  const timeInPosition = msToHHMMSS(entryAge)

  let psdir = null
  if (state?.position?.positionSide === 'LONG') psdir = 'long'
  if (state?.position?.positionSide === 'SHORT') psdir = 'short'
  const direction = psdir || state.delayedDirection || state?.activeOrder?.direction

  const strategy = state.selectedStrategy || 'unknown'
  const lvlPrice = state.delayedLvlPrice || 0

  const isReal = state.position // || state.real

  const stopLoss = state.stopLoss || 0
  const hardStopLoss = state.hardStopLoss || 0
  const takeProfit = state.takeProfit || 0

  const { openPrice } = fixPositionPrices(state)

  const avgEntryPrice = state?.position?.entryPrice || state?.activeOrder?.price || openPrice || 0
  const positionAmount = state?.position?.positionAmount || state?.activeOrder?.amount || 0

  const currentPrice = parseFloat(ticker?.c || 0)

  const openFee = state.delayedEnterType === 'LIMIT' ? limitFee : marketFee
  const closeFee = marketFee
  const combinedFee = openFee + closeFee
  const stopFee = combinedFee
  const takeFee = openFee + limitFee

  // const combFeeCB = (combinedFee * (1 - cashbackRate))
  // const stopFeeCB = (stopFee * (1 - cashbackRate))
  // const takeFeeCB = (takeFee * (1 - cashbackRate))

  let ticksToStop = 0, ticksToHardStop = 0, trailingTicks = 0
  if (exchangeInfo && exchangeInfo?.symbols) {
    const symbolInfo = exchangeInfo.symbols?.filter((s) => s.symbol === symbol)[0]
    if (symbolInfo) {
      // const pricePrecision = symbolInfo.pricePrecision
      const tickSize = getTickSize(symbolInfo)
      ticksToStop = getPriceSteps(currentPrice, stopLoss, tickSize)
      ticksToHardStop = getPriceSteps(currentPrice, hardStopLoss, tickSize)

      // trailingTicks = Math.floor(state.SL_THRESHOLD / tickSize)
      const newStop = direction === 'long'
        ? currentPrice * (1 - state.SL_THRESHOLD)
        : currentPrice * (1 + state.SL_THRESHOLD)
      trailingTicks = getPriceSteps(currentPrice, newStop, tickSize)
    }
  }

  let change = 0, changeUSD = 0, changeFee = 0, volumeUSD = 0,
    stopChange = 0, stopChangeUSD = 0, stopChangeFee = 0,
    takeChange = 0, takeChangeUSD = 0, takeChangeFee = 0,
    changeLevel = 0

  try {
    if (direction === 'long') {
      changeLevel = currentPrice / lvlPrice * 100 - 100

      change = currentPrice / avgEntryPrice * 100 - 100
      volumeUSD = positionAmount * avgEntryPrice
      changeFee = volumeUSD * combinedFee
      changeUSD = volumeUSD * change / 100 // - changeFee

      stopChange = -avgEntryPrice / stopLoss * 100 + 100
      stopChangeFee = volumeUSD * stopFee
      stopChangeUSD = volumeUSD * stopChange / 100 - stopChangeFee

      takeChange = takeProfit / avgEntryPrice * 100 - 100
      takeChangeFee = volumeUSD * takeFee
      takeChangeUSD = volumeUSD * takeChange / 100 - takeChangeFee
    } else if (direction === 'short') {
      // changeLevel = lvlPrice / currentPrice * 100 - 100
      changeLevel = currentPrice / lvlPrice * 100 - 100

      change = avgEntryPrice / currentPrice * 100 - 100
      volumeUSD = positionAmount * avgEntryPrice
      changeFee = volumeUSD * combinedFee
      changeUSD = -volumeUSD * change / 100 + changeFee

      stopChange = stopLoss / avgEntryPrice * 100 - 100
      stopChangeFee = volumeUSD * stopFee
      stopChangeUSD = volumeUSD * stopChange / 100 + stopChangeFee

      takeChange = -avgEntryPrice / takeProfit * 100 + 100
      takeChangeFee = volumeUSD * takeFee
      takeChangeUSD = volumeUSD * takeChange / 100 + takeChangeFee
    }
  } catch (error) {

  }

  // Open Info
  const enteredPoints = state?.delayedTriggered?.length || 0
  const nonEnteredPoints = state?.delayedEnter?.length || 0
  const enterPoints = state?.delayedEnters?.length || 0
  const closestEnter = nonEnteredPoints ? state.delayedEnter[0] : null
  const enterPrice = closestEnter?.enterPrice || 0
  const enterChange = direction === 'long'
    ? currentPrice / enterPrice * 100 - 100
    : enterPrice / currentPrice * 100 - 100
  const nextEnterPriceText = enterPrice ? ` + ${formatNumber(enterChange, 2)}% ${formatNumber(enterPrice, 5)}` : ''
  const enterText = `${enteredPoints} / ${enterPoints}${nextEnterPriceText}`

  // Open Volume
  const enterUsd = closestEnter?.usdAmount || 0
  const nextEnterUsdText = enterUsd ? ` + $${formatNumber(enterUsd, 3)}` : ''
  const enterVolumeText = `$${formatNumber(volumeUSD)}${nextEnterUsdText}`

  // Close Info
  let closePoints = 0
  if (state.history && state.history?.length) {
    const closeKey = direction === 'long' ? 'closeLong' : 'closeShort';
    const closeEvents = state.history.filter(a => a.type === closeKey)
    closePoints = closeEvents.length
  }

  // Take Profit
  const tpNumText = closePoints ? ` ${closePoints}` : ''
  const tpPrc = formatNumber(takeChange, 2)
  const tpUsd = formatNumber(takeChangeUSD)
  const tpPrice = formatNumber(takeProfit, 5)
  const takeText = `TP${tpNumText}: ${tpPrc}% $${tpUsd} ${tpPrice}`
  // Stop Loss
  const slPrc = formatNumber(stopChange, 2)
  const slUsd = formatNumber(stopChangeUSD)
  const slPrice = formatNumber(stopLoss, 5)
  const stopText = `SL: ${slPrc}% $${slUsd} ${slPrice}`

  // if (stopChangeUSD > 1) {
  // console.log('stopText', stopText, stopChangeUSD)
  // }

  const isInProfit = (changeUSD + changeFee) > 0 && change > 0

  // other
  const mustBeClosed = direction === 'long' ? currentPrice < stopLoss : currentPrice > stopLoss
  const failed = stopLoss === 0

  let backgroundColor = 'transparent'
  if (isReal) {
    backgroundColor = '#00ffff22'
  }
  if (mustBeClosed || failed) {
    backgroundColor = '#ff000022'
  }

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <ListItem
      button
      dense={isSmallScreen}
      onClick={() => {
        console.log('position details', symbol, side, state)
      }}
      // selected={replay && replay._id === r._id}
      key={`rp-${symbol}-${side}-${tradesUpdate}`}
      // component={r.insertedId ? Link : undefined}
      // to={r.insertedId ? `/replays/${mode}/${r.insertedId}` : undefined}
      sx={{
        display: 'flex', flexDirection: 'column',
        maxWidth: '100vw',
        overflow: 'hidden',
        backgroundColor
      }}
    >
      <Box sx={{ mt: 0, width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', gap: 1 }}>
        <ListItemText
          primary={`${symbol} ${timeInPosition}`}
          secondary={new Date(entryTime).toLocaleString()}
          sx={{ flexBasis: { xs: '10rem', xl: '12rem' }, flexGrow: 2, mr: 2, color: direction === 'long' ? 'lime' : 'red' }}
        />
        <ListItemText
          primary={`$${formatNumber((changeUSD + changeFee), 2)}`}
          secondary={`${formatNumber(change, 2)}%`}
          sx={{ flexBasis: { xs: '3rem', xl: '3rem' }, flexGrow: 1, mr: 2, color: isInProfit ? 'lime' : 'red' }}
        />
        <ListItemText
          primary={enterVolumeText}
          secondary={enterText}
          sx={{ flexBasis: { xs: '7rem', xl: '7rem' }, flexGrow: 1, mr: { xs: 0, lg: 2 } }}
        />
        <ListItemText
          primary={takeText}
          secondary={stopText}
          sx={{
            flexBasis: '15rem',
            flexGrow: 2,
            mr: 2,
            display: { xs: 'none', lg: 'block' },
            //color: closePoints > 0 ? 'lime' : 'white'
          }}
          primaryTypographyProps={{
            sx: { color: closePoints ? 'lime' : 'white' }
          }}
          secondaryTypographyProps={{
            sx: {
              color: stopChangeUSD > 0
                ? 'lime'
                : hardStopLoss !== stopLoss
                  ? 'yellow'
                  : 'white'
            }
          }}
        />
        <ListItemText
          primary={`SL Ticks: ${ticksToStop} TSL: ${trailingTicks}`}
          secondary={`HSL Ticks: ${ticksToHardStop}`}
          sx={{ flexBasis: '13rem', mr: 2, display: { xs: 'none', lg: 'block' } }}
        />
        <ListItemText
          primary={`${strategy} ${changeLevel.toFixed(2)}% `}
          secondary={`lvl ${lvlPrice.toPrecision(5)}`}
          sx={{ flexBasis: { xs: '8rem', xl: '8rem' }, mr: 2 }}
        />
        <ListItemText
          primary={`current ${formatNumber(currentPrice, 5)}`}
          secondary={`${direction} ${formatNumber(avgEntryPrice, 5)}`}
          sx={{ flexBasis: '10rem', mr: 2, display: { xs: 'none', lg: 'block' } }}
        />
        <Button
          // disabled={loading || !authorized}
          sx={{ flexBasis: { xs: '2rem', xl: '2rem' } }}
          color={'warning'}
          onClick={() => {
            const wsapi = getWsActions()
            if (wsapi) {
              console.log('Close')
              wsapi.sendJsonMessage({
                action: 'closePosition',
                symbol,
                side: direction === 'long' ? 'LONG' : 'SHORT',
              })
            } else {
              console.log('WSAPI not ready')
            }
          }}>
          Close
        </Button>
      </Box>

    </ListItem>
  )
}

function ReadyItem({ symbol, side, state, ticker, tradesUpdate }) {
  if (!state) return null

  const direction = state.delayedDirection || state.activeOrder?.direction || 'unknown'
  const strategy = state.selectedStrategy || 'unknown'
  const lvlPrice = state.delayedLvlPrice || 0
  const nonEnteredPoints = state.delayedEnter?.length || 0
  const enterPoints = state.delayedEnters?.length || 0

  const closestEnter = state?.delayedEnter && state?.delayedEnter[0] || 0
  const enterPrice = closestEnter?.enterPrice || 0

  const stopLoss = state.stopLoss || 0
  const hardStopLoss = state.hardStopLoss || 0
  const takeProfit = state.takeProfit || 0

  const currentPrice = parseFloat(ticker?.c || 0)

  let change, changeUSD, volumeUSD
  try {
    if (direction === 'long') {
      change = currentPrice / enterPrice * 100 - 100
    } else if (direction === 'short') {
      change = enterPrice / currentPrice * 100 - 100
    } else {
      change = 0
    }
  } catch (error) {
    change = 0
  }

  return (<ListItem
    button
    //dense
    onClick={() => {
      console.log('ready position details', symbol, side, state)
    }}
    // selected={replay && replay._id === r._id}
    key={`rp-${symbol}-${side}-${tradesUpdate}`}
    // component={r.insertedId ? Link : undefined}
    // to={r.insertedId ? `/replays/${mode}/${r.insertedId}` : undefined}
    sx={{
      display: 'flex', flexDirection: 'column',
      maxWidth: '100vw',
      width: '100%',
      overflow: 'hidden',
      //backgroundColor: bgVariant % 2 ? 'transparent' : '#eeeeff11'
    }}
  >
    <Box sx={{ mt: 0, width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
      <ListItemText
        primary={`${symbol}`}
        secondary={'prepared'}
        sx={{ flexBasis: '15vw', mr: 2, color: direction === 'long' ? 'lime' : 'red' }} />
      {/* <ListItemText
        primary={`$${formatNumber(changeUSD, 2)}`}
        secondary={`${formatNumber(change, 2)}%`}
        sx={{ flexBasis: { xs: '8vw', xl: '3vw' }, mr: 2, color: change > 0 ? 'lime' : 'red' }} /> */}
      {/* <ListItemText
        primary={`$${formatNumber(volumeUSD)}`}
        secondary={`${enterPoints - nonEnteredPoints} / ${enterPoints}`}
        sx={{ flexBasis: { xs: '12vw', xl: '12vw' }, mr: { xs: 0, lg: 2 } }} /> */}
      <ListItemText
        primary={`current ${formatNumber(currentPrice, 5)}`}
        secondary={`${direction} ${formatNumber(enterPrice, 5)} ${formatNumber(change, 3)}%`}
        sx={{ flexBasis: '16vw', mr: 2, display: { xs: 'none', lg: 'block' } }} />
      <ListItemText
        primary={`${strategy}`}
        secondary={`lvl ${lvlPrice.toPrecision(5)}`}
        sx={{ flexBasis: { xs: '12vw', xl: '12vw' }, mr: 2 }} />
      <ListItemText
        primary={`TP ${formatNumber(takeProfit, 5)}`}
        secondary={`SL ${formatNumber(stopLoss, 5)}`}
        sx={{ flexBasis: '16vw', mr: 2, display: { xs: 'none', lg: 'block' } }} />
    </Box>
  </ListItem>)
}

function StateControls() {
  const showControls = useRecoilValue(showControlsState)
  const tradingStatus = useRecoilValue(tradingStatusState)
  const authorized = useRecoilValue(authorizedState)

  const [loading, setLoading] = useState(true)
  const [isTradingEnabled, setIsTradingEnabled] = useState(false)
  const [isBreakoutEnabled, setIsBreakoutEnabled] = useState(false)
  const [isBounceEnabled, setIsBounceEnabled] = useState(false)
  const [isLongEnabled, setIsLongEnabled] = useState(false)
  const [isShortEnabled, setIsShortEnabled] = useState(false)

  const togglePrefix = enabled => enabled ? 'Disable' : 'Enable'

  useEffect(() => {
    if (tradingStatus) {
      const { trading, breakout, bounce, long, short } = tradingStatus
      setLoading(false)
      setIsTradingEnabled(trading)
      setIsBreakoutEnabled(breakout)
      setIsBounceEnabled(bounce)
      setIsLongEnabled(long)
      setIsShortEnabled(short)
    }
  }, [tradingStatus])

  if (!showControls) return null

  return (<Box sx={{
    mt: 0,
    ml: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 1,
  }}>

    <Box sx={{
      ml: { xs: 0, lg: 0 },
      // flexBasis: { xs: '100%', lg: '10rem' },
    }}>
      <Button
        disabled={loading || !authorized}
        color={'error'}
        onClick={() => {
          const wsapi = getWsActions()
          if (wsapi) {
            console.log('Reload Modules')
            wsapi.sendJsonMessage({ action: 'reload' })
          } else {
            console.log('WSAPI not ready')
          }
        }}>
        Reload Mods
      </Button>

      <Button
        disabled={loading || !authorized}
        color={isTradingEnabled ? 'success' : 'warning'}
        onClick={() => {
          const wsapi = getWsActions()
          if (wsapi) {
            console.log('Toggle Trading')
            wsapi.sendJsonMessage({ action: 'trading', enable: !isTradingEnabled })
          } else {
            console.log('WSAPI not ready')
          }
        }}>
        {togglePrefix(isTradingEnabled)} Trading
      </Button>

      <Button
        disabled={loading || !authorized}
        color={isBreakoutEnabled ? 'success' : 'warning'}
        onClick={() => {
          const wsapi = getWsActions()
          if (wsapi) {
            console.log('Toggle Breakout')
            wsapi.sendJsonMessage({ action: 'breakout', enable: !isBreakoutEnabled })
          } else {
            console.log('WSAPI not ready')
          }
        }}>
        {togglePrefix(isBreakoutEnabled)} Breakout
      </Button>

      <Button
        disabled={loading || !authorized}
        color={isBounceEnabled ? 'success' : 'warning'}
        onClick={() => {
          const wsapi = getWsActions()
          if (wsapi) {
            console.log('Toggle Bounce')
            wsapi.sendJsonMessage({ action: 'bounce', enable: !isBounceEnabled })
          } else {
            console.log('WSAPI not ready')
          }
        }}>
        {togglePrefix(isBounceEnabled)} Bounce
      </Button>


      <Button
        disabled={loading || !authorized}
        color={isLongEnabled ? 'success' : 'warning'}
        onClick={() => {
          const wsapi = getWsActions()
          if (wsapi) {
            console.log('Toggle Long')
            wsapi.sendJsonMessage({ action: 'long', enable: !isLongEnabled })
          } else {
            console.log('WSAPI not ready')
          }
        }}>
        {togglePrefix(isLongEnabled)} Long
      </Button>


      <Button
        disabled={loading || !authorized}
        color={isShortEnabled ? 'success' : 'warning'}
        onClick={() => {
          const wsapi = getWsActions()
          if (wsapi) {
            console.log('Toggle Short')
            wsapi.sendJsonMessage({ action: 'short', enable: !isShortEnabled })
          } else {
            console.log('WSAPI not ready')
          }
        }}>
        {togglePrefix(isShortEnabled)} Short
      </Button>
    </Box>

  </Box>)
}

function PositionsControls() {
  const showControls = useRecoilValue(showControlsState)
  const authorized = useRecoilValue(authorizedState)

  if (!showControls) return null

  return (<Box sx={{
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexBasis: { xs: '30rem', lg: '30rem' },
  }}>
    <Button
      disabled={!authorized}
      color={'error'}
      onClick={() => {
        const wsapi = getWsActions()
        if (wsapi) {
          console.log('Reset Paper')
          wsapi.sendJsonMessage({ action: 'resetPaper' })
        } else {
          console.log('WSAPI not ready')
        }
      }}>
      Reset Paper
    </Button>

    <Button
      disabled={!authorized}
      sx={{ flexBasis: { xs: '6rem', xl: '6rem' } }}
      color={'warning'}
      onClick={() => {
        const wsapi = getWsActions()
        if (wsapi) {
          console.log('CloseAll')
          wsapi.sendJsonMessage({
            action: 'closeAll',
          })
        } else {
          console.log('WSAPI not ready')
        }
      }}>
      Close ALL
    </Button>
    <Button
      disabled={!authorized}
      sx={{ flexBasis: { xs: '7rem', xl: '7rem' } }}
      color={'success'}
      onClick={() => {
        const wsapi = getWsActions()
        if (wsapi) {
          console.log('CloseLong')
          wsapi.sendJsonMessage({
            action: 'closeLong',
          })
        } else {
          console.log('WSAPI not ready')
        }
      }}>
      Close Long
    </Button>
    <Button
      disabled={!authorized}
      sx={{ flexBasis: { xs: '7rem', xl: '7rem' } }}
      color={'error'}
      onClick={() => {
        const wsapi = getWsActions()
        if (wsapi) {
          console.log('CloseShort')
          wsapi.sendJsonMessage({
            action: 'closeShort',
          })
        } else {
          console.log('WSAPI not ready')
        }
      }}>
      Close Short
    </Button>
  </Box>)
}

function getPNL(state, ticker) {
  const currentPrice = parseFloat(ticker?.c || 0)
  if (state?.activeOrder) {
    const direction = state.delayedDirection || state.activeOrder.direction
    const { openPrice } = fixPositionPrices(state)
    const avgEntryPrice = state.activeOrder.price || openPrice
    const positionAmount = state.activeOrder.amount

    const openFee = state.delayedEnterType === 'LIMIT' ? limitFee : marketFee
    const closeFee = marketFee
    const combinedFee = openFee + closeFee

    let change, changeUSD, totalUsd, volumeUSD, fee, cashback
    try {
      if (direction === 'long') {
        change = currentPrice / avgEntryPrice * 100 - 100
        volumeUSD = positionAmount * avgEntryPrice
        fee = Math.abs(volumeUSD * combinedFee)
        cashback = fee * cashbackRate
        changeUSD = volumeUSD * change / 100
        totalUsd = changeUSD - fee + cashback
      } else if (direction === 'short') {
        change = avgEntryPrice / currentPrice * 100 - 100
        volumeUSD = positionAmount * avgEntryPrice
        fee = Math.abs(volumeUSD * combinedFee)
        cashback = fee * cashbackRate
        changeUSD = -volumeUSD * change / 100
        totalUsd = changeUSD + fee - cashback
      }

      if (isFinite(changeUSD)) {
        return { pnl: changeUSD, total: totalUsd, fee, cashback }
      }
    } catch (error) {

    }
  }

  return { pnl: 0, fee: 0, total: 0, cashback: 0 }
}


function fixPrice(initPrice, symbolInfo, roundType = 'round') {
  const priceFilter = symbolInfo.filters.find(f => f.filterType === 'PRICE_FILTER')
  const tickSize = parseFloat(priceFilter.tickSize)
  const minPrice = parseFloat(priceFilter.minPrice)
  const price = Math[roundType]((initPrice - minPrice) / tickSize) * tickSize + minPrice

  const pricePrecision = symbolInfo.pricePrecision
  const priceStr = price.toFixed(pricePrecision)
  return priceStr
}

function fixQty(initQty, symbolInfo, orderType = 'MARKET', roundType = 'round') {
  const { minQty, stepSize } = getQtyLimits(symbolInfo, orderType)

  const qty = Math.abs(initQty)
  const quantity = Math[roundType]((qty - minQty) / stepSize) * stepSize + minQty

  const quantityPrecision = symbolInfo.quantityPrecision
  const quantityStr = quantity.toFixed(quantityPrecision)

  return quantityStr
}

function getQtyLimits(symbolInfo, orderType = 'MARKET') {
  const marketOrders = ['MARKET', 'STOP_MARKET', 'TAKE_PROFIT_MARKET', 'TRAILING_STOP_MARKET']
  const isMarket = marketOrders.includes(orderType)
  const filterType = isMarket ? 'MARKET_LOT_SIZE' : 'LOT_SIZE'

  const lotFilter = symbolInfo.filters.find(f => f.filterType === filterType)
  const minQty = parseFloat(lotFilter.minQty)
  const maxQty = parseFloat(lotFilter.maxQty)
  const stepSize = parseFloat(lotFilter.stepSize)

  const notFilter = symbolInfo.filters.find(f => f.filterType === 'MIN_NOTIONAL')
  const minNotional = parseFloat(notFilter.notional)

  return { minQty, maxQty, stepSize, minNotional }
}

function getTickSize(symbolInfo) {
  const priceFilter = symbolInfo.filters.find(f => f.filterType === 'PRICE_FILTER')
  const tickSize = parseFloat(priceFilter.tickSize)

  return tickSize
}

function getPriceSteps(target, current, tickSize) {
  const delta = Math.abs(target - current)
  const steps = Math.floor(delta / tickSize)

  return steps
}

function formatNumber(num, significantDigits = 3) {
  // Включаем массив суффиксов внутрь функции для полной инкапсуляции
  const suffixes = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
    1000000000000: 'T'
  };

  const absNum = Math.abs(num);
  const sigDelta = Math.max(0, significantDigits - 3)

  if (absNum >= 1000) {
    let scale = 1;
    let suffix = '';
    let scaledNum = num;

    // Определяем нужный делитель и суффикс
    while (scaledNum >= 1000 && scale <= 1e12) {
      scale *= 1000;
      scaledNum = num / scale;
      suffix = suffixes[scale] || '';
    }

    // Округляем число в зависимости от величины
    if (scaledNum >= 100) {
      return (scaledNum.toFixed(sigDelta) + suffix);
    } else if (scaledNum >= 10) {
      return (scaledNum.toFixed(sigDelta + 1) + suffix);
    } else {
      return (scaledNum.toFixed(sigDelta + 2) + suffix);
    }
  } else if (absNum < 1) {
    // Используем toPrecision для чисел меньше 1
    return num.toPrecision(significantDigits);
  } else {

    // Числа от 1 до 999, применяем логику похожую на большие числа, но без суффикса
    if (num >= 100) {
      return num.toFixed(sigDelta);
    } else if (num >= 10) {
      return num.toFixed(sigDelta + 1);
    } else {
      return num.toFixed(sigDelta + 2);
    }
  }
}

function fixPositionPrices(r) {
  let positionSize = 0
  let openPrice = r.openPrice

  if (r.history && r.history?.length) {
    const openKey = r.direction === 'long' ? 'openLong' : 'openShort';
    const opens = r.history.filter(a => a.type === openKey)

    let avgPrice = 0
    let totalPosition = 0
    for (const enter of opens) {
      avgPrice += enter.avgEntryPrice * enter.position
      totalPosition += enter.position
    }
    avgPrice /= totalPosition
    openPrice = avgPrice
    positionSize = totalPosition
  }

  // if (openPrice !== r.openPrice) console.log('fixPositionPrices openPrice', openPrice, r.openPrice)


  let closePrice = r.closePrice
  //if (!closePrice) {
  // if (r.history && r.history?.length) {
  //   const closeKey = r.direction === 'long' ? 'closeLong' : 'closeShort';
  //   const closes = r.history.filter(a => a.type === closeKey)

  //   let avgPrice = 0
  //   let totalPosition = 0
  //   for (const enter of closes) {
  //     avgPrice += enter.avgEntryPrice * enter.position
  //     totalPosition += enter.position
  //   }
  //   avgPrice /= totalPosition
  //   closePrice = avgPrice
  // }
  //}

  return { openPrice, closePrice }
}

function msToHHMMSS(ms) {
  const seconds = Math.floor(ms / 1000)
  const hh = Math.floor(seconds / 3600)
  const mm = Math.floor((seconds - hh * 3600) / 60)
  const ss = seconds - hh * 3600 - mm * 60

  const hText = hh === 0 ? '' : `${hh}:`
  return `${hText}${mm}:${ss}`
}