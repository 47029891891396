import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { currentFactorState } from '#state';

const factors = [8, 7, 6, 5, 4, 3, 2, 1];

export function ClustersLevelsRoundFactor() {
  const [currentFactor, setCurrentFactor] = useRecoilState(currentFactorState);

  useEffect(() => {
    localforage.setItem('currentFactor', currentFactor);
  }, [currentFactor]);

  const handleFactorChange = (event) => {
    setCurrentFactor(event.target.value);
  };

  return (
    <Select
      size='small'
      value={currentFactor}
      onChange={handleFactorChange}
    >
      {factors.map((symbol) => (
        <MenuItem value={symbol} key={symbol}>
          {symbol} digits
        </MenuItem>
      ))}
    </Select>
  );
}