// themes
import paleteDark from './paleteDark';
import paleteLight from './paleteLight';

// theme images
// import mapDark from '#assets/mapDark.svg';
// import mapLight from '#assets/mapLight.svg';
// import alertDark from '#assets/alertDark.svg';
// import alertLight from '#assets/alertLight.svg';

export const getDesignTokens = (mode) => ({
    images: {
        // ...(mode === 'dark'
        //     ? {
        //           alert: alertDark,
        //           map: mapDark,
        //       }
        //     : {
        //           alert: alertLight,
        //           map: mapLight,
        //       }),
    },
    palette: {
        mode,
        ...(mode === 'dark' && paleteDark),
        ...(mode === 'light' && paleteLight),
    },
});
