import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  levelsMinimumVolumeSpotState
} from '#state';

export function LevelsMinimumVolumeSpot() {
  const [volume, setVolume] = useRecoilState(levelsMinimumVolumeSpotState);

  useEffect(() => {
    localforage.setItem('levelsMinimumVolumeSpot', volume);
  }, [volume]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const v = parseFloat(newValue)
    if (v >= 1) {
      setVolume(v);
    } else {
      setVolume(1);
    }
  };

  return (
    <TextField
      sx={{
        width: '6rem',
        background: 'linear-gradient(rgba(215, 7, 218, 0.3) 0% 45%, rgba(62, 218, 7, 0.3) 55% 100%)'
      }}
      label="Spot > $k"
      size='small'
      type="number"
      inputProps={{ min: 1 }} // set the minimum value to 1
      value={volume}
      onChange={handleChange}
    />
  );
}

export default LevelsMinimumVolumeSpot;