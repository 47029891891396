import React, { useEffect } from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState } from 'recoil';
import {
  enableZoomState,
} from '#state';

const ZoomEnabled = () => {
  const [enableZoom, setEnableZoom] = useRecoilState(enableZoomState);

  useEffect(() => {
    localforage.setItem('enableZoomState', enableZoom);
  }, [enableZoom]);

  const handleChange = (event) => {
    setEnableZoom(event.target.value);
  };

  return (
    <>
      <FormControl>
        <InputLabel id="enableZoom">Zoom</InputLabel>
        <Select
          labelId="enableZoom"
          id="enableZoom-select"
          size='small'
          sx={{ minWidth: '3rem' }}
          value={enableZoom}
          onChange={handleChange}
        >
          <MenuItem
            value={false}
            key={false}
          >
            Off
          </MenuItem>
          <MenuItem
            value={true}
            key={true}
          >
            On
          </MenuItem>
        </Select>
      </FormControl>
    </>);
};

export { ZoomEnabled }
