import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil';
// 
import reportWebVitals from './reportWebVitals';
import App from './App';
import { getDesignTokens } from './theme/index';
// 
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

import {
  appearanceState,
  savedColorSchemeState,
} from '#state';

function Index() {

  // themes
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const systemAppearance = prefersDarkMode ? 'dark' : 'light';
  const [appearance, setAppearance] = useRecoilState(appearanceState);
  const setSavedColorScheme = useSetRecoilState(savedColorSchemeState);

  const theme = useMemo(() => {
    const design = getDesignTokens(appearance);
    const resultTheme = createTheme(design);
    console.log('mui theme', resultTheme);
    return resultTheme;
  }, [appearance]);

  useEffect(() => {
    setAppearance(systemAppearance);
  }, [systemAppearance, setAppearance]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </ThemeProvider>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <Index />
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
