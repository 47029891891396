import { EMA, SMA } from '@debut/indicators';
import { atom, selector } from 'recoil';
import {
  tradesState,
  tradesSelector,
  candlesState,
  candlesSelector,
  clustersState,
  clustersSelector,
  bookState,
  bookSelector,
  bookUpdatesState,
  bookUpdatesSelector,
  resampledUpdatesCache,
} from '#state/data';


export const balanceState2 = atom({
  key: 'balanceState2',
  default: 100000, // начальный баланс
});

const aggPeriod = 1000;

export const weightedAvgSelector = selector({
  key: 'weightedAvgSelector',
  get: ({ get }) => {
    const trades = get(tradesState);

    let lastTime = -Infinity
    let weightedAvgs = []
    let tradesBuffer = []

    trades.forEach(trade => {
      const start = Math.floor(trade.T / aggPeriod) * aggPeriod
      if (lastTime !== start) {
        if (tradesBuffer.length) {
          const lastWeightedAvg = weightedAvg(tradesBuffer);   
          weightedAvgs.push(lastWeightedAvg)
          tradesBuffer = []
        } else {
          tradesBuffer.push(trade)
        }
      }
      // else {
      //   tradesBuffer.push(trade)
      // }

      lastTime = start
    });
    
    return weightedAvgs;
  },
});

export const ema1Selector = selector({
  key: 'ema1Selector',
  get: ({ get }) => {
    const avgPrice = get(weightedAvgSelector);
    const ema = new EMA(8);
    let emas = []

    avgPrice.forEach(price => { 
      const last = ema.nextValue(price)
      emas.unshift(last)
    })
    return emas;
  },
});

export const ema2Selector = selector({
  key: 'ema2Selector',
  get: ({ get }) => {
    const avgPrice = get(weightedAvgSelector);
    const ema = new EMA(13);
    let emas = []

    avgPrice.forEach(price => { 
      const last = ema.nextValue(price)
      emas.unshift(last)
    })
    return emas;
  },
});
export const ema3Selector = selector({
  key: 'ema3Selector',
  get: ({ get }) => {
    const avgPrice = get(weightedAvgSelector);
    const ema = new EMA(55);
    let emas = []

    avgPrice.forEach(price => { 
      const last = ema.nextValue(price)
      emas.unshift(last)
    })
    return emas;
  },
});
export const sma3Selector = selector({
  key: 'sma3Selector',
  get: ({ get }) => {
    const avgPrice = get(weightedAvgSelector);
    const ma = new SMA(55);
    let mas = []

    avgPrice.forEach(price => { 
      const last = ma.nextValue(price)
      mas.unshift(last)
    })
    return mas;
  },
});

function weightedAvg(trades) {
  let sum = 0, totalQuantity = 0;

  trades.forEach(trade => {
    const q = trade.q
    sum += trade.p * q
    totalQuantity += q;
  });

  return sum / totalQuantity;
};

function constUp(arr, num) {
  // resturn true if arr[0] > arr[1] && arr[1] > arr[2] ... arr[num-1] > arr[num]
  for (let i = 0; i < num - 1; i++) {
    if (arr[i] < arr[i + 1]) return false
  }
  return true
}
function constDown(arr, num) {
  for (let i = 0; i < num - 1; i++) {
    if (arr[i] > arr[i + 1]) return false
  }
  return true
}