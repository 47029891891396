import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  levelsPercentileState
} from '#state';

export function LevelsPercentile() {
  const [levelsPercentile, setLevelsPercentile] = useRecoilState(levelsPercentileState);

  useEffect(() => {
    localforage.setItem('levelsPercentileState', levelsPercentile);
  }, [levelsPercentile]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const v = parseFloat(newValue)
    if (v >= 1 && v <= 99) {
      setLevelsPercentile(v);
    } else if (v < 1){
      setLevelsPercentile(1);
    } else {
      setLevelsPercentile(99);
    }
  };

  return (
    <TextField
      sx={{
        width: '5rem',
      }}
      label="LVL %"
      type="number"
      size='small'
      inputProps={{ min: 1, max: 99, step: 1 }}
      value={levelsPercentile}
      onChange={handleChange}
    />
  );
}

export default LevelsPercentile;