
export function groupUpdates(resample, startTime, updates) {
  const st = parseInt(startTime)
  let nextSampleTime = st + resample * 1000 // group by resample seconds
  const minuteUpdates = {}

  minuteUpdates[nextSampleTime] = minuteUpdates[nextSampleTime] || []

  for (const update of updates) {
    const time = update.time
    if (time > nextSampleTime) {
      nextSampleTime = nextSampleTime + resample * 1000
      minuteUpdates[nextSampleTime] = minuteUpdates[nextSampleTime] || []
    }
    minuteUpdates[nextSampleTime].push(update)
  }

  return minuteUpdates
}

export function getSamplesFromUpdates(groupedUpdates) {
  const resampledUpdates = []
  for (let updateEnd in groupedUpdates) {
    const updates = groupedUpdates[updateEnd]
    const sample = { time: updateEnd, bids: {}, asks: {} }
    for (const u of updates) {
      sample.bids = { ...sample.bids, ...u.bids }
      sample.asks = { ...sample.asks, ...u.asks }
    }
    // checkSample(sample)
    resampledUpdates.push(sample)
  }
  return resampledUpdates
}

function checkSample(sample) {
  let bestAsk = Infinity
  for (const lvl in sample.asks) {
    const volume = sample.asks[lvl]
    const v = parseFloat(volume)
    const p = parseFloat(lvl)
    if (v === 0) {
      // do not remove now
      // delete sample.asks[lvl]
    } else if (p < bestAsk) {
      bestAsk = p
      sample.bestAsk = lvl
    }
  }

  let bestBid = -Infinity
  for (const lvl in sample.bids) {
    const volume = sample.bids[lvl]
    const v = parseFloat(volume)
    const p = parseFloat(lvl)
    if (v === 0) {
      // do not remove now
      // delete sample.bids[lvl]
    } else if (p > bestBid) {
      bestBid = p
      sample.bestBid = lvl
    }
  }
  // console.log('sample', sample)
  //candleStart, updateEnd, bestAsk, bestBid)
  if (bestAsk <= bestBid) {
    console.error('sample invalid', sample)
    // candleStart, updateEnd, bestAsk, bestBid)
  }
}


export const groupPrices = (data, decimals, useAvg = false) => {
  // const factor = Math.pow(10, decimals);
  const groupedTrades = {};
  const groupedAvg = {};
  const groups = {};

  for (const lvl in data) {
    const price = parseFloat(lvl);
    const volume = data[lvl];
    const roundedPrice = Number(price.toPrecision(decimals))
    // const roundedPrice = Math.round(price / factor) * factor;
    // console.log('roundedPrice', roundedPrice)
    groups[roundedPrice] = groups[roundedPrice] || [];
    groups[roundedPrice].push({ price, volume })

    if (!groupedTrades[roundedPrice]) {
      groupedTrades[roundedPrice] = volume
    } else {
      groupedTrades[roundedPrice] += volume
    }
  }

  for (const group in groups) {
    if (useAvg) {
      const avgPrice = groups[group].reduce((a, b) => a + b.price, 0) / groups[group].length;
      groupedAvg[avgPrice] = groupedTrades[group]
    } else {
      const sumPriceVolume = groups[group].reduce((acc, item) => acc + item.price * item.volume, 0);
      const sumVolume = groups[group].reduce((acc, item) => acc + item.volume, 0);
      const weightedAvgPrice = sumPriceVolume / sumVolume;
      groupedAvg[weightedAvgPrice] = groupedTrades[group];
    }
  }
  // console.log('groups', groups)
  // console.log('groupedAvg', groupedAvg)

  return groupedAvg;
}

export function percentile(values, p) {
  values.sort((a, b) => a - b);
  const index = (p / 100) * (values.length - 1);

  if (Math.floor(index) === index) {
    return values[index];
  } else {
    const i = Math.floor(index);
    return values[i] + (values[i + 1] - values[i]) * (index - i);
  }
}
