import Dexie from 'dexie';

export const db = new Dexie('bot');
db.version(7).stores({
  candles: '++id, symbol, time, candles',
  candlesH1: '++id, symbol, time, candles',
  orderBook: '++id, symbol, time, asks, bids',
  orderBookUpdates: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_1: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_2: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_3: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_4: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_5: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_6: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_10: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_12: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_15: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_20: '++id, symbol, time, asks, bids',
  orderBookUpdatesR_30: '++id, symbol, time, asks, bids',
});