
import pLimit from 'p-limit';
import localforage from 'localforage';
import { db } from "./db";

const isDev = process.env.NODE_ENV !== 'production'
const apiEnv = process.env.REACT_APP_ENV || 'TEST';
const apiBase = (apiEnv === 'PROD')
  ? 'https://bpcm1.elmeno.dev'
  // : 'https://botpro.elmeno.dev'
  : 'https://bot.elmeno.dev'

// const apiBase = process.env[`REACT_APP_BASE_DOMAIN_${apiEnv}`]

const binanceBase = 'https://fapi.binance.com'

export async function getCandles(opts) {
  const {
    symbol = 'BTCUSDT',
    interval = '1m',
    from = Date.now() - 1000 * 60 * 60,
    to = Date.now(),
    setCandlesData,
  } = opts;
  try {
    const combinedRes = []
    // const url = `${domain}/candles?key=${key}&symbol=${symbol}&interval=${interval}&from=${from}&to=${to}`
    const request = new URL(`${apiBase}/candles`);
    request.search = new URLSearchParams({
      key: window.apiKey,
      symbol: symbol,
      interval: interval,
      from: from,
      to: to
    })
    const url = request.toString()
    await loadCandles(url, combinedRes, setCandlesData)
    console.log('loadCandles combinedRes', combinedRes);
    // setCandlesData(combinedRes);
    let candlesResult = []
    for (const chunk of combinedRes) {
      candlesResult = candlesResult.concat(chunk)
    }
    setCandlesData(candlesResult);
  } catch (error) {
    console.error('getCandles failed', error);
  }
}

export async function getClusters(opts) {
  const {
    symbol = 'BTCUSDT',
    interval = '1m',
    from = Date.now() - 1000 * 60 * 60,
    to = Date.now(),
    setClustersData
  } = opts;
  try {
    const combinedRes = []
    // const url = `${domain}/clusters?key=${window.apiKey}&symbol=${symbol}&interval=${interval}&from=${from}&to=${to}`
    const request = new URL(`${apiBase}/clusters`);
    request.search = new URLSearchParams({
      key: window.apiKey,
      symbol: symbol,
      interval: interval,
      from: from,
      to: to
    })
    const url = request.toString()
    await loadCandles(url, combinedRes, setClustersData)
    console.log('getClusters combinedRes', combinedRes);
    // setClustersData(combinedRes);
    let candlesResult = []
    for (const chunk of combinedRes) {
      candlesResult = candlesResult.concat(chunk)
    }
    setClustersData(candlesResult);
  } catch (error) {
    console.error('getClusters failed', error);
  }
}

async function loadCandles(url, results, setData) {
  try {
    console.log('loadCandles url', url);
    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json();
      results.push(data.candles);
      let candlesResult = []
      for (const chunk of results) {
        candlesResult = candlesResult.concat(chunk)
      }
      setData(candlesResult);
      if (data.next && data.next !== url) {
        const request = new URL(data.next);
        const params = new URLSearchParams(request.search);
        params.append('key', window.apiKey);
        const next = request.toString()
        console.log('loadCandles next', next);
        await loadCandles(next, results)
      }
    } else {
      console.log('getStatsList response not ok', response);
      if (response.status === 401) {
        window.apiKey = null
        localforage.removeItem('apiKey')
        window.location.reload()
      }
    }
  } catch (error) {
    console.error('loadCandles failed', error);
  }
}

export async function getOrderBook(opts) {
  const {
    symbol,
    interval,
    from,
    to,
    setBookData,
  } = opts;
  try {
    let candlesResult = []

    const times = getCandleTimes(interval, from, to)
    console.log('times', times);
    const q = await db.orderBook
      .where('time')
      .between(from, to)
      .and(r => r.symbol === symbol)
      .toArray()
    q.sort((a, b) => a.time - b.time)
    console.log('db.orderBook', q);

    const minutesToLoad = times.filter((time) => {
      const ind = q.findIndex((item) => item.time === time)
      return ind === -1
    })
    console.log('minutesToLoad', minutesToLoad);

    const limit = pLimit(3);
    const tasks = []
    for (const minute of minutesToLoad) {
      // const url = `${domain}/book?key=${window.apiKey}&symbol=${symbol}&interval=${interval}&from=${minute}&to=${minute}`
      const request = new URL(`${apiBase}/book`);
      request.search = new URLSearchParams({
        key: window.apiKey,
        symbol: symbol,
        interval: interval,
        from: minute,
        to: minute
      })
      const url = request.toString()
      const combinedRes = []

      const task = async () => {
        const res = await loadBook(url, combinedRes, null, opts)
        console.log('minute book loaded', minute);
        return res
      }
      tasks.push(limit(task))
    }

    const result = await Promise.all(tasks);

    const q2 = await db.orderBook
      .where('time')
      .between(from, to)
      .and(r => r.symbol === symbol)
      .toArray();

    if (q2.length) {
      console.log('getOrderBook db.orderBook', q2);
      candlesResult.push(...q2)
    }
    setBookData(candlesResult);
  } catch (error) {
    console.error('getOrderBook failed', error);
  }
}
async function loadBook(url, results, setData, updParams) {
  try {
    const {
      from,
      to,
      symbol,
      interval,
      setBookUpdatesData
    } = updParams

    console.log('loadBook url', url);
    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json();
      // console.log('loadBook response', data);

      if (data.depth) {
        results.push(data.depth);

        const time = data.depth.time

        const existingCandles = await db.orderBook
          .where('time')
          .equals(time)
          .and(r => r.symbol === symbol)
          .toArray();

        if (existingCandles.length) {
          console.log('db.orderBook exists', time, symbol);
        } else {
          console.log('db.orderBook add', time, symbol);
          await db.orderBook.add({
            symbol,
            time: time,
            asks: data.depth.asks,
            bids: data.depth.bids,
          });
        }
      }
      let candlesResult = []
      for (const chunk of results) {
        candlesResult = candlesResult.concat(chunk)
      }
      if (setData) setData(candlesResult);
      if (data.next && data.next !== url) {
        const request = new URL(data.next);
        const params = new URLSearchParams(request.search);
        params.append('key', window.apiKey);
        const next = request.toString()
        console.log('loadBook next', next);
        await loadBook(next, results, setData, updParams)
      }
    }

  } catch (error) {
    console.error('loadBook failed', error);
  }
}

export async function getOrderBookUpdates(opts) {
  const {
    symbol,
    interval = '1m',
    from,
    to,
    resample,
    resampleOnServer,
    setBookUpdatesData
  } = opts;
  try {
    const candleLength = 60 * 1000 - 1

    const times = getCandleTimes(interval, from, to)
    // console.log('getOrderBookUpdates times', times);
    let selectedDB = 'orderBookUpdates'
    if (resampleOnServer && resample) {
      selectedDB = `orderBookUpdatesR_${resample}`
    }

    const q = await db[selectedDB]
      .where('time')
      .between(from, to)
      .and(r => r.symbol === symbol)
      .toArray()
    q.sort((a, b) => a.time - b.time)
    // console.log('db.orderBookUpdates', q);

    const minutesToLoad = times.filter((time) => {
      const ind = q.findIndex((item) => item.time >= time && item.time <= time + candleLength)
      return ind === -1
    })
    console.log('getOrderBookUpdates minutesToLoad', minutesToLoad);

    const limit = pLimit(3);
    const tasks = []
    for (const minute of minutesToLoad) {
      // const url = `${domain}/book/updates?symbol=${symbol}&interval=${interval}&from=${minute}&to=${minute + candleLength}`
      const request = new URL(`${apiBase}/book/updates`);
      request.search = new URLSearchParams({
        key: window.apiKey,
        symbol,
        resample,
        from: minute,
        to: minute + candleLength
      })
      const url = request.toString()
      const minuteUpdates = []

      const task = async () => {
        const res = await loadBookUpdates(url, opts, minuteUpdates)
        console.log('minute updates loaded', minute);
        const q2 = await db[selectedDB]
          .where('time')
          .between(from, to)
          .and(r => r.symbol === symbol)
          .toArray();

        // console.log('getOrderBookUpdates db.orderBook', q2);
        setBookUpdatesData(q2);
        return res
      }
      tasks.push(limit(task))

    }

    const result = await Promise.all(tasks);

    const q2 = await db[selectedDB]
      .where('time')
      .between(from, to)
      .and(r => r.symbol === symbol)
      .toArray();

    // console.log('getOrderBookUpdates db.orderBook', q2);
    setBookUpdatesData(q2);
  } catch (error) {
    console.error('getOrderBookUpdates failed', error);
  }
}

async function loadBookUpdates(url, updParams, minuteUpdates) {
  try {
    const {
      from,
      to,
      symbol,
      interval,
      resample,
      resampleOnServer,
      setBookUpdatesData
    } = updParams
    console.log('loadBookUpdates url', url);

    let selectedDB = 'orderBookUpdates'
    if (resampleOnServer && resample) {
      selectedDB = `orderBookUpdatesR_${resample}`
    }

    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json();
      // console.log('loadBookUpdates response', data);

      if (data.updates) {
        minuteUpdates.push(...data.updates);
        for (const update of data.updates) {
          const existingCandles = await db[selectedDB]
            .where('time')
            .equals(update.time)
            .and(r => r.symbol === symbol)
            .toArray();

          if (!existingCandles.length) {
            // console.log('db.orderBookUpdates add', time, symbol);
            await db[selectedDB].add({
              symbol: symbol,
              time: update.time,
              // updTime: update.updTime,
              asks: update.asks,
              bids: update.bids,
            });
          } else {
            // console.log('db.orderBookUpdates exists', time, symbol);
          }
        }
      }

      // if (data.next && data.next !== url) {
      //   const request = new URL(data.next);
      //   const params = new URLSearchParams(request.search);
      //   params.append('key', window.apiKey);
      //   const next = request.toString()
      //   console.log('loadBookUpdates next', next);
      //   await loadBookUpdates(next, updParams, minuteUpdates)
      // }

    } else {
      console.log('loadBookUpdates response not ok', response);

    }
  } catch (error) {
    console.error('loadBookUpdates failed', error);
  }
}



// export async function getCandlesBinance(opts) {
//   const { symbol, interval, from, to, setCandlesData, } = opts;
//   try {
//     const request = new URL(`${binanceBase}/fapi/v1/klines`);
//     request.search = new URLSearchParams({
//       symbol: symbol,
//       interval: interval,
//       startTime: from,
//       endTime: to,
//       limit: 1000
//     })
//     const url = request.toString()
//     const candles = await loadCandlesBinance(url)
//     setCandlesData(candles);
//   } catch (error) {
//     console.error('getCandles failed', error);
//   }
// }

export async function getCandlesBinance(opts) {
  const { symbol, interval, from, to, setCandlesData, } = opts;
  console.log('getCandlesBinance', from, to);
  try {
    const request = new URL(`${binanceBase}/fapi/v1/klines`);
    const limit = 960; // Ограничение установлено на 960 минут или 16 часов
    const minute = 60000; // Миллисекунды в минуте
    const hour = 60 * minute; // Миллисекунды в часе
    let allCandles = new Map();

    const hourStart = time => Math.floor(time / hour) * hour;

    let hours = [];
    for (let time = hourStart(from); time < to; time += hour) {
      hours.push(time);
    }
    hours = hours.filter(el => el >= from);

    let missingHours = [];
    for (let hour of hours) {
      const existingHourData = await db.candles.where('time').equals(hour).and(r => r.symbol === symbol).toArray();
      if (!existingHourData.length || (existingHourData.length && existingHourData[existingHourData.length - 1].candles.length !== 60)) {
        missingHours.push(hour);
        // console.log('missingHour', hour, existingHourData);
      } else {
        allCandles.set(hour, existingHourData[0].candles);
      }
    }

    if (missingHours.length) {
      console.log('missingHours', missingHours);
    }

    if (missingHours.length > 0) {
      missingHours.sort((a, b) => a - b);

      let chunks = [];
      let currentChunk = [missingHours[0]];

      for (let i = 1; i < missingHours.length; i++) {
        if (missingHours[i] - missingHours[i - 1] === hour && currentChunk.length < limit / 60) {
          currentChunk.push(missingHours[i]);
        } else {
          chunks.push(currentChunk);
          currentChunk = [missingHours[i]];
        }
      }

      if (currentChunk.length > 0) {
        chunks.push(currentChunk);
      }

      for (let chunk of chunks) {
        request.search = new URLSearchParams({
          symbol: symbol,
          interval: interval,
          startTime: chunk[0],
          endTime: chunk[chunk.length - 1] + hour,
          limit: chunk.length * 60
        });

        const url = request.toString();
        const candles = await loadCandlesBinance(url);
        if (!candles) {
          console.log('loadCandlesBinance failed', url);
          return;
        }

        for (let j = 0; j < chunk.length; j++) {
          const hourCandles = candles.slice(j * 60, (j + 1) * 60);
          allCandles.set(chunk[j], hourCandles);

          try {
            if (hourCandles.length === 60) {
              const put = await db.candles.put({ symbol: symbol, time: chunk[j], candles: hourCandles })
              // console.log('db.candles.put', chunk[j], put);
            } else {
              console.log('db.candles.put skip not full hour', chunk[j], hourCandles.length);
            }
          } catch (error) {
            console.error('db.candles.put failed', chunk[j], error);
          }
        }
      }
    }

    let finalCandles = [];
    for (let hourCandles of allCandles.values()) {
      finalCandles = finalCandles.concat(hourCandles);
    }
    setCandlesData(finalCandles);
  } catch (error) {
    console.error('getCandles failed', error);
  }
}

export async function getCandlesBinanceH1(opts) {
  const { symbol, interval, from, to, setCandlesH1Data } = opts;
  console.log('getCandlesBinanceH1', from, to);
  try {
    const request = new URL(`${binanceBase}/fapi/v1/klines`);
    const limit = 960; // Ограничение установлено на 960 часов = 40 дней
    const hour = 3600000; // Миллисекунды в часе
    const day = 24 * hour; // Миллисекунды в дне
    let allCandles = new Map();

    const dayStart = time => Math.floor(time / day) * day;

    let days = [];
    for (let time = dayStart(from); time < to; time += day) {
      days.push(time);
    }
    days = days.filter(el => el >= from);

    let missingDays = [];
    for (let day of days) {
      const existingDayData = await db.candlesH1.where('time').equals(day).and(r => r.symbol === symbol).toArray();
      if (!existingDayData.length || (existingDayData.length && existingDayData[existingDayData.length - 1].candles.length !== 24)) {
        missingDays.push(day);
        // console.log('missingDay', day, existingDayData);
      } else {
        allCandles.set(day, existingDayData[0].candles);
      }
    }

    if (missingDays.length) {
      console.log('missingDays', missingDays);
    }

    if (missingDays.length > 0) {
      missingDays.sort((a, b) => a - b);

      let chunks = [];
      let currentChunk = [missingDays[0]];

      for (let i = 1; i < missingDays.length; i++) {
        if (missingDays[i] - missingDays[i - 1] === day && currentChunk.length < limit / 24) {
          currentChunk.push(missingDays[i]);
        } else {
          chunks.push(currentChunk);
          currentChunk = [missingDays[i]];
        }
      }

      if (currentChunk.length > 0) {
        chunks.push(currentChunk);
      }

      for (let chunk of chunks) {
        request.search = new URLSearchParams({
          symbol: symbol,
          interval: interval,
          startTime: chunk[0],
          endTime: chunk[chunk.length - 1] + day,
          limit: chunk.length * 24
        });

        const url = request.toString();
        const candles = await loadCandlesBinance(url);
        if (!candles) {
          console.log('loadCandlesBinanceH1 failed', url);
          return;
        }

        for (let j = 0; j < chunk.length; j++) {
          const dayCandles = candles.slice(j * 24, (j + 1) * 24);
          allCandles.set(chunk[j], dayCandles);

          try {
            if (dayCandles.length === 24) {
              const put = await db.candlesH1.put({ symbol: symbol, time: chunk[j], candles: dayCandles })
              // console.log('db.candlesH1.put', chunk[j], put);
            } else {
              console.log('db.candlesH1.put skip not full day', chunk[j], dayCandles.length);
            }
          } catch (error) {
            console.error('db.candlesH1.put failed', chunk[j], error);
          }
        }
      }
    }

    let finalCandles = [];
    for (let dayCandles of allCandles.values()) {
      finalCandles = finalCandles.concat(dayCandles);
    }
    setCandlesH1Data(finalCandles);
  } catch (error) {
    console.error('getCandlesH1 failed', error);
  }
}


// export async function getCandlesBinance(opts) {
//   const { symbol, interval, from, to, setCandlesData, } = opts;

//   try {
//     const request = new URL(`${binanceBase}/fapi/v1/klines`);
//     let allCandles = [];
//     let startTime = from;
//     let limit = 1000;
//     let minute = 60000; // Миллисекунды в минуте

//     // Задаем время окончания интервала
//     let endTime = Math.min(startTime + limit * minute, to);

//     while (startTime < to) {
//       request.search = new URLSearchParams({
//         symbol: symbol,
//         interval: interval,
//         startTime: startTime,
//         endTime: endTime,
//         limit: limit
//       })

//       const url = request.toString();
//       const candles = await loadCandlesBinance(url);
//       allCandles = allCandles.concat(candles);

//       // Обновляем значения startTime и endTime
//       startTime = endTime;
//       endTime = Math.min(startTime + limit * minute, to);
//     }

//     setCandlesData(allCandles);
//   } catch (error) {
//     console.error('getCandles failed', error);
//   }
// }


async function loadCandlesBinance(url) {
  try {
    console.log('loadCandles url', url);
    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json();
      const parsed = data.map(c => {
        const [startTime, open, high, low, close, volume, endTime, quoteVolume, trades, tbbv, tbqv] = c
        return { startTime, open, high, low, close, volume, endTime, quoteVolume, trades }
      })
      return parsed
    }
    return null
  } catch (error) {
    console.error('loadCandles failed', error);
    return null
  }
}


// **********************************************
// Other
// **********************************************

export function getCandleTimes(interval, from, to) {
  const intervalMinutes = intervalToMinutes(interval)
  const candleLength = intervalMinutes * 60 * 1000
  const firstMinute = getMinuteStart(from)

  const candleTimes = []
  for (let i = firstMinute; i < to; i += candleLength) {
    candleTimes.push(i)
  }
  const result = candleTimes.filter(t => t >= from && t <= to)
  return result
}

export function getMinuteStart(time) {
  return Math.floor(time / 1000 / 60) * 60 * 1000
}

export function getSecondStart(time) {
  return Math.floor(time / 1000) * 1000
}

export function intervalToMinutes(interval) {
  const conversion = { 'm': 1, 'h': 60, 'd': 24 * 60, 'w': 7 * 24 * 60, 'M': 30 * 24 * 60 };
  const unit = interval.slice(-1);
  const quantity = Number(interval.slice(0, -1));
  return quantity * conversion[unit];
}

export function joinCandles(candles) {
  return {
    time: candles[0].time,
    open: candles[0].open,
    high: Math.max(...candles.map(c => c.high)),
    low: Math.min(...candles.map(c => c.low)),
    close: candles[candles.length - 1].close,
    volume: candles.reduce((sum, c) => sum + c.volume, 0),
  };
}

// export function convertCandles(candles, fromTF, toTF) {
//   const minute = 60
//   const result = [];

//   if (!candles || !candles.length) return result

//   if (typeof fromTF === 'string') {
//     fromTF = intervalToMinutes(toTF)
//   }
//   if (typeof toTF === 'string') {
//     toTF = intervalToMinutes(toTF)
//   }

//   let startTime = Math.floor(candles[0].time / minute / toTF) * minute * toTF;
//   if (candles[0].time < startTime) startTime = startTime + minute * toTF

//   let currentPeriod
//   let groupedCandles = [];
//   for (const candle of candles) {
//     if (candle.time < startTime) continue // skip first candles if it's not full
//     let candleStart = Math.floor(candle.time / minute / toTF) * minute * toTF;
//     if (candleStart !== currentPeriod && groupedCandles.length) {
//       const newCandle = joinCandles(groupedCandles)
//       result.push(newCandle);
//       groupedCandles = []
//     }
//     currentPeriod = candleStart
//     groupedCandles.push(candle);
//   }

//   if (groupedCandles.length > 0) {
//     const newCandle = joinCandles(groupedCandles)
//     result.push(newCandle);
//   }

//   return result;
// }

export function convertCandles(candles, fromTF, toTF) {
  const now = Date.now();
  const minute = 60;
  const result = [];

  if (!candles || !candles.length) return result;

  if (typeof fromTF === 'string') {
    fromTF = intervalToMinutes(fromTF);
  }
  if (typeof toTF === 'string') {
    toTF = intervalToMinutes(toTF);
  }

  const ratio = toTF / fromTF;
  if (ratio < 1) {
    console.error('Target timeframe should be greater than or equal to the source timeframe');
    return result;
  }

  let startTime = Math.floor(candles[0].time / (minute * fromTF * ratio)) * minute * fromTF * ratio;
  if (candles[0].time < startTime) startTime += minute * fromTF * ratio;

  let currentPeriod;
  let groupedCandles = [];
  for (const candle of candles) {
    if (candle.time < startTime) {
      continue; // skip first candles if it's not full
    }
    let candleStart = Math.floor(candle.time / (minute * fromTF * ratio)) * minute * fromTF * ratio;
    if (candleStart !== currentPeriod && groupedCandles.length) {
      const newCandle = joinCandles(groupedCandles);
      result.push(newCandle);
      groupedCandles = [];
    }
    currentPeriod = candleStart;
    groupedCandles.push(candle);
  }


  if (groupedCandles.length > 0) {
    const newCandle = joinCandles(groupedCandles);
    result.push(newCandle);
  }

  return result;
}


// ********************************************** 

export async function getExchangeInfo() {
  try {
    const response = await fetch(`${binanceBase}/fapi/v1/exchangeInfo`)
    if (response.ok) {
      const data = await response.json();
      return data
    }
  } catch (error) {
    console.error('getExchangeInfo failed', error);
    return null
  }
}

export async function getReplaysList(opts) {
  const { setReplaysList, setLastSeenId, lastSeenId, mode } = opts;
  try {
    let modePath = '/records/move';
    if (mode === 'move') modePath = '/records/move';
    else if (mode === 'break') modePath = '/records/break';
    else if (mode === 'bounce') modePath = '/records/bounce';

    const request = new URL(`${apiBase}${modePath}`);
    const params = { key: window.apiKey }
    if (lastSeenId) {
      params.lastSeenId = lastSeenId;
    }
    request.search = new URLSearchParams(params)
    const url = request.toString()
    console.log('getReplaysList url', url);
    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json();
      const { records, next } = data;
      if (lastSeenId) setReplaysList(prev => prev.concat(records));
      else setReplaysList(records);
      if (next) {
        setLastSeenId(next);
      }
    } else {
      console.log('getStatsList response not ok', response);
      if (response.status === 401) {
        window.apiKey = null
        localforage.removeItem('apiKey')
        window.location.reload()
      }
    }
  } catch (error) {
    console.error('getReplaysList failed', error);
  }
}

export async function getReplay(opts) {
  const { id, mode } = opts;
  try {
    const savedReplay = await localforage.getItem(`replay:${id}`);
    if (savedReplay !== null) {
      const replay = JSON.parse(savedReplay);
      return replay;
    } else {
      let modePath = '/records/move';
      if (mode === 'move') modePath = '/records/move';
      else if (mode === 'break') modePath = '/records/break';
      else if (mode === 'bounce') modePath = '/records/bounce';
      else if (mode === 'paper') modePath = '/records/paper';
      else if (mode === 'real') modePath = '/records/real';

      const request = new URL(`${apiBase}${modePath}/${id}`);
      request.search = new URLSearchParams({
        key: window.apiKey,
      })
      const url = request.toString()
      console.log('getReplay url', url);
      const response = await fetch(url)
      if (response.ok) {
        const data = await response.json();
        localforage.setItem(`replay:${id}`, JSON.stringify(data));
        return data;
      } else {
        console.log('getStatsList response not ok', response);
        if (response.status === 401) {
          window.apiKey = null
          localforage.removeItem('apiKey')
          window.location.reload()
        }
      }
    }
  } catch (error) {
    console.error('getReplay failed', error);
  }
}

export async function getStatsList(opts) {
  const { statsList, setStatsList, setLastSeenId, lastSeenId, mode, filter } = opts;
  try {
    let modePath = '/stats/paper';
    if (mode === 'paper') modePath = '/stats/paper';
    else if (mode === 'real') modePath = '/stats/real';

    const request = new URL(`${apiBase}${modePath}`);
    const params = { key: window.apiKey, filter }
    if (lastSeenId) {
      params.lastSeenId = lastSeenId;
      console.log('getStatsList lastSeenId', lastSeenId)
    }
    request.search = new URLSearchParams(params)
    const url = request.toString()
    console.log('getStatsList url', url);
    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json();
      const { records, next } = data;
      if (next) {
        console.log('getStatsList setLastSeenId next', next)
        setLastSeenId(next);
      }
      if (lastSeenId) {
        let p = []
        setStatsList(prev => {
          console.log('setStatsList', prev, records)
          p = prev.concat(records)
          const sortedSL = groupAndSortByMaxNow(p);
          p = sortedSL
          return sortedSL
        });
        return p
      } else {
        const sortedSL = groupAndSortByMaxNow(records);
        setStatsList(sortedSL);
        return sortedSL
      }
    } else {
      console.log('getStatsList response not ok', response);
      if (response.status === 401) {
        window.apiKey = null
        localforage.removeItem('apiKey')
        window.location.reload()
      }
    }
    return []
  } catch (error) {
    console.error('getReplaysList failed', error);
  }
}

function groupAndSortByMaxNow(collection) {
  // Создаем объект для хранения блоков данных
  const blocks = {};

  // Проходимся по коллекции и группируем элементы по полю 'sid'
  collection.forEach((item) => {
    const { sid, now } = item;

    if (!blocks[sid]) {
      blocks[sid] = [];
    }

    blocks[sid].push(item);
  });

  // Преобразуем объект блоков в массив и сортируем по максимальному 'now'
  const sortedBlocks = Object.values(blocks).sort((a, b) => {
    const maxNowA = Math.max(...a.map((item) => item.now));
    const maxNowB = Math.max(...b.map((item) => item.now));

    return maxNowB - maxNowA; // Сортируем по убыванию максимального now
  });

  // Создаем результирующий массив
  const result = [];

  // Добавляем отсортированные блоки в результирующий массив
  sortedBlocks.forEach((block) => {
    block.sort((a, b) => a.now - b.now); 
    result.push(...block);
  });

  return result;
}

export async function validateApiKey(apiKey) {
  try {
    const request = new URL(`${apiBase}/validatekey`);
    request.search = new URLSearchParams({
      key: apiKey,
    })
    const url = request.toString()
    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json();
      if (data.valid) return true
    }
    return false
  } catch (error) {
    console.error('validateApiKey failed', error);
  }
}
