import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentSymbolState } from '#state';
import { exchangeInfoState } from '#state/data';
import symbolsDefault from '#src/symbols';
import FuzzySearch from 'fuzzy-search';

export function SymbolSelect() {
  const [currentSymbol, setCurrentSymbol] = useRecoilState(currentSymbolState);
  const [inputValue, setInputValue] = useState(currentSymbol);
  const exchangeInfo = useRecoilValue(exchangeInfoState);
  const [symbols, setSymbols] = useState(symbolsDefault);

  useEffect(() => {
    if (exchangeInfo) {
      const symbols = exchangeInfo.symbols
        .filter((s) => s.contractType === 'PERPETUAL')
        .map((s) => s.symbol);
      setSymbols(symbols);
    }
  }, [exchangeInfo]);

  useEffect(() => {
    localforage.setItem('currentSymbol', currentSymbol);
    setInputValue(currentSymbol);
  }, [currentSymbol]);

  useEffect(() => {
    if (currentSymbol !== inputValue && symbols.includes(inputValue)) {
      setCurrentSymbol(inputValue);
    }
  }, [inputValue, symbols, currentSymbol, setCurrentSymbol]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const fuzzySearch = new FuzzySearch(symbols);

  const filterOptions = (options, { inputValue }) =>
    fuzzySearch.search(inputValue);

  return (
    <Autocomplete
      size='small'
      sx={{ minWidth: '11rem' }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      filterOptions={filterOptions}
      options={symbols}
      renderInput={(params) => <TextField
        {...params}
        variant="outlined"
        label={currentSymbol}
      />}
    />
  );
}
