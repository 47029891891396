import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  mainFilter2DurationState
} from '#state';

export function MainFilter2Duration() {
  const [duration, setDuration] =
    useRecoilState(mainFilter2DurationState);

  useEffect(() => {
    localforage.setItem('mainFilter2DurationState', duration);
  }, [duration]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const v = parseFloat(newValue)
    if (v >= 1) {
      setDuration(v);
    } else {
      setDuration(6);
    }
  };

  return (
    <TextField
      sx={{ width: '4rem' }}
      label="M F1 Hours"
      type="number"
      size='small'
      inputProps={{ min: 1 }} // set the minimum value to 1
      value={duration}
      onChange={handleChange}
    />
  );
}

export default MainFilter2Duration;