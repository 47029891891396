import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  updateRateIntervalState
} from '#state';

export function UpdateRateInterval() {
  const [rate, setRate] = useRecoilState(updateRateIntervalState);
  const [value, setValue] = useState(rate);

  useEffect(() => {
    localforage.setItem('updateRateIntervalState', rate);
  }, [rate]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const v = parseFloat(newValue)
    setValue(newValue);
    if (v >= 10) {
      setRate(v);
    } else {
      setRate(10);
    }
  };

  return (
    <TextField
      size='small'
      sx={{ width: '5rem' }}
      label="Rate ms"
      type="number"
      inputProps={{ min: 1 }} // set the minimum value to 1
      value={value}
      onChange={handleChange}
    />
  );
}

export default UpdateRateInterval;