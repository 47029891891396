import React, { useEffect } from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState } from 'recoil';
import {
  resampleOnServerState,
  resampleOnServerLevelState,
} from '#state';

const levels = [0, 1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30];

const ResampleOnServer = () => {
  const [resampleOnServer, setResampleOnServer] = useRecoilState(resampleOnServerState);
  const [currentLevels, setCurrentLevels] =
    useRecoilState(resampleOnServerLevelState);
  const resampleEnabled = resampleOnServer ? 'on' : 'off';

  useEffect(() => {
    localforage.setItem('resampleOnServerLevel', currentLevels);
  }, [currentLevels]);

  const onChange = (event) => {
    setCurrentLevels(event.target.value);
  };

  useEffect(() => {
    localforage.setItem('resampleOnServer', resampleOnServer);
  }, [resampleOnServer]);

  const handleChange = (event) => {
    setResampleOnServer(event.target.value);
  };

  return (
    <>
      <FormControl>
        <InputLabel id="enableServerResample">Resample</InputLabel>
        <Select
          labelId="enableServerResample"
          id="enableServerResample-select"
          size='small'
          sx={{ minWidth: 100 }}
          value={resampleOnServer}
          onChange={handleChange}
        >
          <MenuItem
            value={true}
            key={true}
          >
            Server
          </MenuItem>
          <MenuItem
            value={false}
            key={false}
          >
            Local
          </MenuItem>
        </Select>
      </FormControl>

      {resampleOnServer ?
        <Select
          value={currentLevels}
          onChange={onChange}
        >
          {levels.map((level) => (
            <MenuItem
              value={level}
              key={level}
            >
              {level}s
            </MenuItem>
          ))}
        </Select>
        : null}
    </>);
};

export { ResampleOnServer }
