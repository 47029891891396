import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Box } from '@mui/material';
import { Resizable } from 're-resizable';
import localforage from 'localforage';

import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  apiKeyState,
  showControlsState,
} from '#state';

import Auth from './Auth';
import { Controls } from './controls';
import { WSAPI } from './WSAPI';
import { BinanceMini } from './BinanceMini';
import { Stats } from './Stats';
import { State } from './State';
import { Screen } from './Screen';
import { Realtime } from './Realtime';
import { Replays } from './Replays';
import { Replay } from './Replay';

import {
  exchangeInfoState,
} from '#state/data';

import {
  getExchangeInfo,
} from '../utils';

import './Main.css';

function App(props) {
  const [searchParams] = useSearchParams()

  const apiKey = useRecoilValue(apiKeyState);
  const setShowControls = useSetRecoilState(showControlsState);
  const setExchangeInfo = useSetRecoilState(exchangeInfoState);


  useEffect(() => {
    const run = async () => {
      const controls = searchParams.get('ctrl')
      if (controls === 'on') {
        setShowControls(true);
        await localforage.setItem('showControlsState', JSON.stringify(true));
      } else if (controls === 'off') {
        setShowControls(false);
        await localforage.setItem('showControlsState', JSON.stringify(false));
      }
    }
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      const newExchangeInfo = await getExchangeInfo();
      if (newExchangeInfo) {
        setExchangeInfo(newExchangeInfo)
      }
    }
    run();
  }, []);

  if (!apiKey) {
    return (<Auth />)
  }

  return (
    <Box className="App" sx={{
      p: 1,
    }}>
      <Controls />
      <WSAPI />
      <BinanceMini />
      <Routes>
        <Route path="/" element={<Realtime />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/state" element={<State />} />
        <Route path="/screen" element={<Screen />} />
        <Route path="/replays" element={<Replays />} />
        <Route path="/replays/*" element={<Replay />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>


    </Box>
  );
}


export default App;
