import { atom, selector} from 'recoil';

let wsActions = null
export function getWsActions() {
  return wsActions
}
export function setWsActions(actions) {
  wsActions = actions
}
export const authorizedState = atom({
  key: 'authorizedState',
  default: false
});

export const tradingStatusState = atom({
  key: 'tradingStatusState',
  default: null
});
export const tradeKeysState = atom({
  key: 'tradeKeysState',
  default: null
});
export const tradesDetailsState = atom({
  key: 'tradesDetailsState',
  default: new Map()
});
export const tradesUpdateState = atom({
  key: 'tradesUpdateState',
  default: 0
});

// Ticker
export const miniTickersState = atom({
  key: 'miniTickersState',
  default: new Map()
});
export const miniTickersUpdateState = atom({
  key: 'miniTickersUpdateState',
  default: 0
});

// Levels
export const levelsUpState = atom({
  key: 'levelsUpState',
  default: null
});
export const levelsDownState = atom({
  key: 'levelsDownState',
  default: null
});

// Базовый баланс в USD
export const balanceState = atom({
  key: 'balanceState',
  default: 100000, // начальный баланс
});

// Базовое количество BTC в портфеле
export const coinState = atom({
  key: 'coinState',
  default: 0, // начальное количество BTC
});

// Состояние последней цены
export const lastPriceState = atom({
  key: 'lastPriceState',
  default: 0,
});

// Комиссия биржи
export const exchangeFeeTakerState = atom({
  key: 'exchangeFeeTakerState',
  default: 0.04 * 0.01, // комиссия в процентах
});

export const exchangeFeeMakerState = atom({
  key: 'exchangeFeeMakerState',
  default: 0.02 * 0.01, // комиссия в процентах
});

// Активный ордер
export const activeOrderState = atom({
  key: 'activeOrderState',
  default: null, // По умолчанию ордера нет
});

export const orderHistoryState = atom({
  key: 'orderHistoryState',
  default: [], // По умолчанию история пуста
});