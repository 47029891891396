import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  orderBookScaleState
} from '#state';

export function BookScale() {
  const [scale, setScale] = useRecoilState(orderBookScaleState);
  const [value, setValue] = useState(scale);

  useEffect(() => {
    localforage.setItem('orderBookScaleState', scale);
  }, [scale]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const v = parseFloat(newValue)
    setValue(newValue);
    if (v >= 0) {
      setScale(v);
    } else {
      setScale(0.001);
    }
  };

  return (
    <TextField
      sx={{ width: '6rem' }}
      label="Book Scale Y"
      type="number"
      size='small'
      inputProps={{ min: 0.0001, max: 0.1, step: 0.001 }} 
      value={value}
      onChange={handleChange}
    />
  );
}

export default BookScale;