export const tradeBuffers = {
  futures: [],
  spot: [],
}

export const clustersStore = {
  futures: {
    minute: 0,
    isFullMinute: false,
    currentCluster: {
      totalV: 0, totalT: 0,
      totalDV: 0, totalDT: 0,
      summV: {}, summT: {},
      buyV: {}, buyT: {},
      sellV: {}, sellT: {},
      deltaV: {}, deltaT: {}
    },
    buffer: [],
  },
  spot: {
    minute: 0,
    isFullMinute: false,
    currentCluster: {
      totalV: 0, totalT: 0,
      totalDV: 0, totalDT: 0,
      summV: {}, summT: {},
      buyV: {}, buyT: {},
      sellV: {}, sellT: {},
      deltaV: {}, deltaT: {}
    },
    buffer: [],
  },
}

export const orderBooks = {
  futures: {
    valid: false,
    snapshotLoading: false,
    buffer: [],
    bufferTimestamp: 0,
    updates: [],
    updatesResampled: [],
    books: [],
    book: {
      bids: new Map(),
      asks: new Map(),
      lastUpdateId: -1,
      lastUpdateTime: 0,
    }
  },
  spot: {
    valid: false,
    snapshotLoading: false,
    buffer: [],
    bufferTimestamp: 0,
    updates: [],
    updatesResampled: [],
    books: [],
    book: {
      bids: new Map(),
      asks: new Map(),
      firstUpdateId: -1,
      lastUpdateId: -1,
      lastUpdateTime: 0,
    }
  }
}