import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { statsFilterWRState } from '#state/gui'

const factors = ['enabled', 'disabled'];

export function StatsFilterWR() {
  const [filter, setFilter] = useRecoilState(statsFilterWRState);

  useEffect(() => {
    localforage.setItem('statsFilterWRState', filter);
  }, [filter]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Select
      size='small'
      value={filter}
      onChange={handleFilterChange}
    >
      {factors.map((symbol) => (
        <MenuItem value={symbol} key={symbol}>
          {symbol}
        </MenuItem>
      ))}
    </Select>
  );
}