
import React, { useEffect, useRef, useState } from 'react';
import {
  createChart,
  CrosshairMode,
  LineStyle,
} from 'lightweight-charts';
import { Resizable } from 're-resizable';
import localforage from 'localforage';
// import { EMA, Extremums, Pivot, ATR } from '@debut/indicators';
// import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

// import {
//   candleChartHeightState,
//   candleChartWidthState,
// } from '#state/gui';

// import { TrendLine } from './plugins/TrendLine';
// import { VertLine } from './plugins/VertLine';
// import { UserPriceAlerts } from './plugins/UserPriceAlerts';

let initialBalance = 1000
//
// view config
// 

// chart
const opts = {
  // autoSize: true,
  rightPriceScale: {
    // visible: true,
    // drawLastPrice: false,
    borderColor: 'rgba(197, 203, 206, 1)',
    scaleMargins: {
      top: 0.1,
      bottom: 0.1,
    },
  },
  leftPriceScale: {
    // visible: false,
    // drawLastPrice: false,
    borderColor: 'rgba(197, 203, 206, 1)',
  },
  layout: {
    background: {
      type: 'solid',
      color: 'rgba(26, 29, 30, 1)',
    },
    textColor: 'rgba(255, 255, 255, 1)',
  },
  grid: {
    horzLines: {
      color: 'rgba(128, 128, 128, 1)',
    },
    vertLines: {
      color: 'rgba(128, 128, 128, 1)',
    },
  },
  crosshair: {
    mode: CrosshairMode.Normal,
  },
  timeScale: {
    borderColor: 'rgba(197, 203, 206, 1)',
    ticksVisible: true,
    timeVisible: true,
    rightOffset: 18,
  },
  handleScroll: {
    // vertTouchDrag: false,
  },
  localization: {
    priceFormatter: price => price.toFixed(3)
  },
}

// "candles": [
//   {
//       "startTime": 1685390400000,
//       "endTime": 1685390459999,
//       "open": "27624.10",
//       "close": "27623.40",
//       "high": "27624.1",
//       "low": "27613.3",
//       "volume": "167.517",
//       "quoteVolume": "4626593.8797",
//       "trades": 1997
//   },]
let currentChart
let tempSeries = []

const statsTimeKey = 'minute' //'hourly'


export const StatsChart = props => {
  const { stats } = props;
  // resize state
  const [resizing, setResizing] = useState(false);
  const [width, setWidth] = useState('100%') // useRecoilState(candleChartWidthState);
  const [height, setHeight] = useState(400) // useRecoilState(candleChartHeightState);

  // chart state 
  const chartContainerRef = useRef();
  const chartRef = useRef(null);

  const volumeSeriesRef = useRef(null);
  const equitySeriesRef = useRef(null);

  const totalSeriesRef = useRef(null);
  const longSeriesRef = useRef(null);
  const shortSeriesRef = useRef(null);
  const breakoutSeriesRef = useRef(null);
  const breakoutLongSeriesRef = useRef(null);
  const breakoutShortSeriesRef = useRef(null);
  const bounceSeriesRef = useRef(null);
  const bounceLongSeriesRef = useRef(null);
  const bounceShortSeriesRef = useRef(null);

  // init chart
  useEffect(() => {
    //if (!stats) return

    const handleResize = () => {
      try {
        const width = chartContainerRef.current.clientWidth;
        // const height = chartContainerRef.current.clientHeight;
        // const fullHeight = window.innerHeight
        chart.applyOptions({
          width,
          height,
        });
      } catch (error) {
        console.log('handleResize error', error)
      }
    };

    const currentRef = chartContainerRef.current
    const fullHeight = window.innerHeight
    const width = chartContainerRef.current.clientWidth;
    // const height = chartContainerRef.current.clientHeight;

    const chart = chartRef.current ? chartRef.current : createChart(chartContainerRef.current, {
      ...opts,
      width,
      height,
    });
    // setChart(chart)
    chartRef.current = chart
    currentChart = chart

    // ***********************************************
    // Volume Histogram
    // ***********************************************

    const volumeSeries = chart.addHistogramSeries({
      color: '#26a69a',
      priceScaleId: '',
      lastValueVisible: false,
      lineWidth: 1,
      priceFormat: {
        type: 'volume',
      },
      priceLineVisible: false,
      // overlay: true 
    });
    // setVolumeSeries(volumeSeries)
    volumeSeriesRef.current = volumeSeries

    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.8, // highest point of the series will be 70% away from the top
        bottom: 0,
      },
    });

    const equitySeries = chart.addLineSeries({
      color: 'rgba(255, 255, 255, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    equitySeriesRef.current = equitySeries

    // ***********************************************
    // Winrates
    // ***********************************************
    const totalSeries = chart.addLineSeries({
      color: 'rgba(255, 255, 255, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    totalSeriesRef.current = totalSeries

    const longSeries = chart.addLineSeries({
      color: 'rgba(0, 150, 136, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    longSeriesRef.current = longSeries

    const shortSeries = chart.addLineSeries({
      color: 'rgba(255,82,82, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    shortSeriesRef.current = shortSeries

    const breakoutSeries = chart.addLineSeries({
      color: 'rgba(200, 200, 0, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Dashed,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    breakoutSeriesRef.current = breakoutSeries

    const breakoutLongSeries = chart.addLineSeries({
      color: 'rgba(100, 200, 0, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.SparseDotted,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    breakoutLongSeriesRef.current = breakoutLongSeries

    const breakoutShortSeries = chart.addLineSeries({
      color: 'rgba(200, 100, 0, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.SparseDotted,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    breakoutShortSeriesRef.current = breakoutShortSeries

    const bounceSeries = chart.addLineSeries({
      color: 'rgba(100, 100, 255, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.Dashed,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    bounceSeriesRef.current = bounceSeries

    const bounceLongSeries = chart.addLineSeries({
      color: 'rgba(100, 200, 200, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.SparseDotted,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    bounceLongSeriesRef.current = bounceLongSeries

    const bounceShortSeries = chart.addLineSeries({
      color: 'rgba(200, 100, 200, 1)',
      lineWidth: 1,
      lineStyle: LineStyle.SparseDotted,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    bounceShortSeriesRef.current = bounceShortSeries


    // ***********************************************
    // handle resize
    // ***********************************************

    window.addEventListener('resize', handleResize);

    return () => {
      chart.unsubscribeClick()
      chart.unsubscribeCrosshairMove()

      window.removeEventListener('resize', handleResize);
      console.log('init chart cleanup')
    };
  }, [])

  // init tooltip
  useEffect(() => {
    const currentRef = chartContainerRef.current
    const chart = chartRef.current
    const volumeSeries = volumeSeriesRef.current
    const equitySeries = equitySeriesRef.current

    // ***********************************************
    // ToolTip
    // ***********************************************
    const toolTipWidth = 80;
    const toolTipHeight = 80;
    const toolTipMargin = 15;

    // Create and style the tooltip html element
    const toolTip = document.createElement('div');
    toolTip.style = `
    width: 196px; 
    height: 220px; 
    position: absolute;
    display: none; 
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
    text-align: left;
    z-index: 1000;
    top: 12px; 
    left: 12px;
    pointer-events: none;
    border-radius: 2px;`;

    toolTip.style.background = '#141414bb';
    toolTip.style.color = 'white';
    chartContainerRef.current.appendChild(toolTip);

    chart.subscribeCrosshairMove(param => {
      try {
        if (
          param.point === undefined ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > chartContainerRef.current.clientWidth ||
          param.point.y < 0 ||
          param.point.y > chartContainerRef.current.clientHeight
        ) {
          toolTip.style.display = 'none';
        } else {
          // time will be in the same format that we supplied to setData.
          const dateStr = new Date(param.time * 1000).toLocaleString('ru-RU')
          toolTip.style.display = 'block';
          const volumeData = param.seriesData.get(volumeSeries);
          const equityData = param.seriesData.get(equitySeries);
          const equity = equityData && equityData.value
          const balance = initialBalance * equity
          const time = volumeData && volumeData.time
          
          if (!stats) {
            return
          }

          const periods = stats[statsTimeKey] || {}
          const period = periods[time * 1000] || {}

          const volume = period.total?.result || 0
          const totalWR = Math.round(period.total?.winRate * 100 || 0)
          const totalTrades = period.total?.count || 0
          const longWR = Math.round(period.long?.winRate * 100 || 0)
          const longTrades = period.long?.count || 0
          const shortWR = Math.round(period.short?.winRate * 100 || 0)
          const shortTrades = period.short?.count || 0
          const breakoutWR = Math.round(period.breakout?.winRate * 100 || 0)
          const breakoutTrades = period.breakout?.count || 0
          const bounceWR = Math.round(period.bounce?.winRate * 100 || 0)
          const bounceTrades = period.bounce?.count || 0
          const breakoutLongWR = Math.round(period['breakout:long']?.winRate * 100 || 0)
          const breakoutLongTrades = period['breakout:long']?.count || 0
          const breakoutShortWR = Math.round(period['breakout:short']?.winRate * 100 || 0)
          const breakoutShortTrades = period['breakout:short']?.count || 0
          const bounceLongWR = Math.round(period['bounce:long']?.winRate * 100 || 0)
          const bounceLongTrades = period['bounce:long']?.count || 0
          const bounceShortWR = Math.round(period['bounce:short']?.winRate * 100 || 0)
          const bounceShortTrades = period['bounce:short']?.count || 0

          toolTip.innerHTML = `
          <div>
            ${dateStr}
            <br/>
            $${formatNumber(volume)}
            <br/>
            <div style="color:rgba(255, 255, 255, 1)">Equity: ${formatNumber(equity * 100)}% ($${formatNumber(balance || 0)})</div>
            <br/>
            <div style="color:rgba(255, 255, 255, 1)">Total: ${totalTrades}, WR: ${formatNumber(totalWR)}%</div>
            <div style="color:rgba(0, 150, 136, 1)">Long: ${longTrades}, WR: ${formatNumber(longWR)}%</div>
            <div style="color:rgba(255,82,82, 1)">Short ${shortTrades}, WR: ${formatNumber(shortWR)}%</div>
            <div style="color:rgba(200, 200, 0, 1)">Breakout: ${breakoutTrades}, WR: ${formatNumber(breakoutWR)}%</div>
            <div style="color:rgba(100, 200, 0, 1)">Breakout Long: ${breakoutLongTrades}, WR: ${formatNumber(breakoutLongWR)}%</div>
            <div style="color:rgba(200, 100, 0, 1)">Breakout Short: ${breakoutShortTrades}, WR: ${formatNumber(breakoutShortWR)}%</div>
            <div style="color:rgba(100, 100, 255, 1)">Bounce: ${bounceTrades}, WR: ${formatNumber(bounceWR)}%</div>
            <div style="color:rgba(100, 200, 200, 1)">Bounce Long: ${bounceLongTrades}, WR: ${formatNumber(bounceLongWR)}%</div>
            <div style="color:rgba(200, 100, 200, 1)">Bounce Short: ${bounceShortTrades}, WR: ${formatNumber(bounceShortWR)}%</div>
          </div>`;

          const y = param.point.y;
          let left = param.point.x + toolTipMargin;
          if (left > chartContainerRef.current.clientWidth - toolTipWidth) {
            left = param.point.x - toolTipMargin - toolTipWidth;
          }

          let top = y + toolTipMargin;
          if (top > chartContainerRef.current.clientHeight - toolTipHeight) {
            top = y - toolTipHeight - toolTipMargin;
          }
          toolTip.style.left = left + 'px';
          toolTip.style.top = top + 'px';
        }
      } catch (error) {
        console.log('chart.subscribeCrosshairMove error', error)
      }
    });


    return () => {
      currentRef.removeChild(toolTip);
    };
  }, [stats])

  // resize chart
  useEffect(() => {
    if (chartRef.current) {
      if (resizing) {
        chartRef.current.applyOptions({
          layout: {
            background: {
              type: 'solid',
              color: 'rgba(0,0,0,1)',
            }
          }
        });
      } else {
        chartRef.current.applyOptions({
          layout: opts.layout
        });
      }
    }
  }, [resizing])

  // resize chart 2
  useEffect(() => {
    if (chartRef.current) {
      const width = chartContainerRef.current.clientWidth;
      chartRef.current.applyOptions({
        width,
        height,
      });
    }
  }, [width, height])

  // init chart data series
  useEffect(() => {
    if (!stats) return
    volumeSeriesRef.current.setData([]);

    const timePeriods = stats[statsTimeKey]

    const timeData = []
    for (const dateStr in timePeriods) {
      const date = parseInt(dateStr)
      timeData.push({
        time: date / 1000,
        value: timePeriods[dateStr]
      })
    }
    timeData.sort((a, b) => a.time - b.time);

    try {
      let volumeData = timeData.map(period => ({
        time: period.time,
        value: Math.abs(period.value.total.result),
        color: period.value.total.result > 0
          ? 'rgba(0, 150, 136, 0.8)'
          : 'rgba(255,82,82, 0.8)',
      }))
      volumeSeriesRef.current.setData(volumeData);
    } catch (error) {
      console.log('volumeSeries.setData error', error)
    }


    try {
      let balance = null
      let equity = timeData.map(period => {
        // TODO: equity
        if (balance === null) {
          balance = period.value.initBalance
          initialBalance = period.value.initBalance
          // console.log('set init balance', period.value.initBalance)
        }
        
        const result = period.value.total.result
        balance += result
        const value = balance / initialBalance
        return { time: period.time, value }
      })
      equitySeriesRef.current.setData(equity);
    } catch (error) {
      console.log('series.setData equitySeries', error)
    }

    try {
      let totalWinrate = timeData.map(period => {
        return { time: period.time, value: period.value.total.winRate }
      })
      totalSeriesRef.current.setData(totalWinrate);
    } catch (error) {
      console.log('series.setData totalSeries', error)
    }
    try {
      let longWinrate = timeData.map(period => {
        return { time: period.time, value: period.value.long.winRate }
      })
      longSeriesRef.current.setData(longWinrate);
    } catch (error) {
      console.log('series.setData longSeries', error)
    }
    try {
      let shortWinrate = timeData.map(period => {
        return { time: period.time, value: period.value.short.winRate }
      })
      shortSeriesRef.current.setData(shortWinrate);
    } catch (error) {
      console.log('series.setData shortSeries', error)
    }
    try {
      let breakoutWinrate = timeData.map(period => {
        return { time: period.time, value: period.value.breakout.winRate }
      })
      breakoutSeriesRef.current.setData(breakoutWinrate);
    } catch (error) {
      console.log('series.setData breakoutSeries', error)
    }
    try {
      let breakoutLongWinrate = timeData.map(period => {
        return { time: period.time, value: period.value['breakout:long'].winRate }
      })
      breakoutLongSeriesRef.current.setData(breakoutLongWinrate);
    } catch (error) {
      console.log('series.setData breakoutLong', error)
    }
    try {
      let breakoutShortWinrate = timeData.map(period => {
        return { time: period.time, value: period.value['breakout:short'].winRate }
      })
      breakoutShortSeriesRef.current.setData(breakoutShortWinrate);
    } catch (error) {
      console.log('series.setData breakoutShort', error)
    }
    try {
      let bounceWinrate = timeData.map(period => {
        return { time: period.time, value: period.value.bounce.winRate }
      })
      bounceSeriesRef.current.setData(bounceWinrate);
    } catch (error) {
      console.log('series.setData bounceSeries', error)
    }
    try {
      let bounceLongWinrate = timeData.map(period => {
        return { time: period.time, value: period.value['bounce:long'].winRate }
      })
      bounceLongSeriesRef.current.setData(bounceLongWinrate);
    } catch (error) {
      console.log('series.setData bounceLong', error)
    }
    try {
      let bounceShortWinrate = timeData.map(period => {
        return { time: period.time, value: period.value['bounce:short'].winRate }
      })
      bounceShortSeriesRef.current.setData(bounceShortWinrate);
    } catch (error) {
      console.log('series.setData bounceShort', error)
    }

    chartRef.current.timeScale().fitContent();

  }, [
    chartRef.current,
    stats,
  ]);

  return (
    <Resizable
      minHeight={200}
      onResizeStop={(e, direction, ref, d) => {
        const newWidth = width + d.width
        const newHeight = height + d.height
        setWidth(newWidth);
        setHeight(newHeight);
        setResizing(false);
        localforage.setItem('statsChartWidthState', newWidth);
        localforage.setItem('statsChartHeightState', newHeight);
      }}
      onResizeStart={(e, direction, ref, d) => {
        setResizing(true);
      }}
      style={{
        boxShadow: '1px 1px 2px 1px rgba(0,0,0,0.5)',
        backgroundColor: resizing ? 'black' : 'transparent',
      }}
    >
      <div className='twchart' ref={chartContainerRef} />
    </Resizable>);
};

export default StatsChart;

function formatNumber(num, significantDigits = 3) {
  // Включаем массив суффиксов внутрь функции для полной инкапсуляции
  const suffixes = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
    1000000000000: 'T'
  };

  const absNum = Math.abs(num);
  const sigDelta = Math.max(0, significantDigits - 3)

  if (absNum >= 1000) {
    let scale = 1;
    let suffix = '';
    let scaledNum = num;

    // Определяем нужный делитель и суффикс
    while (scaledNum >= 1000 && scale <= 1e12) {
      scale *= 1000;
      scaledNum = num / scale;
      suffix = suffixes[scale] || '';
    }

    // Округляем число в зависимости от величины
    if (scaledNum >= 100) {
      return (scaledNum.toFixed(sigDelta) + suffix);
    } else if (scaledNum >= 10) {
      return (scaledNum.toFixed(sigDelta + 1) + suffix);
    } else {
      return (scaledNum.toFixed(sigDelta + 2) + suffix);
    }
  } else if (absNum < 1) {
    // Используем toPrecision для чисел меньше 1
    return num.toPrecision(significantDigits);
  } else {

    // Числа от 1 до 999, применяем логику похожую на большие числа, но без суффикса
    if (num >= 100) {
      return num.toFixed(sigDelta);
    } else if (num >= 10) {
      return num.toFixed(sigDelta + 1);
    } else {
      return num.toFixed(sigDelta + 2);
    }
  }
}
