import React, { useEffect, useCallback, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getSecondStart } from '../utils';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  currentSymbolState,
  updateRateIntervalState,
} from '#state';
import {
  wsTimerState,
  tradesState,
  candlesState,
  candleUpdatesState,
  lastCandleUpdateState,
} from '#state/data';

import { tradeBuffers, clustersStore, orderBooks } from '#state/local';

const wsBase = 'wss://fstream.binance.com/ws/'
const wsSpotBase = 'wss://stream.binance.com/ws/'

let OrderBooksResampleTime = 500
const OrderBooksUpdatesLimit = 100000

const TimeLimitTrades = 2 * 60 * 1000 + 1000
const TimeLimitUpdates = 2 * 60 * 1000 + 1000
const TimeLimitBooks = 2 * 60 * 1000 + 1000

export const BinanceWS = () => {
  const updateRateInterval = useRecoilValue(updateRateIntervalState);
  const setWsTimer = useSetRecoilState(wsTimerState);
  const symbol = useRecoilValue(currentSymbolState);
  const setTrades = useSetRecoilState(tradesState);
  const [candles, setCandles] = useRecoilState(candlesState);
  const [candleUpdates, setCandleUpdates] = useRecoilState(candleUpdatesState);
  const setLastCandleUpdate = useSetRecoilState(lastCandleUpdateState);
  // 
  let spotSymbol = symbol
  if (symbol.slice(0, 4) === '1000') {
    spotSymbol = symbol.slice(4)
  }
  // 
  const tradesFuturesURL = `${wsBase}${symbol.toLowerCase()}@aggTrade`
  // const tradesFuturesURL = `${wsBase}${symbol.toLowerCase()}@trade`
  const tradesSpotURL = `${wsSpotBase}${spotSymbol.toLowerCase()}@trade`
  const bookFuturesURL = `${wsBase}${symbol.toLowerCase()}@depth@100ms`
  const bookSpotURL = `${wsSpotBase}${spotSymbol.toLowerCase()}@depth@100ms`
  const klineURL = `${wsBase}${symbol.toLowerCase()}@kline_1m`
  // const miniTickersURL = `${wsBase}!miniTicker@arr`

  OrderBooksResampleTime = updateRateInterval

  useEffect(() => {
    // trades
    tradeBuffers.futures = []
    tradeBuffers.spot = []
    setTrades([])
    setWsTimer(0)
    // candles
    setCandleUpdates([])
    setLastCandleUpdate(null)
    // clusters
    clustersStore.futures = {
      minute: 0,
      isFullMinute: false,
      currentCluster: {
        totalV: 0, totalT: 0,
        totalDV: 0, totalDT: 0,
        summV: {}, summT: {},
        buyV: {}, buyT: {},
        sellV: {}, sellT: {},
        deltaV: {}, deltaT: {}
      },
      buffer: [],
    }
    clustersStore.spot = {
      minute: 0,
      isFullMinute: false,
      currentCluster: {
        totalV: 0, totalT: 0,
        totalDV: 0, totalDT: 0,
        summV: {}, summT: {},
        buyV: {}, buyT: {},
        sellV: {}, sellT: {},
        deltaV: {}, deltaT: {}
      },
      buffer: [],
    }
    // futures
    orderBooks.futures.valid = false
    orderBooks.futures.snapshotLoading = false
    orderBooks.futures.buffer = []
    orderBooks.futures.bufferTimestamp = 0
    orderBooks.futures.updates = []
    orderBooks.futures.updatesResampled = []
    orderBooks.futures.books = []
    orderBooks.futures.book = {
      bids: new Map(),
      asks: new Map(),
      lastUpdateId: -1,
      lastUpdateTime: 0,
    }

    orderBooks.spot.valid = false
    orderBooks.spot.snapshotLoading = false
    orderBooks.spot.buffer = []
    orderBooks.spot.bufferTimestamp = 0
    orderBooks.spot.updates = []
    orderBooks.spot.updatesResampled = []
    orderBooks.spot.books = []
    orderBooks.spot.book = {
      bids: new Map(),
      asks: new Map(),
      firstUpdateId: -1,
      lastUpdateId: -1,
      lastUpdateTime: 0,
    }
  }, [symbol, setCandleUpdates, setLastCandleUpdate, setTrades]);


  useWebSocket(tradesFuturesURL, {
    onMessage: event => {
      // console.log('tradesFuturesURL', event);
      const msg = JSON.parse(event.data)
      if (msg.s !== symbol) return null

      const p = parseFloat(msg.p);
      const q = parseFloat(msg.q);
      const time = msg.T;
      const historyLimitTime = getSecondStart(time - TimeLimitTrades);
      msg.p = p;
      msg.q = q;

      // update order book
      if (orderBooks.futures.valid && orderBooks.futures.lastUpdateTime <= time) {
        let book
        if (msg.m) {
          book = orderBooks.futures.book.bids
        } else {
          book = orderBooks.futures.book.asks
        }
        const currentVolume = book.get(p)
        if (currentVolume) {
          const newVolume = currentVolume - q
          if (newVolume <= 0) {
            // console.log('trades delete', p, newVolume, currentVolume, q)
            book.delete(p)
          } else {
            // console.log('trades set', p, newVolume, currentVolume, q)
            book.set(p, newVolume)
          }
        }
      }

      // remove old trades
      const sliceIndex = tradeBuffers.futures.findIndex((t, i) => {
        if (t.T > historyLimitTime) {
          return true;
        }
      })
      const nt = tradeBuffers.futures.slice(sliceIndex).slice(-50000)
      nt.push(msg)
      tradeBuffers.futures = nt


      // const {
      //   wsMarket: market,
      //   T: time,
      //   s: symbol,
      //   p: price,
      //   q: quantity,
      //   m: sell, // is it market sell order
      // } = event;

      // const q = parseFloat(quantity)

      // const ss = symbolStates[symbol]
      const clustersOpts = { time, price: msg.p, q, sell: msg.m, store: clustersStore.futures }
      try {
        calculateClusters(clustersOpts)
      } catch (error) {
        console.log('calculateClusters error', clustersOpts, error)
      }
    }
  });

  useWebSocket(tradesSpotURL, {
    onMessage: event => {
      // console.log(event);
      const msg = JSON.parse(event.data)
      if (msg.s !== symbol) return null

      const p = parseFloat(msg.p);
      const q = parseFloat(msg.q);
      const time = msg.T;
      const historyLimitTime = getSecondStart(time - TimeLimitTrades);
      msg.p = p;
      msg.q = q;

      // update order book
      if (orderBooks.spot.valid && orderBooks.spot.lastUpdateTime <= time) {
        let book
        if (msg.m) {
          book = orderBooks.spot.book.bids
        } else {
          book = orderBooks.spot.book.asks
        }
        const currentVolume = book.get(p)
        if (currentVolume) {
          if (currentVolume - q <= 0) {
            book.delete(p)
          } else {
            book.set(p, currentVolume - q)
          }
        }
      }

      // remove old trades
      const sliceIndex = tradeBuffers.spot.findIndex((t, i) => {
        if (t.T > historyLimitTime) {
          return true;
        }
      })
      const nt = tradeBuffers.spot.slice(sliceIndex).slice(-50000)
      nt.push(msg)
      tradeBuffers.spot = nt

      const clustersOpts = { time, price: msg.p, q, sell: msg.m, store: clustersStore.spot }
      try {
        calculateClusters(clustersOpts)
      } catch (error) {
        console.log('calculateClusters error', clustersOpts, error)
      }

    }
  });

  useWebSocket(klineURL, {
    onMessage: event => {
      const msg = JSON.parse(event.data)
      if (msg.s !== symbol) return null

      const c = msg.k
      const candle = {
        startTime: c.t,
        open: c.o,
        high: c.h,
        low: c.l,
        close: c.c,
        volume: c.v,
        endTime: c.T,
        quoteVolume: c.q,
        trades: c.n,
      }
      if (c.x) {
        if (candles.length && candles[candles.length - 1].startTime === candle.startTime) {
          // console.log('update candle')
          const newCandles = candles.slice()
          newCandles[candles.length - 1] = candle
          setCandles(newCandles)
        } else {
          // console.log('add candle')
          setCandleUpdates(candleUpdates.concat([candle]))
        }
      } else {
        setLastCandleUpdate(candle)
      }
    }
  });

  const onFuturesBookMessage = getOnFuturesBookMessage(symbol)
  useWebSocket(bookFuturesURL, {
    onMessage: onFuturesBookMessage,
  });

  const onSpotBookMessage = getOnSpotBookMessage(symbol)
  useWebSocket(bookSpotURL, {
    onMessage: onSpotBookMessage,
  });

  // useWebSocket(miniTickersURL, {
  //   shouldReconnect: closeEvent => true,
  //   reconnectAttempts: 10,
  //   reconnectInterval: 3000,
  //   onOpen: event => {
  //     console.log('miniTickersURL', 'opened')
  //   },
  //   onMessage: event => {
  //     const msg = JSON.parse(event.data)
  //     console.log('miniTickers', msg)
  //   }
  // });


  useEffect(() => {
    const interval = setInterval(() => {
      // if (tradeBuffers.futures.length) setTrades(tradeBuffers.futures);
      setWsTimer(prev => prev + 1)
    }, updateRateInterval)
    return () => {
      clearInterval(interval)
    }
  }, [updateRateInterval, setTrades, symbol, setWsTimer]);


  // const handleClickSendMessage = useCallback(() => {
  //   return sendMessage('Hello')
  // }, []);

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: 'Connecting',
  //   [ReadyState.OPEN]: 'Open',
  //   [ReadyState.CLOSING]: 'Closing',
  //   [ReadyState.CLOSED]: 'Closed',
  //   [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  // }[readyState];

  return (
    <div>
      {/* <button
        onClick={handleClickSendMessage}
        disabled={readyState !== ReadyState.OPEN}
      >
        Click Me to send 'Hello'
      </button> */}
      {/* <div>The WebSocket is currently {connectionStatus}</div> */}
      {/* {lastMessage ? <div>Last message: {lastMessage.data}</div> : null} */}
    </div>
  );
};

export default BinanceWS;

// *********************************************
// Utils
// *********************************************

function mergeUpdates(updates) {
  const merged = {
    e: updates[0].e, // Event type
    E: updates[0].E, // Event time
    T: updates[updates.length - 1].T, // Transaction time 
    s: updates[0].s, // Symbol
    U: updates[0].U, // First update ID in event
    u: updates[updates.length - 1].u, // Final update ID in event
    pu: updates[updates.length - 1].pu, // Final update Id in last stream(ie `u` in last stream)
    b: new Map(), // Bids to be updated
    a: new Map(), // Asks to be updated
  };

  updates.forEach(update => {
    update.b.forEach(([price, quantity]) => {
      const p = parseFloat(price);
      const q = parseFloat(quantity);
      if (q === 0) {
        // merged.b.delete(price);
        merged.b.delete(p);
      } else {
        merged.b.set(p, q);
      }
    });
    update.a.forEach(([price, quantity]) => {
      const p = parseFloat(price);
      const q = parseFloat(quantity);
      if (q === 0) {
        // merged.a.delete(price);
        merged.a.delete(p);
      } else {
        merged.a.set(p, q);
      }
    });
  });

  // Конвертация из Map обратно в массивы
  merged.b = Array.from(merged.b.entries()).map(([price, quantity]) => [price.toString(), quantity.toString()]);
  merged.a = Array.from(merged.a.entries()).map(([price, quantity]) => [price.toString(), quantity.toString()]);

  return merged;
}

function cloneMap(original) {
  return new Map(Array.from(original, ([key, value]) => [key, value]));
}

// *********************************************
// Futures
// *********************************************

async function getFuturesSnapshot(symbol) {
  try {
    const response = await fetch(`https://fapi.binance.com/fapi/v1/depth?symbol=${symbol}&limit=1000`);
    const data = await response.json();

    console.log('getFuturesSnapshot call', data);

    const { bids, asks, lastUpdateId, T } = data;

    // Обновите локальный стакан ордеров
    const parsedAsks = asks.map(([price, quantity]) => [parseFloat(price), parseFloat(quantity)]);
    const parsedBids = bids.map(([price, quantity]) => [parseFloat(price), parseFloat(quantity)]);
    orderBooks.futures.book.asks = new Map(parsedAsks);
    orderBooks.futures.book.bids = new Map(parsedBids);
    orderBooks.futures.book.lastUpdateId = lastUpdateId;
    orderBooks.futures.book.lastUpdateTime = T;

    // save initial book
    orderBooks.futures.initialBook = {
      asks: new Map(parsedAsks),
      bids: new Map(parsedBids),
      lastUpdateId,
      lastUpdateTime: T,
    }

    // Применить обновления стакана
    orderBooks.futures.updates.forEach(update => {
      if (update.u > lastUpdateId) {
        update.a.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.futures.book.asks.delete(p);
          } else {
            orderBooks.futures.book.asks.set(p, q);
          }
        });

        update.b.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.futures.book.bids.delete(p);
          } else {
            orderBooks.futures.book.bids.set(p, q);
          }
        });

        orderBooks.futures.book.lastUpdateId = update.u;
        orderBooks.futures.book.lastUpdateTime = update.T;
      }
    });
    orderBooks.futures.snapshotLoading = false
    orderBooks.futures.valid = true
  } catch (error) {
    console.error(error);
    orderBooks.futures.snapshotLoading = true
    orderBooks.futures.valid = false
  }
}

function getOnFuturesBookMessage(symbol) {
  return async (event) => {
    const msg = JSON.parse(event.data);
    if (msg.s !== symbol) return null

    let loading = orderBooks.futures.snapshotLoading

    if (orderBooks.futures.book.lastUpdateId === -1 && !loading) {
      // При первом событии получите снапшот
      console.log('getOnFuturesBookMessage getFuturesSnapshot 1', orderBooks.futures.book.lastUpdateId, orderBooks.futures.snapshotLoading);
      loading = true
      orderBooks.futures.snapshotLoading = true
      orderBooks.futures.valid = false
      getFuturesSnapshot(symbol);
    }

    if (msg.pu === orderBooks.futures.book.lastUpdateId) {
      // Обновление стакана
      msg.a.forEach(([price, quantity]) => {
        const q = parseFloat(quantity);
        const p = parseFloat(price);
        // const prevVolume = orderBooks.futures.book.asks.get(p);
        if (q === 0) {
          // console.log('book prevVolume delete', p, q, prevVolume);
          orderBooks.futures.book.asks.delete(p);
        } else {
          // console.log('book prevVolume set', p, q, prevVolume);
          orderBooks.futures.book.asks.set(p, q);
        }
      });

      msg.b.forEach(([price, quantity]) => {
        const q = parseFloat(quantity);
        const p = parseFloat(price);
        // const prevVolume = orderBooks.futures.book.bids.get(p);
        if (q === 0) {
          orderBooks.futures.book.bids.delete(p);
          // console.log('book prevVolume delete', p, q, prevVolume);
        } else {
          orderBooks.futures.book.bids.set(p, q);
          // console.log('book prevVolume set', p, q, prevVolume);
        }
      });
    } else if (!loading) {
      // console.log('getOnFuturesBookMessage getFuturesSnapshot 2', msg.pu, orderBooks.futures.book.lastUpdateId, orderBooks.futures.snapshotLoading);
      // loading = true
      // orderBooks.futures.snapshotLoading = true
      // orderBooks.futures.valid = false
      // getFuturesSnapshot(symbol);
    }

    // update orderBooks.futures
    orderBooks.futures.book.lastUpdateId = msg.u;
    orderBooks.futures.book.lastUpdateTime = msg.T;
    orderBooks.futures.updates.push(msg);
    // orderBooks.futures.books.push(orderBooks.futures.book);

    // Если буфер пуст или прошло 500мс с момента создания буфера, создайте новый ивент обновления
    if (orderBooks.futures.buffer.length === 0 || (msg.T - orderBooks.futures.bufferTimestamp) >= OrderBooksResampleTime) {
      if (orderBooks.futures.buffer.length > 0) {
        const mergedUpdate = mergeUpdates(orderBooks.futures.buffer);
        orderBooks.futures.updatesResampled.push(mergedUpdate);

        // push book history 
        orderBooks.futures.books.push({
          lastUpdateId: orderBooks.futures.book.lastUpdateId,
          lastUpdateTime: orderBooks.futures.book.lastUpdateTime,
          bids: cloneMap(orderBooks.futures.book.bids),
          asks: cloneMap(orderBooks.futures.book.asks),
        });
      }

      orderBooks.futures.buffer = [msg];
      orderBooks.futures.bufferTimestamp = msg.T;
    } else {
      orderBooks.futures.buffer.push(msg);
    }

    // slice left limit
    // updates 
    // find left limit
    const historyLimitTimeUpdates = getSecondStart(msg.T - TimeLimitUpdates);
    const sliceIndexUpdates = orderBooks.futures.updates.findIndex((u, i) => {
      if (u.T > historyLimitTimeUpdates) {
        return true;
      }
    })
    const newUpdate = orderBooks.futures.updates.slice(sliceIndexUpdates).slice(-OrderBooksUpdatesLimit)
    orderBooks.futures.updates = newUpdate

    // updates resampled
    // find left limit
    const sliceIndexUpdatesResampled = orderBooks.futures.updatesResampled.findIndex((u, i) => {
      if (u.T > historyLimitTimeUpdates) {
        return true;
      }
    })
    const newUpdateResampled = orderBooks.futures.updatesResampled.slice(sliceIndexUpdatesResampled).slice(-OrderBooksUpdatesLimit)
    orderBooks.futures.updatesResampled = newUpdateResampled

    // books
    // find left limit
    const historyLimitTimeBooks = getSecondStart(msg.T - TimeLimitBooks);
    const sliceIndexBooks = orderBooks.futures.books.findIndex((b, i) => {
      if (b.lastUpdateTime > historyLimitTimeBooks) {
        return true;
      }
    })
    const newBooks = orderBooks.futures.books.slice(sliceIndexBooks).slice(-OrderBooksUpdatesLimit)
    orderBooks.futures.books = newBooks

    // console.log('bookFutures msg', msg);
    // console.log('orderBooks.futures', orderBooks.futures);
  }
}

// *********************************************
// Spot
// *********************************************

async function getSpotSnapshot(symbol) {
  try {
    const response = await fetch(`https://api.binance.com/api/v3/depth?symbol=${symbol}&limit=1000`);
    const data = await response.json();
    console.log('getSpotSnapshot call', data);
    const { bids, asks, lastUpdateId } = data;

    // Обновите локальный стакан ордеров
    const parsedAsks = asks.map(([price, quantity]) => [parseFloat(price), parseFloat(quantity)]);
    const parsedBids = bids.map(([price, quantity]) => [parseFloat(price), parseFloat(quantity)]);
    orderBooks.spot.book.asks = new Map(parsedAsks);
    orderBooks.spot.book.bids = new Map(parsedBids);
    // orderBooks.spot.book.firstUpdateId = firstUpdateId;
    orderBooks.spot.book.lastUpdateId = lastUpdateId;
    // orderBooks.spot.book.lastUpdateTime = T;

    // save initial book
    orderBooks.spot.initialBook = {
      asks: new Map(parsedAsks),
      bids: new Map(parsedBids),
      lastUpdateId,
      // firstUpdateId,
    }

    // Применить обновления стакана
    orderBooks.spot.updates.forEach(update => {
      if (update.U <= lastUpdateId + 1 && update.u >= lastUpdateId + 1) {
        update.a.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.spot.book.asks.delete(p);
          } else {
            orderBooks.spot.book.asks.set(p, q);
          }
        });

        update.b.forEach(([price, quantity]) => {
          const q = parseFloat(quantity);
          const p = parseFloat(price);
          if (q === 0) {
            orderBooks.spot.book.bids.delete(p);
          } else {
            orderBooks.spot.book.bids.set(p, q);
          }
        });

        orderBooks.spot.book.firstUpdateId = update.U;
        orderBooks.spot.book.lastUpdateId = update.u;
        orderBooks.spot.book.lastUpdateTime = update.E;
      }
    });
    orderBooks.spot.snapshotLoading = false
    orderBooks.spot.valid = true
  } catch (error) {
    console.error(error);
    orderBooks.spot.snapshotLoading = true
    orderBooks.spot.valid = false
  }
}

function getOnSpotBookMessage(symbol) {
  return async (event) => {
    const msg = JSON.parse(event.data);
    if (msg.s !== symbol) return null

    // console.log('getOnSpotBookMessage', msg);

    if (orderBooks.spot.book.lastUpdateId === -1 && !orderBooks.spot.snapshotLoading) {
      // При первом событии получите снапшот
      console.log('getOnSpotBookMessage getSpotSnapshot 1', orderBooks.spot.book.lastUpdateId, orderBooks.spot.snapshotLoading);
      orderBooks.spot.snapshotLoading = true
      orderBooks.spot.valid = false
      getSpotSnapshot(symbol);
    }

    if (msg.U === orderBooks.spot.book.lastUpdateId + 1) {
      // Обновление стакана
      msg.a.forEach(([price, quantity]) => {
        const q = parseFloat(quantity);
        const p = parseFloat(price);
        if (q === 0) {
          orderBooks.spot.book.asks.delete(p);
        } else {
          orderBooks.spot.book.asks.set(p, q);
        }
      });

      msg.b.forEach(([price, quantity]) => {
        const q = parseFloat(quantity);
        const p = parseFloat(price);
        if (q === 0) {
          orderBooks.spot.book.bids.delete(p);
        } else {
          orderBooks.spot.book.bids.set(p, q);
        }
      });
    } else if (!orderBooks.spot.snapshotLoading) {
      console.log('getOnSpotBookMessage getSpotSnapshot 2', msg.U, orderBooks.spot.book.lastUpdateId, orderBooks.spot.snapshotLoading);
      orderBooks.spot.snapshotLoading = true
      orderBooks.spot.valid = false
      getSpotSnapshot(symbol);
    }

    // update orderBooks.spot
    orderBooks.spot.book.firstUpdateId = msg.U;
    orderBooks.spot.book.lastUpdateId = msg.u;
    orderBooks.spot.book.lastUpdateTime = msg.E;
    orderBooks.spot.updates.push(msg);
    // orderBooks.spot.books.push(orderBooks.spot.book);

    // Если буфер пуст или прошло 500мс с момента создания буфера, создайте новый ивент обновления
    if (orderBooks.spot.buffer.length === 0 || (msg.E - orderBooks.spot.bufferTimestamp) >= OrderBooksResampleTime) {
      if (orderBooks.spot.buffer.length > 0) {
        const mergedUpdate = mergeUpdates(orderBooks.spot.buffer);
        orderBooks.spot.updatesResampled.push(mergedUpdate);

        // push book history 
        orderBooks.spot.books.push({
          firstUpdateId: orderBooks.spot.book.firstUpdateId,
          lastUpdateId: orderBooks.spot.book.lastUpdateId,
          lastUpdateTime: orderBooks.spot.book.lastUpdateTime,
          bids: cloneMap(orderBooks.spot.book.bids),
          asks: cloneMap(orderBooks.spot.book.asks),
        });
      }

      orderBooks.spot.buffer = [msg];
      orderBooks.spot.bufferTimestamp = msg.E;
    } else {
      orderBooks.spot.buffer.push(msg);
    }

    // slice left limit
    // updates 
    // find left limit
    const historyLimitTimeUpdates = getSecondStart(msg.E - TimeLimitUpdates);
    const sliceIndexUpdates = orderBooks.spot.updates.findIndex((u, i) => {
      if (u.E > historyLimitTimeUpdates) {
        return true;
      }
    })
    const newUpdate = orderBooks.spot.updates.slice(sliceIndexUpdates).slice(-OrderBooksUpdatesLimit)
    orderBooks.spot.updates = newUpdate

    // updates resampled
    // find left limit
    const sliceIndexUpdatesResampled = orderBooks.spot.updatesResampled.findIndex((u, i) => {
      if (u.E > historyLimitTimeUpdates) {
        return true;
      }
    })
    const newUpdateResampled = orderBooks.spot.updatesResampled.slice(sliceIndexUpdatesResampled).slice(-OrderBooksUpdatesLimit)
    orderBooks.spot.updatesResampled = newUpdateResampled

    // books
    // find left limit
    const historyLimitTimeBooks = getSecondStart(msg.E - TimeLimitBooks);
    const sliceIndexBooks = orderBooks.spot.books.findIndex((b, i) => {
      if (b.lastUpdateTime > historyLimitTimeBooks) {
        return true;
      }
    })
    const newBooks = orderBooks.spot.books.slice(sliceIndexBooks).slice(-OrderBooksUpdatesLimit)
    orderBooks.spot.books = newBooks

    // console.log('bookSpot msg', msg);
    // console.log('orderBooks.spot', orderBooks.spot);
  }
}

// *********************************************
// Clusters
// *********************************************

function calculateClusters(props) {
  const { time, price, q, sell, store } = props
  const clusters = store

  const minuteStart = Math.floor(time / 1000 / 60) * 60 * 1000
  const clustersMinute = clusters.minute
  const isFullMinute = clusters.isFullMinute
  const currentCluster = clusters.currentCluster

  // total
  const totalVolume = currentCluster.totalV || 0
  currentCluster.totalV = totalVolume + q;
  const totalTrades = currentCluster.totalT || 0
  currentCluster.totalT = totalTrades + 1;

  // summ
  const summVolume = currentCluster.summV[price] || 0
  currentCluster.summV[price] = summVolume + q;
  const summTrades = currentCluster.summT[price] || 0
  currentCluster.summT[price] = summTrades + 1;

  if (sell) {
    const sellVolume = currentCluster.sellV[price] || 0
    currentCluster.sellV[price] = sellVolume + q;
    const sellTrades = currentCluster.sellT[price] || 0
    currentCluster.sellT[price] = sellTrades + 1;
    // delta price
    const deltaVolume = currentCluster.deltaV[price] || 0
    currentCluster.deltaV[price] = deltaVolume - q;
    const deltaTrades = currentCluster.deltaT[price] || 0
    currentCluster.deltaT[price] = deltaTrades - 1;
    // delta total
    const totalDeltaVolume = currentCluster.totalDV || 0
    currentCluster.totalDV = totalDeltaVolume - q;
    const totalDeltaTrades = currentCluster.totalDT || 0
    currentCluster.totalDT = totalDeltaTrades - 1;
  } else {
    const buyVolume = currentCluster.buyV[price] || 0
    currentCluster.buyV[price] = buyVolume + q;
    const buyTrades = currentCluster.buyT[price] || 0
    currentCluster.buyT[price] = buyTrades + 1;
    // delta price
    const deltaVolume = currentCluster.deltaV[price] || 0
    currentCluster.deltaV[price] = deltaVolume + q;
    const deltaTrades = currentCluster.deltaT[price] || 0
    currentCluster.deltaT[price] = deltaTrades + 1;
    // delta total
    const totalDeltaVolume = currentCluster.totalDV || 0
    currentCluster.totalDV = totalDeltaVolume + q;
    const totalDeltaTrades = currentCluster.totalDT || 0
    currentCluster.totalDT = totalDeltaTrades + 1;
  }

  if (clustersMinute !== minuteStart) {
    // new minute start
    clusters.currentCluster = {
      time: minuteStart,
      totalV: 0, totalT: 0,
      totalDV: 0, totalDT: 0,
      summV: {}, summT: {},
      buyV: {}, buyT: {},
      sellV: {}, sellT: {},
      deltaV: {}, deltaT: {}
    }
    clusters.isFullMinute = false

    if (isFullMinute) {
      clusters.buffer.push(currentCluster)
      clusters.buffer = clusters.buffer.slice(-60 * 12) // last 12 hours hard limit
    }

    if (clustersMinute !== 0) {
      clusters.isFullMinute = true
    }
    clusters.minute = minuteStart
    console.log('clusters', isFullMinute, store);
  }
}
