import React, { useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState } from 'recoil';
import { clusterCandleIntervalState } from '#state';

export const ClusterCandleIntervalSelect = () => {
  const [candleInterval, setCandleInterval] = useRecoilState(clusterCandleIntervalState);

  const handleChange = (event) => {
    setCandleInterval(event.target.value);
  };

  useEffect(() => {
    localforage.setItem('clusterCandleIntervalState', candleInterval);
  }, [candleInterval]);

  return (
    <FormControl>
      <InputLabel id="cluster-candle-interval-label">Clusters</InputLabel>
      <Select
        labelId="cluster-candle-interval-label"
        id="cluster-candle-interval-select"
        size='small'
        value={candleInterval}
        onChange={handleChange}
      >
        <MenuItem value="1m">1m</MenuItem>
        <MenuItem value="5m">5m</MenuItem>
        <MenuItem value="15m">15m</MenuItem>
        <MenuItem value="30m">30m</MenuItem>
        <MenuItem value="1h">1h</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ClusterCandleIntervalSelect;
