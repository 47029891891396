import { atom, selector } from 'recoil';

// **********************************************
// STATE
// **********************************************

export const apiKeyState = atom({
  key: 'apiKeyState',
  default: '',
});

export const showControlsState = atom({
  key: 'showControlsState',
  default: false,
});

export const currentSymbolState = atom({
  key: 'currentSymbolState',
  default: 'ETHUSDT',
});

export const unixTimeState = atom({
  key: 'unixTimeState',
  default: '',
});

export const updateRateIntervalState = atom({
  key: 'updateRateIntervalState',
  default: 100,
});

export const loadCandlesState = atom({
  key: 'loadCandlesState',
  default: 15,
});

export const enableZoomState = atom({
  key: 'enableZoomState',
  default: false,
});

export const recordsModeState = atom({
  key: 'recordsModeState',
  default: 'move',
});

// **********************************************
// Trades Chart Levels
// **********************************************

export const orderBookScaleState = atom({
  key: 'orderBookScaleState',
  default: 0.001,
});

export const levelsPercentileState = atom({
  key: 'levelsPercentileState',
  default: 99,
});

export const levelsMinimumVolumeState = atom({
  key: 'levelsMinimumVolumeState',
  default: 200,
});
export const levelsMinimumVolumeSpotState = atom({
  key: 'levelsMinimumVolumeSpotState',
  default: 200,
});

// **********************************************
// Candle Chart Levels
// **********************************************

export const mainCandleIntervalState = atom({
  key: 'mainCandleIntervalState',
  default: '1h', // default interval is 1 minute
});

export const mainWindowSizeState = atom({
  key: 'mainWindowSize',
  default: 12,
});

export const mainFilter2DurationState = atom({
  key: 'mainFilter2DurationState',
  default: 24, 
});

export const mainF1PercentState = atom({
  key: 'mainF1PercentState',
  default: 0.5, 
});

export const mainF2PercentState = atom({
  key: 'mainF2PercentState',
  default: 0.003, 
});

export const tempCandleIntervalState = atom({
  key: 'tempCandleIntervalState',
  default: '1h', // default interval is 1 minute
});

export const tempWindowSizeState = atom({
  key: 'tempWindowSize',
  default: 2,
});

export const tempFilter2DurationState = atom({
  key: 'tempFilter2DurationState',
  default: 4, 
});

export const tempF1PercentState = atom({
  key: 'tempF1PercentState',
  default: 0.5, 
});

export const tempF2PercentState = atom({
  key: 'tempF2PercentState',
  default: 0.0005, 
});

// **********************************************
// Clusters
// **********************************************

export const clusterCandleIntervalState = atom({
  key: 'clusterCandleIntervalState',
  default: '1m', // default interval is 1 minute
});

export const currentFactorState = atom({
  key: 'currentFactorState',
  default: 6,
});

export const levelsShowedState = atom({
  key: 'levelsShowedState',
  default: 8,
});

// **********************************************
// Book
// **********************************************

export const bookShowLevelsState = atom({
  key: 'bookShowLevelsState',
  default: 75,
});

export const resampleOnServerState = atom({
  key: 'resampleOnServerState',
  default: false,
});

export const resampleOnServerLevelState = atom({
  key: 'resampleOnServerLevelState',
  default: 5,
});

export const bookUpdatesResampleState = atom({
  key: 'bookUpdatesResampleState',
  default: 5,
});

// ***********************************
// appearance
// ***********************************

export const appearanceState = atom({
  key: 'appearanceState',
  default: 'dark',
});
export const savedColorSchemeState = atom({
  key: 'savedColorSchemeState',
  default: 'system',
});