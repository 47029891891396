
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import localforage from 'localforage';
import { Box } from '@mui/material';
import { Resizable } from 're-resizable';

import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  apiKeyState,
  mainCandleIntervalState,
  tempCandleIntervalState,
  clusterCandleIntervalState,
  currentSymbolState,
  unixTimeState,
  loadCandlesState,
  levelsShowedState,
  currentFactorState,
  bookShowLevelsState,
  bookUpdatesResampleState,
  resampleOnServerState,
  resampleOnServerLevelState,
} from '#state';

import {
  exchangeInfoState,
  candlesState,
  candlesH1State,
  candlesSelector,
  clustersState,
  clustersSelector,
  bookState,
  bookSelector,
  bookUpdatesState,
  bookUpdatesSelector,
  resampledUpdatesCache,
} from '#state/data';

import { intervalToMinutes } from '#src/utils'

import { Controls } from './controls';

// import SimpleChart2 from "./components/fc2";
import { TWChart } from './charts/CandleChart';
import ClustersChart from "./charts/ClustersChart";
import WindowChart from "./charts/WindowChart";
import BookChart from "./charts/OrderBookChart";
import { TradesChart } from './charts/TradesChart';
import { IndicatorsChart } from './charts/IndicatorsChart';
import { OrderBookList } from './charts/OrderBookList';

import {
  getCandles,
  getCandlesBinance,
  getCandlesBinanceH1,
  getClusters,
  getOrderBook,
  getOrderBookUpdates,
  getExchangeInfo,
} from '../utils';

// import { client, wsClient } from './Binance'
import { BinanceWS } from './BinanceWS';
import { TradeSimulator } from './TradeSimulator';


export function Realtime(props) {
  // const [currentSymbol, setCurrentSymbol] = useRecoilState(currentSymbolState);
  // const [startUnixTime, setStartUnixTime] = useRecoilState(unixTimeState);
  // const [loadCandles, setLoadCandles] = useRecoilState(loadCandlesState);

  // const setClustersData = useSetRecoilState(clustersState);
  // const candleInterval = useRecoilValue(candleIntervalState);
  // const clusterCandleInterval = useRecoilValue(clusterCandleIntervalState);
  // const setBookData = useSetRecoilState(bookState);
  // const orderBook = useRecoilValue(bookSelector);
  // const setBookUpdatesData = useSetRecoilState(bookUpdatesState);
  // const orderBookUpdates = useRecoilValue(bookUpdatesSelector);
  // const [resampleOnServer, setResampleOnServer] = useRecoilState(resampleOnServerState);
  // const resampleOnServerLevel = useRecoilValue(resampleOnServerLevelState);
  // const [prevMinute, setPrevMinute] = useState(null);

  useEffect(() => {
    // setStartUnixTime(Date.now());
  }, []);

  // Load Candles Binance
  // useEffect(() => {
  //   const run = async () => {
  //     const symbol = currentSymbol;
  //     const startTime = Date.now() // startUnixTime && parseInt(startUnixTime) ||
  //     const symbolInfo = exchangeInfo.symbols.find((s) => s.symbol === symbol)

  //     let m1Start = Math.floor(startTime / 24 / 60 / 60 / 1000) * 24 * 60 * 60 * 1000
  //     m1Start = m1Start - 1000 * 60 * 60 * 24 * 7;
  //     const toH1 = Math.floor(m1Start / 24 / 60 / 60 / 1000) * 24 * 60 * 60 * 1000

  //     let fromH1 = toH1 - 1000 * 60 * 60 * 24 * 40 * 3;
  //     if (symbolInfo.onboardDate > fromH1) {
  //       fromH1 = symbolInfo.onboardDate;
  //     }

  //     let from = toH1 //startTime - 1000 * 60 * 60 * 24 * 7; // 10k min is ~ 1 week (10080)

  //     if (symbolInfo.onboardDate > from) {
  //       from = symbolInfo.onboardDate;
  //     }
  //     const to = startTime;
  //     const interval = '1m';
  //     const sharedOpts = { from, to, symbol, interval };
  //     await getCandlesBinance({
  //       ...sharedOpts,
  //       setCandlesData
  //     });


      
  //     await getCandlesBinanceH1({
  //       symbol,
  //       interval: '1h',
  //       from: fromH1,
  //       to: toH1,
  //       setCandlesH1Data
  //     });
  //   }
  //   if (exchangeInfo) {
  //     run();
  //   }
  // }, [currentSymbol, exchangeInfo, loadCandles, startUnixTime]);

  // Clusters
  // useEffect(() => {
  //   const run = async () => {
  //     const startTime = Date.now()

  //     const from = startTime - 1000 * 60 * loadCandles * 1 - 1000 * 60;
  //     const to = startTime;
  //     const symbol = currentSymbol;
  //     const interval = clusterCandleInterval;
  //     const opts = { from, to, symbol, interval, setClustersData };

  //     await getClusters(opts);
  //   }

  //   run();

  //   const interval = setInterval(() => {
  //     let minute = Math.floor(Date.now() / 1000 / 60) * 60 * 1000 - 1000;
  //     if (prevMinute !== minute) {
  //       setPrevMinute(minute);
  //     }
  //   }, 1000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [currentSymbol, loadCandles, startUnixTime, clusterCandleInterval, prevMinute, setClustersData]);

  // getOrderBookUpdates
  // useEffect(() => {
  //   const run = async () => {
  //     const startTime = startUnixTime && parseInt(startUnixTime) || Date.now()
  //     const from = orderBook[0].time
  //     const to = from + 1000 * 60 * loadCandles
  //     const symbol = currentSymbol;
  //     const interval = '1m';
  //     await getOrderBookUpdates({
  //       from,
  //       to,
  //       symbol,
  //       interval,
  //       resample: resampleOnServer ? resampleOnServerLevel : 0,
  //       resampleOnServer,
  //       setBookUpdatesData
  //     });
  //   }
  //   if (orderBook && orderBook.length) {
  //     // run();
  //   }
  // }, [currentSymbol, orderBook, resampleOnServerLevel, resampleOnServer]);

  // const dexieResult = useLiveQuery(async () => {
  //   const friends = await db.orderBook
  //     .where('symbol')
  //     .equals('BTCUSDT')
  //     .toArray();
  //   return friends;
  // }, []);
  // console.log('dexieResult', dexieResult);

  // const friendCount = useLiveQuery(() => db.orderBook.count());
  // console.log('friendCount', friendCount);


  // console.log('bookData', bookData);
  // console.log('orderBook', orderBook);
  // console.log('bookUpdatesData', bookUpdatesData);
  // console.log('orderBookUpdates', orderBookUpdates);

  console.log('realtime rerender')

  return (
    <>
      <Box sx={{ mt: 1, fontSize: '1rem', display: 'flex' }}>
        {/* <TradeSimulator /> */}
      </Box>

      <Box>
        <BinanceWS />
      </Box>

      <CandleLoader />
      <ClustersLoader />

      <Box sx={{ mt: 1, fontSize: '1rem' }}>
        <OrderBookList />
      </Box>

      <Box sx={{ mt: 1, mb: 1, }}>
        {/* <TradesChart /> */}
      </Box>

      <Box sx={{ mt: 1, mb: 2 }}>
        {/* <IndicatorsChart /> */}
      </Box>

      {/* <Box sx={{ mt: 1, mb: 1 }}>
        <ClustersChart />
      </Box> */}

      <Box sx={{}}>
        {/* <TWChart /> */}
      </Box>

      <Box sx={{ my: 1, }}>
        {/* <BookChart /> */}
      </Box>
    </>
  );
}

function CandleLoader() {
  const currentSymbol = useRecoilValue(currentSymbolState);
  const exchangeInfo = useRecoilValue(exchangeInfoState);
  const tempCandleInterval = useRecoilValue(tempCandleIntervalState);
  const setCandlesData = useSetRecoilState(candlesState);
  const setCandlesH1Data = useSetRecoilState(candlesH1State);

  useEffect(() => {
    const run = async () => {
      const symbol = currentSymbol;
      const startTime = Date.now()
      const symbolInfo = exchangeInfo.symbols.find((s) => s.symbol === symbol)

      let m1StartShiftBack = 1000 * 60 * 60 * 24 * 2;
      switch (tempCandleInterval) {
        case '1m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 2;
          break;
        case '5m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 4;
          break;
        case '15m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 6;
          break;
        case '30m':
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 8;
          break;
        default:
          m1StartShiftBack = 1000 * 60 * 60 * 24 * 10;
          break;
      }

      let m1Start = Math.floor(startTime / 24 / 60 / 60 / 1000) * 24 * 60 * 60 * 1000
      m1Start = m1Start - m1StartShiftBack;

      const toH1 = Math.floor(m1Start / 24 / 60 / 60 / 1000) * 24 * 60 * 60 * 1000
      let fromH1 = toH1 - 1000 * 60 * 60 * 24 * 40 * 2; // 960 1h candles is ~ 40 days (1 batch load)

      if (symbolInfo.onboardDate > fromH1) {
        fromH1 = symbolInfo.onboardDate;
      }

      let from = toH1 //startTime - 1000 * 60 * 60 * 24 * 7; // 10k min is ~ 1 week (10080)

      if (symbolInfo.onboardDate > from) {
        from = symbolInfo.onboardDate;
      }
      const intervalMinutes = intervalToMinutes(tempCandleInterval)
      const endM1 = Math.min(startTime + 1000 * 60 * intervalMinutes * 960, Date.now());
      const to = endM1;
      const interval = '1m';
      const sharedOpts = { from, to, symbol, interval };
      await getCandlesBinance({
        ...sharedOpts,
        setCandlesData
      });

      await getCandlesBinanceH1({
        symbol,
        interval: '1h',
        from: fromH1,
        to: toH1,
        setCandlesH1Data
      });
    }

    if (exchangeInfo) {
      run();
    }
  }, [currentSymbol, tempCandleInterval, exchangeInfo, setCandlesData, setCandlesH1Data]);

  return null;
}


function ClustersLoader() {
  const currentSymbol = useRecoilValue(currentSymbolState);
  const startUnixTime = useRecoilValue(unixTimeState);
  const loadCandles = useRecoilValue(loadCandlesState);
  const clusterCandleInterval = useRecoilValue(clusterCandleIntervalState);
  const setClustersData = useSetRecoilState(clustersState)

  const [prevMinute, setPrevMinute] = useState(null);

  useEffect(() => {
    const run = async () => {
      const startTime = Date.now()

      const intervalMinutes = intervalToMinutes(clusterCandleInterval)
      const totalCandles = Math.floor(loadCandles / intervalMinutes);
      const candlesLeft = Math.floor(totalCandles / 2);
      const candlesRight = Math.ceil(totalCandles / 2);
      // const from = replay.tradeBuffers.usdm[0].T - 1000 * 60 * intervalMinutes * candlesLeft;
      // const to = replay.tradeBuffers.usdm[0].T + 1000 * 60 * intervalMinutes * candlesRight;
      const from = startTime - 1000 * 60 * loadCandles * 1 - 1000 * 60;
      const to = startTime;
      const symbol = currentSymbol;
      const interval = clusterCandleInterval;
      const opts = { from, to, symbol, interval, setClustersData };

      await getClusters(opts);
    }

    run();

    const interval = setInterval(() => {
      let minute = Math.floor(Date.now() / 1000 / 60) * 60 * 1000 - 1000;
      if (prevMinute !== minute) {
        setPrevMinute(minute);
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [currentSymbol, loadCandles, startUnixTime, clusterCandleInterval, setClustersData, prevMinute]);

  return null
}

export default Realtime;
