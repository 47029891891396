import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState } from 'recoil';
import { apiKeyState } from '#state';

const isDev = process.env.NODE_ENV !== 'production'
const apiEnv = process.env.REACT_APP_ENV || 'TEST';
const apiBase = (apiEnv === 'PROD')
  ? 'https://bpcm1.elmeno.dev'
  // : 'https://botpro.elmeno.dev'
  : 'https://bot.elmeno.dev'

console.log('apiEnv', apiEnv, process.env.REACT_APP_ENV, apiEnv);
console.log('apiBase', apiBase, `REACT_APP_BASE_DOMAIN_${apiEnv}`);

const Auth = () => {
  const [key, setKey] = useState('');
  const [apiKey, setApiKey] = useRecoilState(apiKeyState);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${apiBase}?key=${key}`);

      if (response.status === 401) {
        setError('Invalid API key. Please try again.');
      } else {
        setError(null);
        try {
          await localforage.setItem('apiKey', key);
          window.apiKey = key;
          setApiKey(key);
          console.log('setApiKey', apiKey);
        } catch (error) {
          console.error('Failed to save API key:', error);
        }
      }
    } catch (error) {
      setError('Failed to validate API key: ' + error);
    }
  };

  return (
    <>
      <Dialog open={!apiKey}>
        <DialogTitle>Enter your API key</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="apikey"
            label="API Key"
            type="text"
            fullWidth
            onChange={(event) => {
              setKey(event.target.value);
            }}
            value={key}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Auth;
