
import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { Link } from "react-router-dom";
import { Box, List, ListItem, ListItemText, Button } from '@mui/material';

import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { recordsModeState, } from '#state'
import {
  replayState,
  replaysListState,
  replaysLastSeenId,
  replayMetaState,
} from '#state/data'

import { RecordsMode } from './controls/RecordsMode'
import {
  getReplaysList,
} from '../utils';

export function Replays(props) {
  const mode = useRecoilValue(recordsModeState);
  const [replaysList, setReplaysList] = useRecoilState(replaysListState);
  const [lastSeenId, setLastSeenId] = useRecoilState(replaysLastSeenId);
  const [replayMeta, setReplayMeta] = useRecoilState(replayMetaState);
  const replay = useRecoilValue(replayState);
  const [reload, setReload] = useState(Date.now());

  useEffect(() => {
    const run = async () => {
      await getReplaysList({ mode, setReplaysList, setLastSeenId });
    }
    run();
  }, [reload, mode]);

  const loadMoreHandler = async () => {
    await getReplaysList({ mode, setReplaysList, setLastSeenId, lastSeenId });
  }

  return (
    <>
      <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
        <RecordsMode />
        <Button onClick={() => setReload(Date.now())}>Reload</Button>
        {lastSeenId ? <Button onClick={loadMoreHandler}>Load More</Button> : null}
      </Box>

      <List sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
        {replaysList.map((r) => {
          let change = r.change ? (r.change * 100 - 100).toPrecision(3) : null
          return (<ListItem
            button
            onClick={() => setReplayMeta(null)}
            selected={replay && replay._id === r._id}
            key={`rp-${reload}-${r._id}`}
            component={Link}
            to={`/replays/${mode}/${r._id}`}
          >
            <ListItemText primary={`${r.symbol}${change ? ' - ' + change + '%' : ''}`} secondary={new Date(r.time).toLocaleString()} />
          </ListItem>)
        })}
      </List>
    </>
  );
}

export default Replays;
