import React, { useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { stateFilterRealState } from '#state/gui'

const variants = ['all', 'real', 'paper' ];

export function StateFilterReal() {
  const [filter, setFilter] = useRecoilState(stateFilterRealState);

  useEffect(() => {
    localforage.setItem('stateFilterRealState', filter);
  }, [filter]);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Select
      size='small'
      value={filter}
      onChange={handleChange}
    >
      {variants.map((symbol) => (
        <MenuItem value={symbol} key={symbol}>
          {symbol}
        </MenuItem>
      ))}
    </Select>
  );
}