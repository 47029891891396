import React, { useEffect, useCallback, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';

import { updateRateIntervalState } from '#state';
import { miniTickersState, miniTickersUpdateState } from '#state/trades';

const wsBase = 'wss://fstream.binance.com/ws/'
const wsSpotBase = 'wss://stream.binance.com/ws/'

const miniTickersURL = `${wsBase}!miniTicker@arr`

export const BinanceMini = () => {
  const updateRateInterval = useRecoilValue(updateRateIntervalState)

  const [updated, setUpdated] = useState(false)

  const miniTickers = useRecoilValue(miniTickersState);
  const setMiniUpdate = useSetRecoilState(miniTickersUpdateState);

  useWebSocket(miniTickersURL, {
    shouldReconnect: closeEvent => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onOpen: event => {
      console.log('miniTickersURL', 'opened')
    },
    onMessage: event => {
      const msg = JSON.parse(event.data)
      // [
      //   {
      //     "e": "24hrMiniTicker",  // Event type
      //     "E": 123456789,         // Event time
      //     "s": "BTCUSDT",         // Symbol
      //     "c": "0.0025",          // Close price
      //     "o": "0.0010",          // Open price
      //     "h": "0.0025",          // High price
      //     "l": "0.0010",          // Low price
      //     "v": "10000",           // Total traded base asset volume
      //     "q": "18"               // Total traded quote asset volume
      //   }
      // ]
      //console.log('miniTickers', msg)
      for (const t of msg) {
        const key = t.s
        miniTickers.set(key, t)
      }
      setUpdated(true)
    }
  });


  useEffect(() => {
    const interval = setInterval(() => {
      if (updated) {
        setMiniUpdate(p => p + 1)
        setUpdated(false)
      }
    }, updateRateInterval)
    return () => {
      clearInterval(interval)
    }
  }, [updateRateInterval, updated, setMiniUpdate]);


  return (
    <div>
      {/* <button
        onClick={handleClickSendMessage}
        disabled={readyState !== ReadyState.OPEN}
      >
        Click Me to send 'Hello'
      </button> */}
      {/* <div>The WebSocket is currently {connectionStatus}</div> */}
      {/* {lastMessage ? <div>Last message: {lastMessage.data}</div> : null} */}
    </div>
  );
};

export default BinanceMini;
