import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { statsFilterStratState } from '#state/gui'

const factors = ['all', 'breakout', 'bounce' ];

export function StatsFilterStrat() {
  const [statsFilter, setStatsFilter] = useRecoilState(statsFilterStratState);

  useEffect(() => {
    localforage.setItem('statsFilterStratState', statsFilter);
  }, [statsFilter]);

  const handleFactorChange = (event) => {
    setStatsFilter(event.target.value);
  };

  return (
    <Select
      size='small'
      value={statsFilter}
      onChange={handleFactorChange}
    >
      {factors.map((symbol) => (
        <MenuItem value={symbol} key={symbol}>
          {symbol}
        </MenuItem>
      ))}
    </Select>
  );
}