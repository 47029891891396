import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import localforage from 'localforage';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';

import {
  currentSymbolState,
  mainCandleIntervalState,
  tempCandleIntervalState,
} from '#state';

import {
  wsTimerState,
  exchangeInfoState,
  tempCandlesSelector,
  candleUpdatesSelector,
  timeMachineTimeState,
} from '#state/data';

import {
  weightedAvgSelector,
  ema1Selector,
  ema2Selector,
  ma3Selector,
} from '#state/indicators';

import { EMA, ATR } from '@debut/indicators';

import { tradeBuffers, orderBooks } from '#state/local';

import { Box, Tabs, Tab } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { max } from 'd3';
import { convertCandles } from '#src/utils'

// compression
const bookAtrCompressMult = 1
const numLevels = 4;
// show book
const bookShowAtrLimitMult = 4
const showDensityRank = true
const showVolumePercent = true
const showDepthPercent = true
// other
const bookMaxDepth = 10000
// lower bound for volume in USD
const volumeRanking = [0, 25000, 50000, 100000, 200000, 400000, 800000, 1600000, 3200000, 6400000, 12800000, 25600000, 51200000]

export function OrderBookList(props) {
  const { replay, replayData } = props

  const currentSymbol = useRecoilValue(currentSymbolState);
  const timeMachineTime = useRecoilValue(timeMachineTimeState);
  const exchangeInfo = useRecoilValue(exchangeInfoState);
  const symbolInfo = exchangeInfo?.symbols.find(s => s.symbol === currentSymbol)
  // console.log('symbolInfo', symbolInfo)

  const pricePrecision = symbolInfo?.pricePrecision || 6
  const quantityPrecision = symbolInfo?.quantityPrecision || 6

  const tempCandleInterval = useRecoilValue(tempCandleIntervalState);
  const tempCandlesData = useRecoilValue(tempCandlesSelector);
  const candlesUpdates = useRecoilValue(candleUpdatesSelector);

  const minuteCandles2 = tempCandlesData.concat(candlesUpdates)
  const tempCandles = convertCandles(minuteCandles2, 1, tempCandleInterval)
  useRecoilValue(wsTimerState);

  // orderBooks.futures.book.asks = new Map(replay.usdmBook.asks.slice(0, maxDepth));
  // orderBooks.futures.book.bids = new Map(replay.usdmBook.bids.slice(0, maxDepth));

  const selectedTime = replay ? timeMachineTime : Date.now()

  const { ema, emaVol, atr } = getIndicatorValues(tempCandles, selectedTime)
  const atrPercent = atr * 100 / ema
  const emaVolUsdt = ema * emaVol
  let minDensityRank = volumeRanking.length - 1

  for (let i = volumeRanking.length - 1; i > 0; i--) {
    if (emaVolUsdt > volumeRanking[i]) {
      minDensityRank = i
      break
    }
  }
  // console.log('minDensityRank', minDensityRank)

  const compressFactor = calculateCompressionFactor(atr * bookAtrCompressMult, numLevels, pricePrecision)

  let usdmDensities = null, spotDensities = null

  if (replay && replayData) {
    console.log('OrderBookList', replay, replayData)

    const usdmBookUpdated = getBookAtTime(replayData?.usdmBook, replayData?.usdmUpdates, selectedTime)
    const usdmBook = compressOrderBook(usdmBookUpdated, pricePrecision, compressFactor)
    usdmDensities = densityRanking(usdmBook)

    const spotBookUpdated = getBookAtTime(replayData?.spotBook, replayData?.spotUpdates, selectedTime)
    const spotBook = compressOrderBook(spotBookUpdated, pricePrecision, compressFactor)
    spotDensities = densityRanking(spotBook)

  } else if (!replay) {
    const usdmBook = compressOrderBook(orderBooks.futures.book, pricePrecision, compressFactor)
    usdmDensities = densityRanking(usdmBook)

    const spotBook = compressOrderBook(orderBooks.spot.book, pricePrecision, compressFactor)
    spotDensities = densityRanking(spotBook)
  } else {
    return null
  }


  return (
    <Box>
      <Box>
        EMA: {formatNumber(ema, 5)}
        <br />
        EMA Volume: {formatNumber(emaVol)}
        <br />
        EMA Volume USDT: {formatNumber(emaVolUsdt)}
        <br />
        ATR: {formatNumber(atr, 5)}
        <br />
        ATR%: {formatNumber(atrPercent)}%
        <br />
        Compress: {compressFactor}
      </Box>

      <Box sx={{ display: 'flex', gap: '1vw', mt: '1vh' }}>
        <Box>
          Futures
          <OrderBook book={usdmDensities} atr={atr} emaVol={emaVol} quantityPrecision={quantityPrecision} minDensityRank={minDensityRank} />
        </Box>
        <Box>
          Spot
          <OrderBook book={spotDensities} atr={atr} emaVol={emaVol} quantityPrecision={quantityPrecision} minDensityRank={minDensityRank} />
        </Box>
      </Box>
    </Box >
  )
}

function OrderBook(props) {
  const { book, atr, emaVol, quantityPrecision, minDensityRank } = props

  if (!book) return null

  let asks = book?.asks
  if (asks && asks.length) {
    const bestAsk = asks[0]
    const upLimit = bestAsk[0] + atr * bookShowAtrLimitMult
    // console.log('bestAsk', bestAsk, 'upLimit', upLimit)
    asks = asks.filter(([price]) => price <= upLimit)
    asks = asks.reverse()
  }
  const maxAskVolume = asks.reduce((acc, [price, volume]) => Math.max(acc, volume), 0)

  let bids = book?.bids
  if (bids && bids.length) {
    const bestBid = bids[0]
    const downLimit = bestBid[0] - (atr * bookShowAtrLimitMult)
    // console.log('bestBid', bestBid, 'downLimit', downLimit)
    bids = bids.filter(([price]) => price >= downLimit)
  }
  const maxBidVolume = bids.reduce((acc, [price, volume]) => Math.max(acc, volume), 0)

  const maxVolume = Math.max(maxAskVolume, maxBidVolume)
  const volumeMult = maxVolume <= emaVol ? maxVolume / emaVol : emaVol / maxVolume
  const volWidthMult = 100 / emaVol * 0.1 // volumeMult * 0.5

  return (
    <Box>
      {/* ASKS */}
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 300, maxWidth: 700, backgroundColor: 'rgba(255, 0, 0, 0.1)' }} size="small" aria-label="Asks">
          <TableBody>
            {asks.map((row) => {
              const [price, volume, depth, rank, depthRank] = row
              const volumePercent = volume * volWidthMult
              const depthPercent = depth * volWidthMult  / 4

              let opacity = 0.5
              const hasRank = row.length > 0
              let RankCells = null
              if (hasRank) {
                opacity = Math.min(0.2 + (rank / volumeRanking.length), 1)
                RankCells = (<>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '5rem',
                      // fontSize: '0.8rem',
                    }}>
                    {formatNumber(depth)}
                  </TableCell>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '3rem',
                      // fontSize: '0.8rem',
                    }}>
                    {rank}
                  </TableCell>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '3rem',
                      // fontSize: '0.8rem',
                    }}>
                    {depthRank}
                  </TableCell>
                </>)
              }

              return (
                <TableRow
                  key={`a${row[0]}`}
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  {showVolumePercent
                    ? <Box
                      style={{ backgroundColor: `rgba(255, 0, 0, ${opacity})`, width: `${volumePercent}%`, position: 'absolute', left: 0, zIndex: -1, height: '100%' }}
                      // sx={{}}
                    />
                    : null}
                  {showDepthPercent
                    ? <Box
                      style={{ width: `${depthPercent}%`, position: 'absolute', right: 0, zIndex: -1, backgroundColor: `rgba(255, 255, 0, 0.3)`, height: '100%' }}
                      // sx={{}}
                    />
                    : null}
                  <TableCell component="th" scope="row"
                    sx={{
                      padding: '2px 6px',
                      // fontSize: '0.8rem',
                    }}>
                    {price}
                  </TableCell>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '5rem',
                      // fontSize: '0.8rem',
                    }}>
                    {/* {volume.toFixed(quantityPrecision)} */}
                    {formatNumber(volume)}
                  </TableCell>
                  {showDensityRank ? RankCells : null}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* BIDS */}
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 300, maxWidth: 700, backgroundColor: 'rgba(0, 255, 0, 0.1)' }} size="small" aria-label="Bids">
          <TableBody>
            {bids.map((row) => {
              const [price, volume, depth, rank, depthRank] = row
              const volumePercent = volume * volWidthMult
              const depthPercent = depth * volWidthMult / 10

              let opacity = 0.5
              const hasRank = row.length > 2
              let RankCells = null
              if (hasRank) {
                opacity = Math.min(0.2 + (rank / volumeRanking.length), 1)
                RankCells = (<>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '5rem',
                      // fontSize: '0.8rem',
                    }}>
                    {formatNumber(depth, quantityPrecision)}
                  </TableCell>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '3rem',
                      // fontSize: '0.8rem',
                    }}>
                    {rank}
                  </TableCell>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '3rem',
                      // fontSize: '0.8rem',
                    }}>
                    {depthRank}
                  </TableCell>
                </>)
              }

              return (
                <TableRow
                  key={`b${row[0]}`}
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  {showVolumePercent
                    ? <Box
                      style={{ backgroundColor: `rgba(0, 255, 0, ${opacity})`, width: `${volumePercent}%`, position: 'absolute', left: 0, zIndex: -2, height: '100%' }}
                      // sx={{}}
                    />
                    : null}
                  {showDepthPercent
                    ? <Box
                      style={{ width: `${depthPercent}%`, position: 'absolute', right: 0, zIndex: -1, backgroundColor: `rgba(255, 255, 0, 0.3)`, height: '100%' }}
                      // sx={{}}
                    />
                    : null}
                  <TableCell component="th" scope="row"
                    sx={{
                      padding: '2px 6px',
                      // fontSize: '0.8rem',
                    }}>
                    {price}
                  </TableCell>
                  <TableCell align="right"
                    sx={{
                      padding: '2px 6px',
                      width: '5rem',
                      // fontSize: '0.8rem',
                    }}>
                    {/* {volume.toFixed(quantityPrecision)} */}
                    {formatNumber(volume, quantityPrecision)}
                  </TableCell>
                  {showDensityRank ? RankCells : null}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

function getBookAtTime(bookInit, bookUpdates, timeMachineTime) {
  const asksMap = new Map(bookInit?.asks)
  const bidsMap = new Map(bookInit?.bids)

  for (const update of bookUpdates) {
    const updateTime = update.T || update.E
    if (timeMachineTime && updateTime > timeMachineTime) {
      break
    }
    update.a.forEach(([price, quantity]) => {
      const q = parseFloat(quantity);
      const p = parseFloat(price);
      if (q === 0) {
        asksMap.delete(p);
      } else {
        asksMap.set(p, q);
      }
    });

    update.b.forEach(([price, quantity]) => {
      const q = parseFloat(quantity);
      const p = parseFloat(price);
      if (q === 0) {
        bidsMap.delete(p);
      } else {
        bidsMap.set(p, q);
      }
    });
  }

  const bookUpdated = {
    asks: Array.from(asksMap, ([key, value]) => [key, value]),
    bids: Array.from(bidsMap, ([key, value]) => [key, value]),
  }
  bookUpdated.asks.sort((a, b) => a[0] - b[0])
  bookUpdated.bids.sort((a, b) => b[0] - a[0])

  return bookUpdated
}

function calculateCompressionFactor(atrValue, targetLevels, pricePrecision) {
  const minChange = Math.pow(10, -pricePrecision);
  const levelsInRange = atrValue * Math.pow(10, pricePrecision)
  const idealCompression = minChange / (targetLevels / levelsInRange)
  // console.log('idealCompression', idealCompression)

  const roundMults0 = [1, 2, 5];
  const roundMults1 = [1, 2, 2.5, 5];
  let enable25 = false;
  let currentMultiplier = minChange;
  let compression = minChange;
  while (currentMultiplier <= idealCompression) {
    const mults = enable25 ? roundMults1 : roundMults0;
    for (const mult of mults) {
      const c = currentMultiplier * mult;
      if (c <= idealCompression) {
        compression = c;
      } else {
        break;
      }
    }
    currentMultiplier *= 10;
    enable25 = true;
  }

  // console.log('compression', compression)
  return compression
}

function compressOrderBook(orderBook, pricePrecision, step) {
  const n = 1 / step

  const asks = Array.from(orderBook.asks, ([key, value]) => [key, value])
  const bids = Array.from(orderBook.bids, ([key, value]) => [key, value])
  // console.log('compressOrderBook', asks.length, bids.length)

  function compressor(array, roundDirection) {
    const result = {};
    for (let i = 0; i < array.length; i++) {
      let [price, volume] = array[i];
      let compressedPrice;
      if (roundDirection === 'up') {
        compressedPrice = Math.ceil(price * n) / n;
      } else {
        compressedPrice = Math.floor(price * n) / n;
      }
      compressedPrice = compressedPrice.toFixed(pricePrecision);
      if (!result.hasOwnProperty(compressedPrice)) {
        result[compressedPrice] = 0;
      }
      result[compressedPrice] += volume;
    }
    return Object.entries(result).map(([price, volume]) => [parseFloat(price), volume]);
  }

  return {
    asks: compressor(asks, 'up'),
    bids: compressor(bids, 'down')
  };
}

function densityRanking(book) {
  const askDensities = getRanks(book.asks, 'asks')
  const bidDensities = getRanks(book.bids, 'bids')
  const asks = askDensities//.filter(([price, rank]) => rank > 0)
  // asks.sort((a, b) => a[0] - b[0])
  const bids = bidDensities //.filter(([price, rank]) => rank > 0)
  // bids.sort((a, b) => b[0] - a[0])
  return {
    asks,
    bids
  }
}

function getRanks(book, side) {
  const levels = Array.from(book, ([key, value]) => [key, value])
  if (side === 'asks') {
    levels.sort((a, b) => a[0] - b[0])
  } else {
    levels.sort((a, b) => b[0] - a[0])
  }


  const densities = []
  let depth = 0, depthUSD = 0
  for (const level of levels) {
    const [price, quantity] = level
    const qUSD = price * quantity
    depth += quantity
    depthUSD += qUSD

    let rank = 0
    for (let i = 0; i < volumeRanking.length; i++) {
      const v = volumeRanking[i]
      if (qUSD >= v) {
        rank = i
      } else {
        break
      }
    }

    let depthRank = 0
    for (let i = 0; i < volumeRanking.length; i++) {
      const v = volumeRanking[i]
      if (depthUSD >= v) {
        depthRank = i
      } else {
        break
      }
    }
    densities.push([price, quantity, depth, rank, depthRank])
  }

  return densities
}

function getIndicatorValues(candlesData, timeMachineTime) {
  const EMA1 = new EMA(14);
  const EMAVol = new EMA(14);
  const ATR1 = new ATR(14);

  let currentEma = 0
  let currentAtr = 0
  let currentEmaVol = 0

  for (const c of candlesData) {
    if (timeMachineTime && c.time > timeMachineTime) {
      break
    }
    currentEma = EMA1.nextValue(c.close);
    currentEmaVol = EMAVol.nextValue(c.volume);
    currentAtr = ATR1.nextValue(c.high, c.low, c.close)

  }

  return {
    ema: currentEma,
    emaVol: currentEmaVol,
    atr: currentAtr
  }
}

function cloneMap(original, maxDepth = bookMaxDepth) {
  const arr = Array.from(original, ([key, value]) => [key, value])
  const limited = arr.slice(0, maxDepth)
  return new Map(limited);
}

function formatNumber(num, significantDigits = 3) {
  // Включаем массив суффиксов внутрь функции для полной инкапсуляции
  const suffixes = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
    1000000000000: 'T'
  };

  const absNum = Math.abs(num);
  const sigDelta = Math.max(0, significantDigits - 3)

  if (absNum >= 1000) {
    let scale = 1;
    let suffix = '';
    let scaledNum = num;

    // Определяем нужный делитель и суффикс
    while (scaledNum >= 1000 && scale <= 1e12) {
      scale *= 1000;
      scaledNum = num / scale;
      suffix = suffixes[scale] || '';
    }

    // Округляем число в зависимости от величины
    if (scaledNum >= 100) {
      return (scaledNum.toFixed(sigDelta) + suffix);
    } else if (scaledNum >= 10) {
      return (scaledNum.toFixed(sigDelta + 1) + suffix);
    } else {
      return (scaledNum.toFixed(sigDelta + 2) + suffix);
    }
  } else if (absNum < 1) {
    // Используем toPrecision для чисел меньше 1
    return num.toPrecision(significantDigits);
  } else {

    // Числа от 1 до 999, применяем логику похожую на большие числа, но без суффикса
    if (num >= 100) {
      return num.toFixed(sigDelta);
    } else if (num >= 10) {
      return num.toFixed(sigDelta + 1);
    } else {
      return num.toFixed(sigDelta + 2);
    }
  }
}

// Примеры использования
// console.log(formatNumber(123456789));  // Ожидаем "123M"
// console.log(formatNumber(0.000123456)); // Ожидаем "0.000123"
// console.log(formatNumber(988.123));        // Ожидаем "988"
// console.log(formatNumber(22.628));       // Ожидаем "22.6"
// console.log(formatNumber(22645));      // Ожидаем "22.6K"


export default OrderBookList