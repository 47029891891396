import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { statsFilterEventState } from '#state/gui'

const factors = ['close', 'all'];

export function StatsFilterEvent() {
  const [statsFilter, setStatsFilter] = useRecoilState(statsFilterEventState);

  useEffect(() => {
    localforage.setItem('statsFilterEventState', statsFilter);
  }, [statsFilter]);

  const handleFactorChange = (event) => {
    setStatsFilter(event.target.value);
  };

  return (
    <>
      <Select
        size='small'
        value={statsFilter}
        onChange={handleFactorChange}
        
        sx={{display: { xs: 'none', lg: 'flex' }}}
      >
        {factors.map((symbol) => (
          <MenuItem value={symbol} key={symbol}>
            {symbol}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}