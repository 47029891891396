import React, { useEffect, useRef, useState } from 'react';
import * as d3 from "d3";
import * as fc from "d3fc";
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';

import {
  currentSymbolState,
  bookShowLevelsState,
} from '#state';
import {
  candlesState,
  candlesSelector,
  clustersState,
  clustersSelector,
  bookState,
  bookSelector,
  bookUpdatesState,
  bookUpdatesSelector,
  resampledUpdatesCache,
} from '#state/data';

const candleLength = 60 * 1000 - 1

const D3FC = ({ data, scale, keys }) => {
  const myRef = useRef();

  const { minX, maxX, minY, maxY } = scale
  console.log('scale', scale)

  const x = d3.scaleLinear()
    .domain([minX - 1.1, maxX + 1])//.domain([minX, maxX]); //.domain([0, 60])
  const y = d3.scaleLinear()
    .domain([minY * 0.9996, maxY * 1.001])//.domain([minY, maxY]) //.domain([-100, 300]) //

  let extSize = fc
    .extentLinear()
    .accessors([d => d.size])(data)

  let alpha = d3
    .scaleLinear()
    .range([0, 1])
    .domain(extSize);

  let fillColor = fc
    .webglFillColor()
    .value(d => {
      const a = alpha(d.size)
      if (d.bestBid) return [1, 0, 0, 1]
      if (d.bestAsk) return [0, 1, 0, 1]
      if (d.sf) return getColor(alpha(d.sf))
      return getColor(a)
    })
    .data(data);

  let size = d3
    .scaleLinear()
    .range([10, 50])
    .domain(extSize);

  const webglSeries = fc
    .seriesWebglPoint()
    .crossValue(d => d.x)
    .mainValue(d => d.y)
    // .type(d3.symbolSquare)
    .size(d => size(d.size))
    .decorate(program => {
      // Set the color of the points.
      fillColor(program);
      // Enable blending of transparent colors.
      const context = program.context();
      if (context) {
        context.enable(context.BLEND);
        context.blendFunc(context.SRC_ALPHA, context.ONE_MINUS_SRC_ALPHA);
      }
    });

  const gridline = fc.annotationCanvasGridline();

  const dataContainer = {
    c1: data,
  }

  let multiSeries = fc
    .seriesWebglMulti()
    .series([webglSeries,])
    .mapping((data, index, series) => {
      switch (series[index]) {
        case webglSeries:
          return data.c1;
        default:
          return null
      }
    });

  let zoom = fc.zoom().on('zoom', () => {
    render()
  });

  let chart = fc
    .chartCartesian({
      xScale: x,
      yScale: y,
      xAxisLabel: 'Time',
      yAxisLabel: 'Price',
      chartLabel: 'Order Book',
      // xAxis: {
      //   // bottom axis uses one of the two adapters
      //   bottom: scale => adapter(fc.axisOrdinalBottom(scale))
      // },
    })
    .webglPlotArea(multiSeries)
    .canvasPlotArea(gridline)
    .decorate(sel => {
      sel.enter().call(zoom, x, y);
    })


  function render() {
    d3.select(`#d3fcb-${keys}`)
      .datum(dataContainer)
      .call(chart);
  }

  useEffect(() => {
    reinitChart({ x, y, multiSeries, render })
  }, [data, keys]);

  return <div
    key={`d3fcb-${keys}`}
    id={`d3fcb-${keys}`}
    className='d3fc'
    ref={myRef}
    width={500}
    height={300}
  />;
};

function reinitChart(props) {
  let { x, y, multiSeries, render } = props
  const zoom = fc.zoom().on('zoom', render);
  const gridline = fc.annotationCanvasGridline();

  fc.chartCartesian(x, y)
    .webglPlotArea(multiSeries)
    .canvasPlotArea(gridline)
    .decorate(sel => {
      sel.enter().call(zoom, x, y);
    })

  render();
}



const StreamingChart = () => {
  let data = []

  const orderBook = useRecoilValue(bookSelector);
  const orderBookUpdatesLoadable = useRecoilValueLoadable(bookUpdatesSelector);
  let orderBookUpdates = resampledUpdatesCache
  switch (orderBookUpdatesLoadable.state) {
    case 'hasValue':
      orderBookUpdates = orderBookUpdatesLoadable.contents
      break
    case 'loading':
      orderBookUpdates = resampledUpdatesCache
      break
    case 'hasError':
      orderBookUpdates = orderBookUpdatesLoadable.contents;
      break
    default:
      break
  }

  const currentLevels = useRecoilValue(bookShowLevelsState);
  const [rerender, setRerender] = useState(0)

  useEffect(() => {
    setRerender(rerender + 1)
  }, [
    orderBook,
    orderBookUpdates,
    currentLevels
  ])

  // const prng = d3.randomNormal(0.5, 0.5);

  let minTime = Infinity;
  let maxTime = -Infinity;
  let minPrice = Infinity;
  let maxPrice = -Infinity;
  let minVolume = Infinity;
  let bestAsk = -Infinity;
  let bestBid = Infinity;
  let startTime
  let minBestAsk = Infinity;
  let maxBestBid = -Infinity;

  if (orderBook.length) {
    const asks = orderBook[0].asks
    const bids = orderBook[0].bids
    minTime = orderBook[0].time;
    startTime = orderBook[0].time;
    const asksKeys = Object.keys(asks)
    const bidsKeys = Object.keys(bids)
    if (asksKeys.length) {
      const maxAsk = parseFloat(asksKeys[0]);
      bestAsk = parseFloat(asksKeys[asksKeys.length - 1]);
      maxPrice = maxAsk;
      minBestAsk = bestAsk;
    }
    if (bidsKeys.length) {
      const minBid = parseFloat(bidsKeys[bidsKeys.length - 1]);
      bestBid = parseFloat(bidsKeys[0]);
      minPrice = minBid;
      maxBestBid = bestBid;
    }
  }

  for (const candle of orderBook) {
    let candleBestAsk = candle.bestAsk;
    let candleBestBid = candle.bestBid;
    const candleStartTime = candle.time
    const candleEndTime = candle.time + candleLength
    const asks = candle.asks
    const bids = candle.bids
    const topAsks = Object.keys(asks)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .slice(0, currentLevels)
    const topBids = Object.keys(bids)
      .sort((a, b) => parseFloat(b) - parseFloat(a))
      .slice(0, currentLevels)
    // console.log('candle', candle.time, candleBestAsk, candleBestBid);
    const cba = parseFloat(candleBestAsk)
    if (cba < minBestAsk) {
      minBestAsk = cba
    }
    const cbb = parseFloat(candleBestBid)
    if (cbb > maxBestBid) {
      maxBestBid = cbb
    }

    const t = (candleStartTime - startTime) / 60000;
    if (t < minTime) minTime = t
    if (t > maxTime) maxTime = t

    for (const lvl in asks) {
      if (!topAsks.includes(lvl)) continue
      try {
        const p = parseFloat(lvl);
        const v = parseFloat(candle.asks[lvl]);
        let ignore = false;

        // console.log('candle.asks', p, v, lvl);

        if (v < minVolume) minVolume = v;

        if (p / candleBestAsk > 1.5) {
          console.log('ignore', p, minPrice, maxPrice);
          ignore = true;
        } else {
          if (p < minPrice) minPrice = p;
          else if (p > maxPrice) maxPrice = p;
        }

        const cs = Math.min(1 / v, 1) * (Math.random() - 0.5) * 0.66
        const c = {
          x: t,
          y: p,
          size: v,
        }

        if (lvl === candleBestAsk) {
          c.bestAsk = v
        }

        if (!ignore) data.push(c)
        // console.log('c', c)

      } catch (error) {
        console.log('error', error);
      }

    }
    for (const lvl in bids) {
      if (!topBids.includes(lvl)) continue
      try {
        const p = parseFloat(lvl);
        const v = parseFloat(candle.bids[lvl]);
        let ignore = false;

        if (v < minVolume) minVolume = v;

        if (candleBestBid / p > 1.5) {
          console.log('ignore', p, minPrice, maxPrice);
          ignore = true;
        } else {
          if (p < minPrice) minPrice = p;
          else if (p > maxPrice) maxPrice = p;
        }

        const cs = Math.min(1 / v, 1) * (Math.random() - 0.5) * 0.66
        const c = {
          x: t,
          y: p,
          size: v,
        }
        if (lvl === candleBestBid) {
          c.bestBid = v
        }

        if (!ignore) data.push(c)
        // console.log('c', c)

      } catch (error) {
        console.log('error', error);
      }

    }

    // UPDATES

    let newAsks = JSON.parse(JSON.stringify(asks))
    let newBids = JSON.parse(JSON.stringify(bids))

    for (const update of orderBookUpdates) {
      const time = update.time
      if (time < candleStartTime) {
        // console.log('ignore update', candleStartTime, time);
        continue
      }
      if (time > candleEndTime) {
        // console.log('break update', candleEndTime, time);
        break
      }

      const ut = (time - startTime) / 60000
      if (ut > maxTime) maxTime = ut
      // console.log('orderBookUpdate', time, ut)


      const askUpdates = update.asks
      newAsks = { ...newAsks, ...askUpdates }
      const bidUpdates = update.bids
      newBids = { ...newBids, ...bidUpdates }

      // FIX LEVELS 1
      for (const lvl in askUpdates) {
        const volume = newAsks[lvl]
        const v = parseFloat(volume);
        if (v > 0) {
          // console.log('fix newBids', lvl);
          delete newBids[lvl]
        }
      }

      for (const lvl in bidUpdates) {
        const volume = newBids[lvl]
        const v = parseFloat(volume);
        if (v > 0) {
          // console.log('fix newAsks', lvl);
          delete newAsks[lvl]
        }
      }


      // ****************************************
      // ASKS
      let newBestAsk = Infinity;
      let newBestAskLvl

      for (const lvl in newAsks) {
        const p = parseFloat(lvl);
        const volume = newAsks[lvl]
        const v = parseFloat(volume);
        if (v === 0) {
          delete newAsks[lvl]
        } else if (p < newBestAsk) {
          newBestAsk = p;
          newBestAskLvl = lvl;
        }
      }

      // if (newBestAskLvl !== update.bestAsk) {
      // console.log('bestAsk divergence',  ut, update.time, newBestAskLvl, update.bestAsk, update);
      // newBestAskLvl = update.bestAsk;
      // }

      const topNewAsks = Object.keys(newAsks)
        .sort((a, b) => parseFloat(a) - parseFloat(b))
        .slice(0, currentLevels)
      newBestAskLvl = topNewAsks[0]

      // console.log('topNewAsks', newBestAskLvl, topNewAsks);
      for (const lvl in newAsks) {
        if (!topNewAsks.includes(lvl)) continue
        try {
          const p = parseFloat(lvl);
          const v = parseFloat(newAsks[lvl]);
          let ignore = false;

          // if (p / newBestAsk > 1.33) {
          //   console.log('ignore', p, minPrice, maxPrice);
          //   ignore = true;
          //   continue
          // }

          const cs = Math.min(1 / v, 1) * (Math.random() - 0.5) * 0.66
          const c = {
            x: ut,
            y: p,
            size: v,
          }

          if (lvl === newBestAskLvl) {
            c.bestAsk = v
            // console.log('lvl bestAsk', lvl, v);
          }

          if (!ignore) data.push(c)
          // console.log('c', c)

        } catch (error) {
          console.log('error', error);
        }

      }

      // ****************************************
      // BIDS
      let newBestBid = -Infinity;
      let newBestBidLvl

      for (const lvl in newBids) {
        const p = parseFloat(lvl);
        const volume = newBids[lvl]
        const v = parseFloat(volume);
        if (v === 0) {
          delete newBids[lvl]
        } else if (p > newBestBid) {
          newBestBid = p;
          newBestBidLvl = lvl;
        }
      }
      // if (newBestBidLvl !== update.bestBid) {
      // console.log('bestBid divergence', ut, update.time, newBestBidLvl, update.bestBid, update);
      // newBestBidLvl = update.bestBid;
      // }


      // console.log('newBestBid', newBestBid, newBestBidLvl);
      const topNewBids = Object.keys(newBids)
        .sort((a, b) => parseFloat(b) - parseFloat(a))
        .slice(0, currentLevels)

      newBestBidLvl = topNewBids[0];
      // console.log('topNewBids', newBestBidLvl, topNewBids);

      for (const lvl in newBids) {
        if (!topNewBids.includes(lvl)) continue
        try {
          const p = parseFloat(lvl);
          const v = parseFloat(newBids[lvl]);
          let ignore = false;

          // if (newBestBid / p > 1.33) {
          //   console.log('ignore', p, minPrice, maxPrice);
          //   ignore = true;
          //   continue
          // }

          const cs = Math.min(1 / v, 1) * (Math.random() - 0.5) * 0.66
          const c = {
            x: ut,
            y: p,
            size: v,
          }

          if (lvl === newBestBidLvl) {
            c.bestBid = v
            // console.log('lvl bestBid', lvl, v);
          }

          if (!ignore) data.push(c)
          // console.log('c', c)

        } catch (error) {
          console.log('error', error);
        }

      }

    }
  }

  // console.log('data', data);

  const scale = {
    minX: minTime,
    maxX: maxTime,
    minY: minBestAsk / (1 + 0.00005 * currentLevels),
    maxY: maxBestBid * (1 + 0.00005 * currentLevels),
  }

  if (!data.length) return null

  return <D3FC
    keys={`rb-${rerender}`}
    data={data}
    scale={scale}
  />;
};


function getColor(a) {
  const minAlpha = 0.1
  const alpha = Math.max(minAlpha, a)
  if (a > 0.8)
    return [alpha * 0.7, alpha * 0.1, alpha * 0.99, alpha]
  else if (a <= 0.8 && a > 0.6)
    return [alpha * 0.8, alpha * 0.1, alpha * 0.1, alpha]
  else if (a <= 0.6 && a > 0.3)
    return [alpha * 0.01, alpha * 0.5, alpha * 0.01, alpha]
  else if (a <= 0.3 && a > 0.05)
    return [alpha * 0.3, alpha * 0.5, alpha * 0.8, alpha]
  else if (a <= 0.05)
    return [alpha * 0.1, alpha * 0.3, alpha * 0.9, alpha]
}

export default StreamingChart
