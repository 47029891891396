import React, { useEffect } from 'react';
import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState } from 'recoil';
import {
  recordsModeState,
} from '#state';

const RecordsMode = () => {
  const [mode, setMode] = useRecoilState(recordsModeState);

  useEffect(() => {
    localforage.setItem('recordsModeState', mode);
  }, [mode]);

  const handleChange = (event) => {
    setMode(event.target.value);
  };

  return (
    <>
      <FormControl>
        <InputLabel id="recordsMode">Mode</InputLabel>
        <Select
          labelId="recordsMode"
          id="recordsMode-select"
          size='small'
          sx={{ minWidth: '4rem' }}
          value={mode}
          onChange={handleChange}
        >
          <MenuItem
            value={'move'}
            key={'move'}
          >
            Movements
          </MenuItem>
          <MenuItem
            value={'break'}
            key={'break'}
          >
            Level Breaks
          </MenuItem>
          <MenuItem
            value={'bounce'}
            key={'bounce'}
          >
            Level Bounce
          </MenuItem>
        </Select>
      </FormControl>
    </>);
};

export { RecordsMode }
