import React, { useEffect, useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import localforage from 'localforage';
import * as d3 from "d3";
import * as fc from "d3fc";
import { intervalToMinutes } from '#src/utils'
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  clusterChartHeightState,
  clusterChartWidthState,
} from '#state/gui';
import {
  loadCandlesState,
  clusterCandleIntervalState,
  currentSymbolState,
  levelsShowedState,
  enableZoomState,
} from '#state';
import {
  candlesState,
  candlesSelector,
  clustersState,
  clustersSelector,
  clustersAllSelector,
  wsTimerState,
} from '#state/data';

import { clustersStore } from '#state/local';

const D3FC = ({ bars, barsVolumes, globalVolumes, timePoints, scale, keys, width, height, }) => {
  const loadCandles = useRecoilValue(loadCandlesState);
  const candleInterval = useRecoilValue(clusterCandleIntervalState);
  const intervalMinutes = intervalToMinutes(candleInterval)
  const zoomEnabled = useRecoilValue(enableZoomState);

  const myRef = useRef();

  const { minX, maxX, minY, maxY } = scale
  // console.log('scale', scale)

  const x = d3.scaleLinear()
    .domain([-2 - 0.1, loadCandles / intervalMinutes + intervalMinutes])//.domain([minX, maxX]); //.domain([0, 60])
  const y = d3.scaleLinear()
    .domain([minY * 0.9999, maxY * 1.0001])//.domain([minY, maxY]) //.domain([-100, 300]) //

  const gridline = fc.annotationCanvasGridline()
  gridline.xDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });
  gridline.yDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });

  const clustersSeries = fc
    .seriesCanvasBar()
    .bandwidth((d, i) => {
      return 1
    })
    .orient('horizontal')
    .crossValue(d => d.p)
    .mainValue(d => d.m)
    .baseValue(d => d.b)
    .decorate((ctx, d, index) => {
      if (d.dv !== undefined) {
        if (d.dv >= 0) {
          ctx.fillStyle = `rgba(0, 255, 0, ${1})`;
        } else {
          ctx.fillStyle = `rgba(255, 0, 0, ${1})`;
        }
      } else {
        // ctx.fillStyle = `rgba(${255 * d.v}, 255, 0, ${1})`;
        ctx.fillStyle = `rgba(255, 255, 255, ${d.v})`;
      }
    });

  const globalVolumesSeries = fc
    .autoBandwidth(fc.seriesCanvasBar())
    .orient('horizontal')
    .crossValue(d => d.p)
    .mainValue(d => d.m)
    .baseValue(d => d.b)
    .decorate((ctx, d, index) => {
      if (d.dv !== undefined) {
        if (d.dv >= 0) {
          ctx.fillStyle = `rgba(0, 255, 0, ${1})`;
        } else {
          ctx.fillStyle = `rgba(255, 0, 0, ${1})`;
        }
      } else {
        // ctx.fillStyle = `rgba(${255 * d.v}, 255, 0, ${1})`;
        ctx.fillStyle = `rgba(255, 255, 255, ${d.v})`;
      }
    });

  const candleVolumesSeries = fc
    .seriesCanvasBar()
    .bandwidth((d, i) => {
      return 60 * (intervalMinutes / loadCandles)
    })
    .orient('vertical')
    .crossValue(d => d.x)
    .mainValue(d => d.m)
    .baseValue(d => d.b)
    .decorate((ctx, d, index) => {
      // const a = Math.min(d.v * barsVolumes.length, 1)
      // console.log('alpha', a)
      if (d.back) { // background
        ctx.fillStyle = `rgba(255, 255, 255, ${0.3})`;
      } else { // foreground
        if (d.delta !== undefined) {
          if (d.delta >= 0) {
            ctx.fillStyle = `rgba(0, 255, 0, ${1})`;
          } else {
            ctx.fillStyle = `rgba(255, 0, 0, ${1})`;
          }
        } else {
          ctx.fillStyle = `rgba(255, 255, 255, ${1})`;
        }
      }
    });



  const timePointsSeries = fc
    .seriesCanvasBar()
    .bandwidth((d, i) => {
      return 3
    })
    .orient('vertical')
    .crossValue(d => d.x)
    .mainValue(d => d.m)
    .baseValue(d => d.b)
    .decorate((ctx, d, index) => {
      if (d.back === 'openShort' || d.back === 'closeLong') {
        ctx.fillStyle = `rgba(255, 100, 100, ${0.5})`;
      } else if (d.back === 'openLong' || d.back === 'closeShort') {
        ctx.fillStyle = `rgba(100, 255, 100, ${0.5})`;
      } else if (d.back === 'start' || d.back === 'end') {
        ctx.fillStyle = `rgba(255, 255, 255, ${0.3})`;
      } else {
        ctx.fillStyle = `rgba(100, 100, 255, ${0.5})`;
      }
    });

  let barSeries = fc
    .seriesCanvasMulti()
    .series([gridline, timePointsSeries, globalVolumesSeries, candleVolumesSeries, clustersSeries])
    .mapping((data, index, series) => {
      switch (series[index]) {
        case clustersSeries:
          return data.bars;
        case candleVolumesSeries:
          return data.barsVolumes;
        case globalVolumesSeries:
          return data.globalVolumes;
        case timePointsSeries:
          return data.timePoints;
        case gridline:
          return data.c1;
        default:
          return null
      }
    });

  const dataContainer = {
    bars,
    barsVolumes,
    globalVolumes,
    timePoints,
  }

  let chart = fc
    .chartCartesian(x, y)
    .canvasPlotArea(barSeries)


  if (zoomEnabled) {
    const zoom = fc.zoom().on('zoom', render);
    chart.decorate(sel => {
      sel.enter().call(zoom, x, y);
    })
  }


  function render() {
    d3.select(`#d3fcc-${keys}`)
      .datum(dataContainer)
      .call(chart);
  }

  useEffect(() => {
    reinitChart({ x, y, barSeries, render, zoomEnabled })
  }, [bars, barsVolumes, globalVolumes, timePoints, zoomEnabled]); // keys

  return <div
    key={`d3fcc-${keys}`}
    id={`d3fcc-${keys}`}
    className='d3fc'
    ref={myRef}
    style={{
      width: '100%',
      height,
    }}
  />;
};

function reinitChart(props) {
  let { x, y, barSeries, render, zoomEnabled } = props

  const gridline = fc.annotationCanvasGridline()
  gridline.xDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });
  gridline.yDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });

  const chart = fc.chartCartesian(x, y)
    .canvasPlotArea(gridline)
    .canvasPlotArea(barSeries)

  if (zoomEnabled) {
    const zoom = fc.zoom().on('zoom', render);
    chart.decorate(sel => {
      sel.enter().call(zoom, x, y);
    })
  }

  render();
}


export const ClustersChart = ({ replay, replayData, initialData, next }) => {
  // resize state
  const [resizing, setResizing] = useState(false);
  const [width, setWidth] = useRecoilState(clusterChartWidthState);
  const [height, setHeight] = useRecoilState(clusterChartHeightState);

  const candleInterval = useRecoilValue(clusterCandleIntervalState);
  const clustersS = useRecoilValue(clustersSelector);
  const clustersAll = useRecoilValue(clustersAllSelector);
  const currentLevels = useRecoilValue(levelsShowedState);
  // const clustersClone = JSON.parse(JSON.stringify(clusters));
  // console.log('clustersAll', clustersAll);
  const intervalMinutes = intervalToMinutes(candleInterval)
  // const [data, setData] = useState([]);
  const wsTimer = useRecoilValue(wsTimerState);
  const zoomEnabled = useRecoilValue(enableZoomState);
  const [rerender, setRerender] = useState(0)

  useEffect(() => {
    setRerender(rerender + 1)
  }, [width, height, clustersS, clustersAll, currentLevels, candleInterval, zoomEnabled])

  let clusters
  if (clustersS && clustersS.length && intervalMinutes > 1) {
    const lastLoadedCandleTime = clustersS[clustersS.length - 1].time
    const newCandles = clustersStore.futures.buffer.filter(c => c.time > lastLoadedCandleTime)
    const lastCandleEndTime = lastLoadedCandleTime + intervalMinutes * 60 * 1000
    const now = Date.now()
    if (now > lastCandleEndTime) {
      // const useCandles = Math.floor((lastCandleEndTime - now) / (intervalMinutes * 60 * 1000))
      // console.log('lastCandleEndTime', lastCandleEndTime - now, useCandles);
    }

    const useCandles = intervalMinutes - 1
    const lastCandles = newCandles.slice(-useCandles)
    const currentCluster = clustersStore.futures.currentCluster
    lastCandles.push(currentCluster)
    const resampled = resampleClusters(lastCandles, intervalMinutes)
    clusters = clustersS.concat(resampled)
  } else {
    const currentCluster = clustersStore.futures.currentCluster
    clusters = clustersS.concat([currentCluster])
  }
  // 
  let bars = []
  let barsVolumes = []
  let globalVolumes = []

  let minTime = Infinity;
  let maxTime = -Infinity;
  let minPrice = Infinity;
  let maxPrice = -Infinity;
  let minVolume = Infinity;
  let startTime

  if (clusters.length) {
    if (clusters[0].trades) {
      const trades = Object.keys(clusters[0].trades)
      if (trades.length) {
        const p = parseFloat(trades[0]);
        minPrice = p;
        maxPrice = p;
        minTime = clusters[0].time;
        startTime = clusters[0].time;
      }
    } else {
      const volumePrices = Object.keys(clusters[0].summV)
      if (volumePrices.length) {
        const p = parseFloat(volumePrices[0]);
        minPrice = p;
        maxPrice = p;
        minTime = clusters[0].time;
        startTime = clusters[0].time;
      }
    }
  }

  let globalVolume = 0
  const candlesVolumes = []
  const candlesDeltas = []
  const candlesMins = []
  const candlesMaxs = []

  for (const candle of clusters) {
    const t = Math.round((candle.time - startTime) / 60000);
    // shift time +- 1
    let maxVolume = 0;
    let minPrice = Infinity;
    let maxPrice = -Infinity;

    const volumes = []
    for (const lvl in candle.summV) {
      const v = candle.summV[lvl];
      if (v > maxVolume) maxVolume = v;
      volumes.push(v)
    }
    globalVolume += candle.totalV
    candlesVolumes.push(candle.totalV)
    candlesDeltas.push(candle.totalDV)

    const perc = percentile(volumes, 5)

    if (t < minTime) minTime = t
    else if (t > maxTime) maxTime = t

    for (const lvl in candle.summV) {
      const v = candle.summV[lvl];
      if (v < minVolume) minVolume = v;

      const p = parseFloat(lvl);

      if (v < perc) continue // ignore small volumes

      if (p < minPrice) minPrice = p;
      if (p > maxPrice) maxPrice = p;

      const dv = candle.deltaV[lvl]

      const m = t + v / maxVolume * intervalMinutes * 0.9
      const mDelta = t + (v / maxVolume) * Math.abs(dv) / v * intervalMinutes * 0.9

      const s = v / candle.totalV
      const a = Math.max(0.3, s)
      if (m >= mDelta) {
        bars.push({ b: t, m, p, a, v: v / maxVolume })
        bars.push({ b: t, m: mDelta, p, a, v: v / maxVolume, dv })
      } else {
        bars.push({ b: t, m: mDelta, p, a, v: v / maxVolume, dv })
        bars.push({ b: t, m, p, a, v: v / maxVolume })
      }
    }

    candlesMins.push(minPrice)
    candlesMaxs.push(maxPrice)
  }

  let candleMaxVolume = Math.max(...candlesVolumes)
  for (const candle of clusters) {
    const t = Math.round((candle.time - startTime) / 60000);
    const volume = candlesVolumes.shift()
    const delta = candlesDeltas.shift()
    const min = candlesMins.shift()
    const max = candlesMaxs.shift()

    // background bar full price range
    barsVolumes.push({
      x: t - 0.01,
      b: min,
      m: max,
      v: volume / globalVolume,
      back: true
    })
    // foreground bar min to relative candle volume
    const volumeMain = min + (max - min) * volume / candleMaxVolume
    const vBar = {
      x: t - 0.01,
      b: min,
      m: volumeMain,
      v: volume / globalVolume
    }
    // foreground bar delta
    const deltaMain = min + (max - min) * Math.abs(delta) / volume
    const dBar = {
      x: t - 0.01,
      b: min,
      m: deltaMain,
      v: delta / volume,
      delta
    }

    if (volumeMain >= deltaMain) {
      barsVolumes.push(vBar)
      barsVolumes.push(dBar)
    } else {
      barsVolumes.push(dBar)
      barsVolumes.push(vBar)
    }
  }

  const scale = {
    minX: minTime,
    maxX: maxTime,
    minY: minPrice,
    maxY: maxPrice,
  }


  let fullVolume = 0;
  let maxVolume = 0;

  const caVolumes = []
  for (const lvl in clustersAll.summV) {
    const v = clustersAll.summV[lvl];
    fullVolume += v;
    if (v > maxVolume) maxVolume = v;
    caVolumes.push(v)
  }
  const perc = percentile(caVolumes, 1)
  let newMin = Infinity
  let newMax = -Infinity

  for (const lvl in clustersAll.summV) {
    const v = clustersAll.summV[lvl];
    const dv = clustersAll.deltaV[lvl]
    const p = parseFloat(lvl);

    const b = -intervalMinutes * 2
    const s = v / fullVolume
    const a = Math.max(0.2, s)
    const m = b + v / maxVolume * intervalMinutes * 2 * 0.9
    const mDelta = b + (v / maxVolume) * Math.abs(dv) / v * intervalMinutes * 2 * 0.9
    if (Number.isNaN(mDelta)) console.log('mDelta', mDelta, v, maxVolume, dv, v / maxVolume, Math.abs(dv) / v, intervalMinutes);

    if (m >= mDelta) {
      globalVolumes.push({ b, m, p, a, v: v / maxVolume })
      globalVolumes.push({ b, m: mDelta, p, a, v: v / maxVolume, dv })
    } else {
      globalVolumes.push({ b, m: mDelta, p, a, v: v / maxVolume, dv })
      globalVolumes.push({ b, m, p, a, v: v / maxVolume })
    }

    if (v < perc) continue // ignore small volumes
    if (p < newMin) newMin = p
    else if (p > newMax) newMax = p
  }



  // console.log('clustersAll', clustersAll);
  // console.log('bars', bars);
  // console.log('barsVolumes', barsVolumes);
  // console.log('globalVolumes', globalVolumes);

  // const allkeys = Object.keys(clustersAll.summV)

  // allkeys.forEach((key) => {
  //   const kp = parseFloat(key)
  //   if (kp < newMin) newMin = kp
  //   else if (kp > newMax) newMax = kp
  //   // console.log('kp', kp, newMin, newMax);
  // })
  scale.minY = newMin
  scale.maxY = newMax

  const timePoints = []
  if (replay && replayData) {
    const shiftLeft = 0.08

    if (replayData?.event?.history?.length) {
      for (const ev of replayData.event.history) {
        const eventTime = ev.time
        if (eventTime) {
          const t = (eventTime - startTime) / 60000;
          timePoints.push({
            x: t - shiftLeft,
            b: newMin,
            m: newMax,
            v: 1,
            back: ev.type || 'replay'
          })
        }
      }
    } else {
      const rt = replayData?.event?.now || null// || replayData?.time
      if (rt) {
        const pointTime = rt
        const t = (pointTime - startTime) / 60000;
        timePoints.push({
          x: t - shiftLeft,
          b: newMin,
          m: newMax,
          v: 1,
          back: 'replay'
        })
      }
    }

    const buffer = replayData?.tradeBuffers?.usdm
    if (buffer.length > 1) {
      const firstTradeTime = buffer[0].T
      const firstTradeClusterTime = (firstTradeTime - startTime) / 60000;
      timePoints.push({
        x: firstTradeClusterTime - shiftLeft,
        b: newMin,
        m: newMax,
        v: 1,
        back: 'start'
      })

      const lastTradeTime = buffer[buffer.length - 1].T
      const lastTradeClusterTime = (lastTradeTime - startTime) / 60000;
      timePoints.push({
        x: lastTradeClusterTime - shiftLeft,
        b: newMin,
        m: newMax,
        v: 1,
        back: 'end'
      })
    }

  }

  // console.log('scale', scale);

  if (!bars.length) return null

  return <Resizable
    minHeight={200}
    minWidth={100}
    onResizeStop={(e, direction, ref, d) => {
      const newWidth = width + d.width
      const newHeight = height + d.height
      // setWidth(newWidth);
      setHeight(newHeight);
      setResizing(false);
      // localforage.setItem('clusterChartWidthState', newWidth);
      localforage.setItem('clusterChartHeightState', newHeight);
    }}
    onResizeStart={(e, direction, ref, d) => {
      setResizing(true);
    }}
    style={{
      boxShadow: '1px 1px 2px 1px rgba(0,0,0,0.5)',
      backgroundColor: resizing ? 'black' : 'transparent',
    }}
  >
    <D3FC
      keys={`r-${rerender}`}
      className='d3fc'
      resizing={resizing}
      width={width}
      height={height}
      scale={scale}
      // data
      bars={bars}
      barsVolumes={barsVolumes}
      globalVolumes={globalVolumes}
      timePoints={timePoints}
    />
  </Resizable>;
};



export default ClustersChart


function percentile(values, p) {
  values.sort((a, b) => a - b);
  const index = (p / 100) * (values.length - 1);

  if (Math.floor(index) === index) {
    return values[index];
  } else {
    const i = Math.floor(index);
    return values[i] + (values[i + 1] - values[i]) * (index - i);
  }
}

function resampleClusters(lastCandles, aggregationFactor) {
  const aggregatedData = [];
  let i = 0;
  while (i < lastCandles.length) {
    let candle = lastCandles[i]
    let { time } = candle

    let agg = {
      time,
      totalV: 0, totalT: 0,
      totalDV: 0, totalDT: 0,
      summV: {}, summT: {},
      buyV: {}, buyT: {},
      sellV: {}, sellT: {},
      deltaV: {}, deltaT: {}
    };

    for (let j = 0; j < aggregationFactor && i + j < lastCandles.length; j++) {
      candle = lastCandles[i + j]

      agg.totalV += candle.totalV
      agg.totalT += candle.totalT
      agg.totalDV += candle.totalDV
      agg.totalDT += candle.totalDT

      for (let lvl in candle.summV) {
        agg.summV[lvl] = agg.summV[lvl] ? agg.summV[lvl] + candle.summV[lvl] : candle.summV[lvl]
        agg.summT[lvl] = agg.summT[lvl] ? agg.summT[lvl] + candle.summT[lvl] : candle.summT[lvl]
        agg.deltaV[lvl] = agg.deltaV[lvl] ? agg.deltaV[lvl] + candle.deltaV[lvl] : candle.deltaV[lvl]
        agg.deltaT[lvl] = agg.deltaT[lvl] ? agg.deltaT[lvl] + candle.deltaT[lvl] : candle.deltaT[lvl]
      }
      for (let lvl in candle.buyV) {
        agg.buyV[lvl] = agg.buyV[lvl] ? agg.buyV[lvl] + candle.buyV[lvl] : candle.buyV[lvl]
        agg.buyT[lvl] = agg.buyT[lvl] ? agg.buyT[lvl] + candle.buyT[lvl] : candle.buyT[lvl]
      }
      for (let lvl in candle.sellV) {
        agg.sellV[lvl] = agg.sellV[lvl] ? agg.sellV[lvl] + candle.sellV[lvl] : candle.sellV[lvl]
        agg.sellT[lvl] = agg.sellT[lvl] ? agg.sellT[lvl] + candle.sellT[lvl] : candle.sellT[lvl]
      }
    }
    // console.log('candle', i, j, Object.keys(trades).length)
    // console.log('volumes', i, j, Object.keys(volumes).length)

    agg.time = candle.time
    aggregatedData.push(agg);

    i += aggregationFactor;
  }
  return aggregatedData;
}