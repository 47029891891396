import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { loadCandlesState } from '#state';

const levels = [5, 10, 15, 20, 30, 60, 120, 240, 360, 480, 720, 1440];

export function LoadCandles() {
  const [currentLevels, setCurrentLevels] =
    useRecoilState(loadCandlesState);

  useEffect(() => {
    console.log('loadCandlesState', currentLevels);
    localforage.setItem('loadCandles', currentLevels);
  }, [currentLevels]);

  const onChange = (event) => {
    setCurrentLevels(event.target.value);
  };

  return (
    <Select
      size='small'
      value={currentLevels}
      onChange={onChange}
    >
      {levels.map((level) => (
        <MenuItem value={level} key={level}>
          {level}m
        </MenuItem>
      ))}
    </Select>
  );
}