import React, { useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState } from 'recoil';
import { mainCandleIntervalState } from '#state';

export const MainCandleInterval = () => {
  const [candleInterval, setCandleInterval] = useRecoilState(mainCandleIntervalState);

  const handleChange = (event) => {
    setCandleInterval(event.target.value);
  };

  useEffect(() => {
    localforage.setItem('mainCandleIntervalState', candleInterval);
  }, [candleInterval]);

  return (
    <FormControl>
      <InputLabel id="candle-interval-label">Candles</InputLabel>
      <Select
        // disabled
        labelId="candle-interval-label"
        id="candle-interval-select"
        size='small'
        value={candleInterval}
        onChange={handleChange}
      >
        {/* <MenuItem value="1m">1m</MenuItem>
        <MenuItem value="5m">5m</MenuItem>
        <MenuItem value="15m">15m</MenuItem>
        <MenuItem value="30m">30m</MenuItem> */}
        <MenuItem value="1h">1h</MenuItem>
        <MenuItem value="4h">4h</MenuItem>
        <MenuItem value="6h">6h</MenuItem>
        <MenuItem value="12h">12h</MenuItem>
        <MenuItem value="1d">1d</MenuItem>
      </Select>
    </FormControl>
  );
};

export default MainCandleInterval;
