import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, } from 'recoil';
import { stateFilterStratState } from '#state/gui'

const factors = ['all', 'breakout', 'bounce' ];

export function StateFilterStrat() {
  const [stratFilter, setStratFilter] = useRecoilState(stateFilterStratState);

  useEffect(() => {
    localforage.setItem('stateFilterStratState', stratFilter);
  }, [stratFilter]);

  const handleFactorChange = (event) => {
    setStratFilter(event.target.value);
  };

  return (
    <Select
      size='small'
      value={stratFilter}
      onChange={handleFactorChange}
    >
      {factors.map((symbol) => (
        <MenuItem value={symbol} key={symbol}>
          {symbol}
        </MenuItem>
      ))}
    </Select>
  );
}