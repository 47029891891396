
import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, List, ListItem, ListItemText, Button } from '@mui/material';

import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import { showControlsState } from '#state';
import { exchangeInfoState } from '#state/data';
import {
  getWsActions,
  authorizedState,
  tradingStatusState,
  tradesDetailsState,
  tradesUpdateState,
  miniTickersState,
  miniTickersUpdateState,
  levelsUpState,
  levelsDownState,
} from '#state/trades';


export function Screen(props) {
  const miniTickers = useRecoilValue(miniTickersState);
  const miniUpdate = useRecoilValue(miniTickersUpdateState);

  const levelsUp = useRecoilValue(levelsUpState)
  const levelsDown = useRecoilValue(levelsDownState)

  const [items, setItems] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const wsapi = getWsActions()
      if (wsapi) {
        // console.log('request closestLevels')
        wsapi.sendJsonMessage({
          action: 'closestLevels',
          type: 'atr',
          isup: true,
          tf: 'm15',
          limit: 5
        })
        wsapi.sendJsonMessage({
          action: 'closestLevels',
          type: 'atr',
          isup: false,
          tf: 'm15',
          limit: 5
        })
      } else {
        console.log('WSAPI not ready')
      }
    }, 2000)
    return () => clearInterval(interval)
  }, []);

  useEffect(() => {
    const run = async () => {
      // const entries = tradesDetails.entries()
      const items = []

      if (levelsUp) {
        for (const lvl of levelsUp) {
          const { symbol, score } = lvl
          const item = <PositionItem key={`sc-up-${symbol}`} symbol={symbol} score={score} isup={true} />
          items.push(item)
        }
        items.reverse()
      }

      if (levelsDown) {
        for (const lvl of levelsDown) {
          const { symbol, score } = lvl
          const item = <PositionItem key={`sc-dn-${symbol}`} symbol={symbol} score={score} isup={false} />
          items.push(item)
        }
      }

      setItems(items)
    }
    run();
  }, [levelsUp, levelsDown])//, miniTickers, miniUpdate]);

  return (
    <>
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          // width: '100%',
        }}>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          // overflowY: 'auto',
          // flexBasis: '20%',
          // width: '100%'
        }}>
          {items}
        </Box>
      </Box>
    </>
  );
}

export default Screen;


function PositionItem({ symbol, score, isup, ticker }) {
  const exchangeInfo = useRecoilValue(exchangeInfoState);
  const now = Date.now()
  // const timeInPosition = msToHHMMSS(entryAge)
  const currentPrice = parseFloat(ticker?.c || 0)


  let ticksToStop = 0, ticksToHardStop = 0, trailingTicks = 0
  if (exchangeInfo && exchangeInfo?.symbols) {
    const symbolInfo = exchangeInfo.symbols?.filter((s) => s.symbol === symbol)[0]
    if (symbolInfo) {
      // const pricePrecision = symbolInfo.pricePrecision
      const tickSize = getTickSize(symbolInfo)
      // ticksToStop = getPriceSteps(currentPrice, stopLoss, tickSize)
    }
  }

  // const tpPrc = formatNumber(takeChange, 2)

  let backgroundColor = 'transparent'
  // if (isReal) {
  //   backgroundColor = '#00ffff22'
  // }
  // if (mustBeClosed || failed) {
  //   backgroundColor = '#ff000022'
  // }

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const direction = isup ? 'long' : 'short'

  return (<ListItem
    button
    dense={isSmallScreen}
    onClick={() => {
      console.log('position details', symbol)
    }}
    // selected={replay && replay._id === r._id}
    key={`screen-${symbol}-${direction}`}
    // component={r.insertedId ? Link : undefined}
    // to={r.insertedId ? `/replays/${mode}/${r.insertedId}` : undefined}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      // width: '16%',
      overflow: 'hidden',
      backgroundColor
    }}
  >
    <Box sx={{
      mt: 0,
      width: 'auto',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      gap: 1
    }}>
      <ListItemText
        primary={`${symbol}`}
        secondary={`ATR: ${formatNumber(score)}`}
        sx={{ flexBasis: { xs: '10rem', xl: '12rem' }, flexGrow: 2, mr: 0, color: direction === 'long' ? 'lime' : 'red' }}
      />
      {/* <ListItemText
        primary={`$${formatNumber(changeUSD, 2)}`}
        secondary={`${formatNumber(change, 2)}%`}
        sx={{ flexBasis: { xs: '3rem', xl: '3rem' }, flexGrow: 1, mr: 2, color: change > 0 ? 'lime' : 'red' }}
      /> */}
      {/* <ListItemText
        primary={enterVolumeText}
        secondary={enterText}
        sx={{ flexBasis: { xs: '7rem', xl: '7rem' }, flexGrow: 1, mr: { xs: 0, lg: 2 } }}
      /> */}
      {/* <ListItemText
        primary={takeText}
        secondary={stopText}
        sx={{
          flexBasis: '15rem',
          flexGrow: 2,
          mr: 2,
          display: { xs: 'none', lg: 'block' },
          //color: closePoints > 0 ? 'lime' : 'white'
        }}
        primaryTypographyProps={{
          sx: { color: closePoints ? 'lime' : 'white' }
        }}
        secondaryTypographyProps={{
          sx: {
            color: stopChangeUSD > 0
              ? 'lime'
              : hardStopLoss !== stopLoss
                ? 'yellow'
                : 'white'
          }
        }}
      /> */}
      {/* <ListItemText
        primary={`SL Ticks: ${ticksToStop} TSL: ${trailingTicks}`}
        secondary={`HSL Ticks: ${ticksToHardStop}`}
        sx={{ flexBasis: '13rem', mr: 2, display: { xs: 'none', lg: 'block' } }}
      /> */}
      {/* <ListItemText
        primary={`${strategy}`}
        secondary={`lvl ${lvlPrice.toPrecision(5)}`}
        sx={{ flexBasis: { xs: '8rem', xl: '8rem' }, mr: 2 }}
      /> */}
      {/* <ListItemText
        primary={`current ${formatNumber(currentPrice, 5)}`}
        secondary={`${direction} ${formatNumber(avgEntryPrice, 5)}`}
        sx={{ flexBasis: '10rem', mr: 2, display: { xs: 'none', lg: 'block' } }}
      /> */}


    </Box>
  </ListItem>)
}

function getTickSize(symbolInfo) {
  const priceFilter = symbolInfo.filters.find(f => f.filterType === 'PRICE_FILTER')
  const tickSize = parseFloat(priceFilter.tickSize)

  return tickSize
}

function getPriceSteps(target, current, tickSize) {
  const delta = Math.abs(target - current)
  const steps = Math.floor(delta / tickSize)

  return steps
}

function formatNumber(num, significantDigits = 3) {
  // Включаем массив суффиксов внутрь функции для полной инкапсуляции
  const suffixes = {
    1000: 'K',
    1000000: 'M',
    1000000000: 'B',
    1000000000000: 'T'
  };

  const absNum = Math.abs(num);
  const sigDelta = Math.max(0, significantDigits - 3)

  if (absNum >= 1000) {
    let scale = 1;
    let suffix = '';
    let scaledNum = num;

    // Определяем нужный делитель и суффикс
    while (scaledNum >= 1000 && scale <= 1e12) {
      scale *= 1000;
      scaledNum = num / scale;
      suffix = suffixes[scale] || '';
    }

    // Округляем число в зависимости от величины
    if (scaledNum >= 100) {
      return (scaledNum.toFixed(sigDelta) + suffix);
    } else if (scaledNum >= 10) {
      return (scaledNum.toFixed(sigDelta + 1) + suffix);
    } else {
      return (scaledNum.toFixed(sigDelta + 2) + suffix);
    }
  } else if (absNum < 1) {
    // Используем toPrecision для чисел меньше 1
    return num.toPrecision(significantDigits);
  } else {

    // Числа от 1 до 999, применяем логику похожую на большие числа, но без суффикса
    if (num >= 100) {
      return num.toFixed(sigDelta);
    } else if (num >= 10) {
      return num.toFixed(sigDelta + 1);
    } else {
      return num.toFixed(sigDelta + 2);
    }
  }
}

function msToHHMMSS(ms) {
  const seconds = Math.floor(ms / 1000)
  const hh = Math.floor(seconds / 3600)
  const mm = Math.floor((seconds - hh * 3600) / 60)
  const ss = seconds - hh * 3600 - mm * 60

  const hText = hh === 0 ? '' : `${hh}:`
  return `${hText}${mm}:${ss}`
}