import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import localforage from 'localforage';
import * as d3 from "d3";
import * as fc from "d3fc";
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  indicatorsChartHeightState,
  indicatorsChartWidthState,
} from '#state/gui';
import {
  currentSymbolState,
  levelsShowedState,
  levelsPercentileState,
  levelsMinimumVolumeState,
  levelsMinimumVolumeSpotState,
  updateRateIntervalState,
  orderBookScaleState,
  enableZoomState,
} from '#state';
import {
  wsTimerState,
  candlesState,
  candlesSelector,
  clustersState,
  clustersSelector,
  tradesState,
  tradesSelector,
} from '#state/data';

import {
  balanceState,
  coinState,
  lastPriceState,
  activeOrderState
} from '#state/trades';

import {
  weightedAvgSelector,
  ema1Selector,
  ema2Selector,
  ma3Selector,
} from '#state/indicators';

import { getSecondStart } from '../../utils';

import { EMA, SMA } from '@debut/indicators';

import { tradeBuffers, orderBooks } from '#state/local';

let cacheStartTime
let featureTradePointsCache = []
let featureUpdatePointsCache = []
let featureBookPointsCache = []

let emaPointsCaches = []
let emaPoints1Cache = []
let emaPoints2Cache = []
let emaPoints3Cache = []
let emaPoints4Cache = []
let minYCache = Infinity
let maxYCache = -Infinity

let spotTradePointsCache = []
let spotUpdatePointsCache = []
let spotBookPointsCache = []

const e1t = 24
const ma1 = new EMA(e1t);
const ma2 = new EMA(e1t);

const e2t = 24
const ma3 = new EMA(e2t);
const ma4 = new EMA(e2t);

const forceYScale = false // [0, 55000] // false

const showEmas = true
const showAggregatedTrades = true
const tradesAggregationInterval = 500
const tradesAggregationInterval2 = 500
const emaSource1 = 'deltaVolumeUSD' // red
const emaSource2 = 'deltaTrades' // green
const source1Mult = 1
const source2Mult = 1
// lastWeightedAvg,
// totalVolume, deltaVolume,
// buyVolume, sellVolume,
// minPrice, maxPrice,
// totalVolumeUSD, deltaVolumeUSD,
// buyVolumeUSD, sellVolumeUSD,
// totalTrades, deltaTrades,
// buyTrades, sellTrades,
// 
const showFuturesTrades = true
const showFuturesUpdates = false
const showFuturesBook = false
// 
const showSpotTrades = false
const showSpotUpdates = false
const showSpotBook = false
// 
const minSizeFuturesTrades = 2
const minSizeSpotTrades = 2
const minSizeFuturesBook = 1
const minSizeSpotBook = 1
// 
let minVolumeKUSD = 100000
let minVolumeSpotKUSD = 100000


const D3FC = (props) => {
  const myRef = useRef();
  const {
    featureTradePoints,
    spotTradePoints,
    emaPoints1,
    emaPoints2,
    emaPoints3,
    emaPoints4,
    futuresUpdates,
    futuresBook,
    spotUpdates,
    spotBook,
    scale,
    priceScale,
    keys,
    width,
    height,
    timeWindow,
    timePoints,
  } = props

  // console.log('indicatorsChart', props)

  const zoomEnabled = useRecoilValue(enableZoomState);

  // const updateRateInterval = useRecoilValue(updateRateIntervalState);

  // console.log('spotUpdates', spotUpdates, 'spotBook', spotBook)

  const { minX, maxX, minY, maxY } = scale

  const minYPoints = d3.min(featureTradePoints, d => d.y)
  const maxYPoints = d3.max(featureTradePoints, d => d.y)

  const maxEma1Y = d3.max(emaPoints1, d => d.y)
  const minEma1Y = d3.min(emaPoints1, d => d.y)
  // find best source1Mult to fit with minY, maxY
  const source1MultMin = 1 / minEma1Y * minYPoints
  const source1MultMax = 1 / maxEma1Y * maxYPoints
  const source1Mult = Math.min(source1MultMax, source1MultMin)


  const maxEma2Y = d3.max(emaPoints2, d => d.y)
  const minEma2Y = d3.min(emaPoints2, d => d.y)
  // find best source2Mult to fit with minY, maxY
  const source2MultMin = 1 / minEma2Y * minYPoints
  const source2MultMax = 1 / maxEma2Y * maxYPoints
  const source2Mult = Math.min(source2MultMax, source2MultMin)

  // console.log('maxEma1Y', maxEma1Y, maxEma2Y)
  // console.log('scale', scale)

  const xwindow = maxX + 0.01
  const x = d3.scaleLinear()
    // .domain([0.8, xwindow]) //.domain([minX, maxX]); //.domain([0, 60])
    .domain([xwindow - timeWindow, xwindow]) //.domain([minX, maxX]); //.domain([0, 60])
  // .domain([0.82, 1.125])

  const yscale = forceYScale ? forceYScale : [minYPoints >= 0 ? minYPoints * (0) : minYPoints * (1.1), maxYPoints * (1.1)]
  const y = d3.scaleLinear()
    // .domain([-50, 1400000])
    // .domain([0, 140])
    .domain(yscale)
  // .domain([minY * (1 - priceScale), maxY * (1 + priceScale)])//.domain([minY, maxY]) //.domain([-100, 300]) //

  // .tickArguments([5])
  // .tickCenterLabel(true);

  // *******************************************************
  // Futures Trades
  // *******************************************************
  let extSize = fc
    .extentLinear()
    .accessors([d => d.s])(featureTradePoints)

  let alpha = d3
    .scaleLinear()
    .range([0.5, 1])
    .domain(extSize);

  let fillColor = fc
    .webglFillColor()
    .value(d => {
      const a = alpha(d.s)
      return getColorFeatureTrades(a, d)
    })
    .data(featureTradePoints);

  // let size = d3
  //   .scaleLinear()
  //   .range([3, 300])
  //   .domain(extSize);



  const seriesFeatureTrades = fc
    .seriesWebglPoint()
    .crossValue(d => d.x)
    .mainValue(d => d.y)
    // .type(d3.symbolSquare)
    .size(d => futuresTradeSize(d, minSizeFuturesTrades))
    .decorate(program => {
      // Set the color of the points.
      fillColor(program);
      // Enable blending of transparent colors.
      const context = program.context();
      if (context) {
        context.enable(context.BLEND);
        context.blendFunc(context.SRC_ALPHA, context.ONE_MINUS_SRC_ALPHA);
      }
    });

  // *******************************************************
  // Spot Trades
  // *******************************************************
  let extSizeSpotTrades = fc
    .extentLinear()
    .accessors([d => d.s])(spotTradePoints)

  let alphaSpotTrades = d3
    .scaleLinear()
    .range([0.5, 1])
    .domain(extSizeSpotTrades);

  let fillColorSpotTrades = fc
    .webglFillColor()
    .value(d => {
      const a = alphaSpotTrades(d.s)
      return getColorSpotTrades(a, d.m)
    })
    .data(spotTradePoints);


  const seriesSpotTrades = fc
    .seriesWebglPoint()
    .crossValue(d => d.x)
    .mainValue(d => d.y)
    // .type(d3.symbolSquare)
    .size(d => spotTradeSize(d, minSizeSpotTrades))
    .decorate(program => {
      // Set the color of the points.
      fillColorSpotTrades(program);
      // Enable blending of transparent colors.
      const context = program.context();
      if (context) {
        context.enable(context.BLEND);
        context.blendFunc(context.SRC_ALPHA, context.ONE_MINUS_SRC_ALPHA);
      }
    });

  // *******************************************************
  // Points Connections
  // *******************************************************

  const points1 = fc
    .seriesWebglLine()
    .crossValue(d => d.x)
    .mainValue(d => d.y)
    .decorate((program, _, index) => {
      fc
        .webglStrokeColor()
        .value(() => {
          // const { r, g, b, opacity } = d3.color(color(index));
          // return [r / 255, g / 255, b / 255, opacity];
          return [217 / 256, 67 / 256, 8 / 256, 1]
        })
        .data(featureTradePoints)(program);
    });

  // *******************************************************
  // Futures Emas
  // *******************************************************

  const ema1 = fc
    .seriesWebglLine()
    .crossValue(d => d.x)
    .mainValue(d => d.y * source1Mult)
    .decorate((program, _, index) => {
      fc
        .webglStrokeColor()
        .value(() => {
          // const { r, g, b, opacity } = d3.color(color(index));
          // return [r / 255, g / 255, b / 255, opacity];
          return [255 / 256, 1 / 256, 1 / 256, 1];
        })
        .data(featureTradePoints)(program);
    });
  const ema2 = fc
    .seriesWebglLine()
    .crossValue(d => d.x)
    .mainValue(d => d.y * source2Mult)
    .decorate((program, _, index) => {
      fc
        .webglStrokeColor()
        .value(() => {
          return [1 / 256, 255 / 256, 1 / 256, 1];
        })
        .data(featureTradePoints)(program);
    });


  const ema3 = fc
    .seriesWebglLine()
    .crossValue(d => d.x)
    .mainValue(d => d.y * source1Mult)
    .decorate((program, _, index) => {
      fc
        .webglStrokeColor()
        .value(() => {
          return [155 / 256, 5 / 256, 5 / 256, 1];
        })
        .data(featureTradePoints)(program);
    });

  const ema4 = fc
    .seriesWebglLine()
    .crossValue(d => d.x)
    .mainValue(d => d.y * source2Mult)
    .decorate((program, _, index) => {
      fc
        .webglStrokeColor()
        .value(() => {
          return [5 / 256, 155 / 256, 5 / 256, 1];
        })
        .data(featureTradePoints)(program);
    });


  // *******************************************************
  // Time Points
  // *******************************************************

  const timePointsSeries = fc
    .seriesCanvasBar()
    .bandwidth((d, i) => {
      return 3
    })
    .orient('vertical')
    .crossValue(d => d.x)
    .mainValue(d => d.m)
    .baseValue(d => d.b)
    .decorate((ctx, d, index) => {
      if (d.back === 'openShort' || d.back === 'closeLong') {
        ctx.fillStyle = `rgba(255, 100, 100, ${0.5})`;
      } else if (d.back === 'openLong' || d.back === 'closeShort') {
        ctx.fillStyle = `rgba(100, 255, 100, ${0.5})`;
      } else if (d.back === 'start' || d.back === 'end') {
        ctx.fillStyle = `rgba(255, 255, 255, ${0.3})`;
      } else {
        ctx.fillStyle = `rgba(100, 100, 255, ${0.3})`;
      }
    });

  const gridline = fc.annotationCanvasGridline()
  gridline.xDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });
  gridline.yDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });

  let canvasSeries = fc
    .seriesCanvasMulti()
    .series([gridline, timePointsSeries])
    .mapping((data, index, series) => {
      switch (series[index]) {
        case timePointsSeries:
          return data.timePoints;
        case gridline:
          return data.c1;
        default:
          return null
      }
    });

  // *******************************************************
  // Combine Series
  // *******************************************************

  const dataContainer = {
    featureTrades: featureTradePoints,
    spotTrades: spotTradePoints,
    points1: featureTradePoints,
    ema1: emaPoints1,
    ema2: emaPoints2,
    ema3: emaPoints3,
    ema4: emaPoints4,
    futuresUpdates: futuresUpdates,
    futuresBook: futuresBook,
    spotUpdates: spotUpdates,
    spotBook: spotBook,
    timePoints: timePoints,
  }

  // console.log('dataContainer', dataContainer)
  const showSeries = []
  // order by z-index
  // trades
  if (showSpotTrades) showSeries.push(seriesSpotTrades)
  if (showFuturesTrades) {
    // showSeries.push(points1)
    showSeries.push(seriesFeatureTrades)
  }
  // emas
  if (showEmas) {
    showSeries.push(ema4, ema3, ema2, ema1)
  }


  let multiSeries = fc
    .seriesWebglMulti()
    .series(showSeries)
    .mapping((dc, index, series) => {
      switch (series[index]) {
        case ema1:
          return dc.ema1;
        case ema2:
          return dc.ema2;
        case ema3:
          return dc.ema3;
        case ema4:
          return dc.ema4;
        // connections
        case points1:
          return dc.points1;
        // futures
        case seriesFeatureTrades:
          return dc.featureTrades;
        // spot
        case seriesSpotTrades:
          return dc.spotTrades;
        case timePointsSeries:
          return dc.timePoints;
        default:
          return null
      }
    });




  let chart = fc
    .chartCartesian(x, y)
    .webglPlotArea(multiSeries)
    .canvasPlotArea(canvasSeries)

  if (zoomEnabled) {
    const zoom = fc.zoom().on('zoom', render);
    chart.decorate(sel => {
      sel.enter().call(zoom, x, y);
    })
  }

  function render() {
    d3.select(`#d3fcind-${keys}`)
      .datum(dataContainer)
      .call(chart);
  }

  useEffect(() => {
    reinitChart({ x, y, multiSeries, render })
  }, [featureTradePoints, emaPoints1, emaPoints2, emaPoints3, emaPoints4, x, y, multiSeries, render]); // keys

  useEffect(() => {
    console.log('tradesChart resize', width, height)
    reinitChart({ x, y, multiSeries, render, zoomEnabled })
  }, [height, width, zoomEnabled]);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     render()
  //   }, updateRateInterval)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [updateRateInterval, render]);

  return <div
    key={`d3fcind-${keys}`}
    id={`d3fcind-${keys}`}
    className='d3fc tradesChart'
    ref={myRef}
    style={{
      width: '100%',
      height,
    }}
  />;
};

function reinitChart(props) {
  let { x, y, multiSeries, render, zoomEnabled } = props

  const gridline = fc.annotationCanvasGridline()
  gridline.xDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });
  gridline.yDecorate((context, _, index) => {
    context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
  });

  const chart = fc.chartCartesian(x, y)
    .webglPlotArea(multiSeries)
    .canvasPlotArea(gridline)

  if (zoomEnabled) {
    const zoom = fc.zoom().on('zoom', render);
    chart.decorate(sel => {
      sel.enter().call(zoom, x, y);
    })
  }

  render();
}




export const IndicatorsChart = ({ replay, replayData, next }) => {
  // resize state
  const [resizing, setResizing] = useState(false);
  const [width, setWidth] = useRecoilState(indicatorsChartWidthState);
  const [height, setHeight] = useRecoilState(indicatorsChartHeightState);
  // real state
  const updateRateInterval = useRecoilValue(updateRateIntervalState);
  const wsTimer = useRecoilValue(wsTimerState);
  const currentSymbol = useRecoilValue(currentSymbolState);
  // const trades = useRecoilValue(tradesState);
  const levelsPercentile = useRecoilValue(levelsPercentileState);
  const minVolumeUSD = useRecoilValue(levelsMinimumVolumeState)
  const minVolumeSpotUSD = useRecoilValue(levelsMinimumVolumeSpotState);
  const priceScale = useRecoilValue(orderBookScaleState);
  const zoomEnabled = useRecoilValue(enableZoomState);
  // console.log('trades', trades)
  minVolumeKUSD = minVolumeUSD * 1000
  minVolumeSpotKUSD = minVolumeSpotUSD * 1000
  // fix for d3fc-zoom
  const [rerender, setRerender] = useState(0)

  // useEffect(() => {
  //   // console.log('wsTimer', wsTimer)
  // }, [wsTimer])

  useEffect(() => {
    setRerender(rerender + 1)
  }, [width, height, minVolumeUSD, minVolumeSpotUSD, levelsPercentile, currentSymbol, zoomEnabled])

  useEffect(() => {
    featureTradePointsCache = []
    spotTradePointsCache = []
    emaPoints1Cache = []
    emaPoints2Cache = []
    emaPoints3Cache = []
    emaPoints4Cache = []
    minYCache = Infinity
    maxYCache = -Infinity
  }, [currentSymbol])

  useEffect(() => {
    featureUpdatePointsCache = []
    featureBookPointsCache = []
    emaPoints1Cache = []
    emaPoints2Cache = []
    emaPoints3Cache = []
    emaPoints4Cache = []
    minYCache = Infinity
    maxYCache = -Infinity
  }, [minVolumeUSD, levelsPercentile, priceScale, currentSymbol])

  useEffect(() => {
    spotUpdatePointsCache = []
    spotBookPointsCache = []
    emaPoints1Cache = []
    emaPoints2Cache = []
    emaPoints3Cache = []
    emaPoints4Cache = []
    minYCache = Infinity
    maxYCache = -Infinity
  }, [minVolumeSpotUSD, levelsPercentile, priceScale, currentSymbol])


  let minTime = Infinity;
  let maxTime = -Infinity;
  let minPrice = Infinity;
  let minY = minYCache || Infinity;
  let maxY = maxYCache || -Infinity;
  let maxPrice = -Infinity;
  let minVolume = Infinity;
  let tradesStartTime = 0
  let startTime

  if (tradeBuffers.futures.length) {
    if (!cacheStartTime) cacheStartTime = tradeBuffers.futures[0].T
    const p = tradeBuffers.futures[0].p;
    // minPrice = p;
    // maxPrice = p;
    tradesStartTime = tradeBuffers.futures[0].T
    startTime = tradeBuffers.futures[0].T;
    minTime = (startTime - cacheStartTime) / 60000;
    maxTime = (Date.now() + 1000 - cacheStartTime) / 60000
    // maxTime = minTime + 2;
  }

  let timeWindow = 2
  if (replay && tradeBuffers.futures.length) {
    const start = tradeBuffers.futures[0].T
    const end = tradeBuffers.futures[tradeBuffers.futures.length - 1].T
    cacheStartTime = start
    maxTime = (end + 1000 - cacheStartTime) / 60000
    timeWindow = Math.round((end - start) / 60000)
    console.log('replay', replay, timeWindow, cacheStartTime, startTime, tradesStartTime, minTime, maxTime)
  }


  let emaPoints1 = []
  let emaPoints2 = []
  let emaPoints3 = []
  let emaPoints4 = []

  let featureTradePoints = []
  let spotTradePoints = []
  let featureTradesBuffer = []
  let featureTradesBuffer2 = []
  let featureTradesBuffer3 = []
  let spotTradesBuffer = []
  let lastTime = 0
  let lastTime2 = 0
  let lastTime3 = 0

  // *******************************************************
  // Futures Trades
  // *******************************************************

  if (showFuturesTrades) {
    let startIndex = -1
    featureTradePointsCache.forEach((p, i) => {
      if (startIndex === -1 && p.t >= tradesStartTime) startIndex = i
      if (p.y < minPrice) minPrice = p.y
      if (p.y > maxPrice) maxPrice = p.y
    })

    // remove old trades
    const filteredTradesPoints = startIndex >= 0 ? featureTradePointsCache.slice(startIndex) : featureTradePointsCache
    featureTradePoints = filteredTradesPoints

    // 
    const cacheEndTime = featureTradePoints.length ? featureTradePoints[featureTradePoints.length - 1].t : 0
    const cacheEndId = featureTradePoints.length ? featureTradePoints[featureTradePoints.length - 1].i : 0

    const tradeUpdates = tradeBuffers.futures.filter((t) => {
      const tradeId = t.a // .t trade id for @trades, .a for @aggTrades
      return t.T >= cacheEndTime && (replay ? true : tradeId > cacheEndId)
  })

    let prevP = null
    let prevQ = null
    let cumulativeDelta = 0

    // TODO: fix this cache problems
    if (tradeUpdates.length > 1000) {
      // console.log('tradeUpdates', tradeUpdates.length)
    } else {
      // console.log('tradeUpdates', tradeUpdates.length)

      emaPoints1 = emaPoints1Cache
      emaPoints2 = emaPoints2Cache
      emaPoints3 = emaPoints3Cache
      emaPoints4 = emaPoints4Cache
    }

    for (const trade of tradeUpdates) {
      try {
        const p = trade.p;
        const v = trade.q;
        if (prevP && prevQ) {
          // const
        }
        prevP = p
        prevQ = v

        let ignore = false;

        //   {
        //     "T": 1696826941810,
        //     "t": 62161871,
        //     "m": false,
        //     "p": 1.2837,
        //     "q": 102.5
        // }

        if (v < minVolume) minVolume = v;
        const ut = (trade.T - cacheStartTime) / 60000

        if (ut < minTime) minTime = ut
        else if (ut > maxTime) maxTime = ut

        // if (p < minPrice && minPrice / p > 1.02) {
        //   // console.log('ignore top', p, maxPrice);
        //   ignore = true
        // }
        // if (p > maxPrice && p / maxPrice > 1.02) {
        //   // console.log('ignore bottom', p, maxPrice);
        //   ignore = true
        // }
        // if (ignore) {
        //   // console.log('ignore trade', p, maxPrice, ignoreTop, minPrice, ignoreBottom);
        //   // ignore = true;
        // } else {
        //   if (p < minPrice) minPrice = p;
        //   else if (p > maxPrice) maxPrice = p;
        // }



        const start = Math.floor(trade.T / tradesAggregationInterval) * tradesAggregationInterval
        const start2 = Math.floor(trade.T / tradesAggregationInterval2) * tradesAggregationInterval2

        if (lastTime !== start) {
          if (featureTradesBuffer.length) {
            // const lastWeightedAvg = weightedAvg(featureTradesBuffer);

            const wavg = weightedAvg(featureTradesBuffer);
            const {
              lastWeightedAvg,
              totalVolume, deltaVolume,
              buyVolume, sellVolume,
              minPrice, maxPrice,
              totalVolumeUSD, deltaVolumeUSD,
              buyVolumeUSD, sellVolumeUSD,
              totalTrades, deltaTrades,
              buyTrades, sellTrades,
            } = wavg


            cumulativeDelta += deltaVolume
            const deltaPricePercent = (maxPrice - minPrice) / minPrice * 100
            const dpv = deltaVolume
            const newY = wavg[emaSource1] / tradesAggregationInterval * 1000 // per second // totalVolume * lastWeightedAvg
            const newY2 = wavg[emaSource2] / tradesAggregationInterval * 1000 // per second // totalVolume * lastWeightedAvg

            if (newY < minY) minY = newY;
            else if (newY > maxY) maxY = newY;

            if (showEmas) {
              // console.log('featureTradesBuffer lastWeightedAvg', lastWeightedAvg);
              const lastMA = ma1.nextValue(newY)
              const lastMA2 = ma2.nextValue(newY2)
              // const lastMA2 = ma2.nextValue(newY)
              // const lastMA3 = ma3.nextValue(newY)

              if (lastMA) {
                emaPoints1.push({
                  x: ut,
                  y: lastMA || totalVolumeUSD,
                })
              }
              // if (lastMA2) {
              //   emaPoints2.push({
              //     x: ut,
              //     y: lastMA2 || totalVolumeUSD,
              //   })
              // }
              if (lastMA2) {
                emaPoints2.push({
                  x: ut,
                  y: lastMA2 || totalVolumeUSD,
                })
              }
            }

            if (showAggregatedTrades) {
              const lastTrade = featureTradesBuffer[featureTradesBuffer.length - 1]
              const tradeId = lastTrade.a // .t trade id for @trades, .a for @aggTrades
              // const c = {
              //   t: lastTrade.T,
              //   i: lastTrade.t,
              //   x: ut,
              //   y: lastWeightedAvg,
              //   s: totalVolume,
              //   u: lastWeightedAvg * totalVolume,
              //   m: deltaVolume < 0 ? true : false,  
              // }

              // featureTradePoints.push(c)


              const sell = {
                t: lastTrade.T,
                i: tradeId,
                x: ut,
                y: newY, //lastWeightedAvg,
                s: sellVolume,
                u: lastWeightedAvg * sellVolume,
                m: true,
                p: 1
              }

              const buy = {
                t: lastTrade.T,
                i: tradeId,
                x: ut,
                y: newY, //lastWeightedAvg,
                s: buyVolume,
                u: lastWeightedAvg * buyVolume,
                m: false,
                p: 1
              }

              if (deltaVolume >= 0) {
                featureTradePoints.push(buy)
                featureTradePoints.push(sell)
              } else {
                featureTradePoints.push(sell)
                featureTradePoints.push(buy)
              }
            }

            featureTradesBuffer = []
          }
          lastTime = start
          // currentIndex++
        } else {
          featureTradesBuffer.push(trade)
          lastTime = start
          // console.log('featureTradesBuffer push', featureTradesBuffer);
        }

        if (lastTime2 !== start2) {
          if (featureTradesBuffer2.length) {
            // const lastWeightedAvg = weightedAvg(featureTradesBuffer);
            const wavg = weightedAvg(featureTradesBuffer2);
            const {
              lastWeightedAvg,
              totalVolume, deltaVolume,
              buyVolume, sellVolume,
              minPrice, maxPrice,
              totalVolumeUSD, deltaVolumeUSD,
              buyVolumeUSD, sellVolumeUSD,
              totalTrades, deltaTrades,
              buyTrades, sellTrades,
            } = wavg



            cumulativeDelta += deltaVolume
            const deltaPricePercent = (maxPrice - minPrice) / minPrice * 100
            const dpv = deltaVolume
            const newY = wavg[emaSource1] / tradesAggregationInterval2 * 1000 // per second  // totalVolume * lastWeightedAvg
            const newY2 = wavg[emaSource2] / tradesAggregationInterval2 * 1000 // per second  // totalVolume * lastWeightedAvg

            if (newY < minY) minY = newY;
            else if (newY > maxY) maxY = newY;

            if (showEmas) {
              // console.log('featureTradesBuffer lastWeightedAvg', lastWeightedAvg);

              const lastMA1 = ma3.nextValue(newY)

              if (lastMA1) {
                emaPoints3.push({
                  x: ut,
                  y: lastMA1 || totalVolumeUSD,
                })
              }

              const lastMA2 = ma4.nextValue(newY2)

              if (lastMA2) {
                emaPoints4.push({
                  x: ut,
                  y: lastMA2 || totalVolumeUSD,
                })
              }
            }

            if (showAggregatedTrades) {
              const lastTrade = featureTradesBuffer2[featureTradesBuffer2.length - 1]
              const tradeId = lastTrade.a // .t trade id for @trades, .a for @aggTrades

              const sell = {
                t: lastTrade.T,
                i: tradeId,
                x: ut,
                y: newY, //lastWeightedAvg,
                s: sellVolume,
                u: lastWeightedAvg * sellVolume,
                m: true,
                p: 2
              }

              const buy = {
                t: lastTrade.T,
                i: tradeId,
                x: ut,
                y: newY, //lastWeightedAvg,
                s: buyVolume,
                u: lastWeightedAvg * buyVolume,
                m: false,
                p: 2
              }

              if (deltaVolume >= 0) {
                featureTradePoints.push(buy)
                featureTradePoints.push(sell)
              } else {
                featureTradePoints.push(sell)
                featureTradePoints.push(buy)
              }
            }

            featureTradesBuffer2 = []
          }

          lastTime2 = start
          // currentIndex++
        } else {
          featureTradesBuffer2.push(trade)
          lastTime2 = start2
          // console.log('featureTradesBuffer push', featureTradesBuffer);
        }

        if (!ignore && !showAggregatedTrades) {
          // group trades
          const prevPoint = featureTradePoints.length ? featureTradePoints[featureTradePoints.length - 1] : null
          if (prevPoint && trade.T - prevPoint.t < 100 && trade.p === prevPoint.y && trade.m === prevPoint.m) {
            // console.log('group trades', trade.T, prevPoint.t, trade.p, prevPoint.y, trade.m, prevPoint.m);
            const tradeId = trade.a // .t trade id for @trades, .a for @aggTrades
            prevPoint.s += v
            prevPoint.i = tradeId
            prevPoint.x = ut

          }
          else {
            const c = {
              t: trade.T,
              i: trade.t,
              x: ut,
              y: p,
              s: v,
              u: p * v,
              m: trade.m
            }

            featureTradePoints.push(c)
          }
        }


      } catch (error) {
        console.log('error', error);
      }

    }
  }

  // *******************************************************
  // Spot Trades
  // *******************************************************

  if (showSpotTrades) {
    let startIndex = -1
    spotTradePointsCache.forEach((p, i) => {
      if (startIndex === -1 && p.t >= tradesStartTime) startIndex = i
      if (p.y < minPrice) minPrice = p.y
      if (p.y > maxPrice) maxPrice = p.y
    })

    // remove old trades
    const filteredTradesPoints = startIndex >= 0 ? spotTradePointsCache.slice(startIndex) : spotTradePointsCache
    spotTradePoints = filteredTradesPoints
    // 
    const cacheEndTime = spotTradePoints.length ? spotTradePoints[spotTradePoints.length - 1].t : 0
    const cacheEndId = spotTradePoints.length ? spotTradePoints[spotTradePoints.length - 1].i : 0

    const tradeUpdates = tradeBuffers.spot.filter((t) => t.T >= cacheEndTime && (replay ? true : t.t > cacheEndId))

    for (const trade of tradeUpdates) {
      try {
        const p = trade.p;
        const v = trade.q;
        let ignore = false;

        if (v < minVolume) minVolume = v;
        const ut = (trade.T - cacheStartTime) / 60000

        // if (ut < minTime) minTime = ut
        // else if (ut > maxTime) maxTime = ut

        if (p < minPrice && minPrice / p > 1.01) {
          console.log('ignore top', p, maxPrice);
          ignore = true
        }
        if (p > maxPrice && p / maxPrice > 1.01) {
          console.log('ignore bottom', p, maxPrice);
          ignore = true
        }

        if (ignore) {
          // console.log('ignore trade', p, minPrice, maxPrice);
          // ignore = true;
        } else {
          if (p < minPrice) minPrice = p;
          else if (p > maxPrice) maxPrice = p;
        }

        const start = Math.floor(trade.T / tradesAggregationInterval) * tradesAggregationInterval

        if (lastTime !== start) {
          if (spotTradesBuffer.length) {
            // const lastWeightedAvg = weightedAvg(spotTradesBuffer);
            const [lastWeightedAvg, totalVolume, deltaVolume, buyVolume, sellVolume] = weightedAvg(spotTradesBuffer);

            if (showEmas) {
              // console.log('spotTradesBuffer lastWeightedAvg', lastWeightedAvg);
              // const lastEMA = ema1.nextValue(lastWeightedAvg)
              // const lastEMA2 = ema2.nextValue(lastWeightedAvg)
              const lastma3 = ma3.nextValue(lastWeightedAvg)

              // if (lastEMA) {
              //   emaPoints1.push({
              //     x: ut,
              //     y: lastEMA,
              //   })
              // }
              // if (lastEMA2) {
              //   emaPoints2.push({
              //     x: ut,
              //     y: lastEMA2,
              //   })
              // }
              if (lastma3) {
                emaPoints3.push({
                  x: ut,
                  y: lastma3,
                })
              }
            }

            if (showAggregatedTrades) {
              const lastTrade = spotTradesBuffer[spotTradesBuffer.length - 1]

              // const combined = {
              //   t: lastTrade.T,
              //   i: lastTrade.t,
              //   x: ut,
              //   y: lastWeightedAvg,
              //   s: totalVolume,
              //   u: lastWeightedAvg * totalVolume,
              //   m: deltaVolume < 0 ? true : false,  
              // }
              // spotTradePoints.push(combined)

              const sell = {
                t: lastTrade.T,
                i: lastTrade.t,
                x: ut,
                y: lastWeightedAvg,
                s: sellVolume,
                u: lastWeightedAvg * sellVolume,
                m: true
              }

              const buy = {
                t: lastTrade.T,
                i: lastTrade.t,
                x: ut,
                y: lastWeightedAvg,
                s: buyVolume,
                u: lastWeightedAvg * buyVolume,
                m: false
              }

              if (deltaVolume >= 0) {
                spotTradePoints.push(buy)
                spotTradePoints.push(sell)
              } else {
                spotTradePoints.push(sell)
                spotTradePoints.push(buy)
              }
            }

            spotTradesBuffer = []
          }
          lastTime = start
          // currentIndex++
        } else {
          spotTradesBuffer.push(trade)
          lastTime = start
          // console.log('spotTradesBuffer push', spotTradesBuffer);
        }

        if (!ignore && !showAggregatedTrades) {
          // group trades
          const prevPoint = spotTradePoints.length ? spotTradePoints[spotTradePoints.length - 1] : null
          if (prevPoint && trade.T - prevPoint.t < 100 && trade.p === prevPoint.y && trade.m === prevPoint.m) {
            // console.log('group trades', trade.T, prevPoint.t, trade.p, prevPoint.y, trade.m, prevPoint.m);
            prevPoint.s += v
            prevPoint.i = trade.t
            prevPoint.x = ut

          }
          else {
            const c = {
              t: trade.T,
              i: trade.t,
              x: ut,
              y: p,
              s: v,
              u: p * v,
              m: trade.m
            }

            spotTradePoints.push(c)
          }
        }


      } catch (error) {
        console.log('error', error);
      }

    }
  }


  // *******************************************************
  // Other
  // *******************************************************

  if (minY !== Infinity) minYCache = minY
  if (maxY !== -Infinity) maxYCache = maxY

  const scale = {
    minX: minTime,
    maxX: maxTime,
    minY: minYCache,
    maxY: maxYCache,
  }

  // console.log('tradeBuffers', tradeBuffers);
  // console.log('orderBooks.futures', orderBooks.futures);
  // console.log('orderBooks.spot', orderBooks.spot);

  // console.log('futuresUpdates', futuresUpdates);
  // console.log('futuresBook', futuresBook);

  // console.log('featureTradePoints', featureTradePoints);
  // console.log('spotTradePoints', spotTradePoints);

  // console.log('emaPoints1', emaPoints1);

  featureTradePointsCache = featureTradePoints
  if (emaPoints1.length) emaPoints1Cache = emaPoints1
  if (emaPoints2.length) emaPoints2Cache = emaPoints2
  if (emaPoints3.length) emaPoints3Cache = emaPoints3
  if (emaPoints4.length) emaPoints4Cache = emaPoints4

  spotTradePointsCache = spotTradePoints


  // console.log('spotTradePoints', spotTradePoints);

  if (!featureTradePoints.length) return null

  const totalPoints = featureTradePoints.length + spotTradePoints.length

  // console.log('indicatorsChart calc', minY, emaPoints1)

  const timePoints = []
  if (replay && replayData) {
    const shiftLeft = 0

    if (replayData?.event?.history?.length) {
      for (const ev of replayData.event.history) {
        const eventTime = ev.time
        if (eventTime) {
          const pointTime = (eventTime - startTime) / 60000;
          timePoints.push({
            x: pointTime - shiftLeft,
            b: minYCache,
            m: maxYCache,
            v: 1,
            back: ev.type || 'replay'
          })
        }
      }
    } else {
      const rt = replayData?.event?.now || replayData?.time
      if (rt) {
        const replayTime = rt
        const pointTime = (replayTime - startTime) / 60000;
        timePoints.push({
          x: pointTime - shiftLeft,
          b: minYCache,
          m: maxYCache,
          v: 1,
          back: 'replay'
        })
      }
    }
  }

  return (<>
    <Resizable
      minHeight={200}
      minWidth={100}
      onResizeStop={(e, direction, ref, d) => {
        const newWidth = width + d.width
        const newHeight = height + d.height
        // setWidth(newWidth);
        setHeight(newHeight);
        setResizing(false);
        // localforage.setItem('tradeChartWidthState', newWidth);
        localforage.setItem('indicatorsChartHeightState', newHeight);
      }}
      onResizeStart={(e, direction, ref, d) => {
        setResizing(true);
      }}
      style={{
        boxShadow: '1px 1px 2px 1px rgba(0,0,0,0.5)',
        backgroundColor: resizing ? 'black' : 'transparent',
      }}
    >
      {!replay && totalPoints / updateRateInterval > 1000 ? <div>
        Points:
        Total: {totalPoints + ' '}
        | FTrades: {featureTradePointsCache.length + ' '}
        | FUpdates: {featureUpdatePointsCache.length + ' '}
        | FBook: {featureBookPointsCache.length + ' '}
        | STrades: {spotTradePointsCache.length + ' '}
        | SUpdates: {spotUpdatePointsCache.length + ' '}
        | SBook: {spotBookPointsCache.length + ' '}
      </div> : null}

      <D3FC
        keys={`r-${rerender}`}
        className='d3fc'
        resizing={resizing}
        width={width}
        height={height}
        featureTradePoints={featureTradePoints}
        spotTradePoints={spotTradePoints}
        emaPoints1={emaPoints1}
        emaPoints2={emaPoints2}
        emaPoints3={emaPoints3}
        emaPoints4={emaPoints4}
        scale={scale}
        priceScale={priceScale}
        timeWindow={timeWindow}
        timePoints={timePoints}
      />
    </Resizable>

  </>);
};


function getColorFeatureTrades(a, d) {
  const sell = d.m
  const type = d.p
  if (type === 1) {
    return [217 / 256, 67 / 256, 8 / 256, a]
  } else if (type === 2) {
    return [8 / 256, 157 / 256, 217 / 256, a]
  }

  if (sell) {
    return [217 / 256, 67 / 256, 8 / 256, a]
  } else {
    return [8 / 256, 157 / 256, 217 / 256, a]
  }
}
function getColorSpotTrades(a, sell) {
  if (sell) {
    return [123 / 256, 9 / 256, 216 / 256, a]
  } else {
    return [130 / 256, 216 / 256, 9 / 256, a]
  }
}


function futuresTradeSize(d, minSize) {
  // return size(d.s)
  if (d.u < 1000) return 1 * minSize
  if (d.u < 2000) return 2 * minSize
  if (d.u < 4000) return 4 * minSize
  if (d.u < 8000) return 8 * minSize
  if (d.u < 16000) return 16 * minSize
  if (d.u < 32000) return 24 * minSize
  if (d.u < 64000) return 32 * minSize
  if (d.u < 128000) return 40 * minSize
  if (d.u < 256000) return 48 * minSize
  if (d.u < 512000) return 56 * minSize
  if (d.u < 1024000) return 64 * minSize
  if (d.u < 2048000) return 72 * minSize
  if (d.u < 4096000) return 80 * minSize
  return 100 * minSize
}

function spotTradeSize(d, minSize) {
  if (d.u < 1000) return 1 * minSize
  if (d.u < 2000) return 2 * minSize
  if (d.u < 4000) return 4 * minSize
  if (d.u < 8000) return 8 * minSize
  if (d.u < 16000) return 16 * minSize
  if (d.u < 32000) return 24 * minSize
  if (d.u < 64000) return 32 * minSize
  if (d.u < 128000) return 40 * minSize
  if (d.u < 256000) return 48 * minSize
  if (d.u < 512000) return 56 * minSize
  if (d.u < 1024000) return 64 * minSize
  if (d.u < 2048000) return 72 * minSize
  if (d.u < 4096000) return 80 * minSize
  return 100 * minSize
}

function getColorFutures2(a, d) {
  const minAlpha = 0.1
  const alpha = Math.max(minAlpha, a)
  if (a > 0.8)
    return [alpha * 0.7, alpha * 0.1, alpha * 0.99, alpha]
  else if (a <= 0.8 && a > 0.6)
    return [alpha * 0.8, alpha * 0.1, alpha * 0.1, alpha]
  else if (a <= 0.6 && a > 0.3)
    return [alpha * 0.01, alpha * 0.5, alpha * 0.01, alpha]
  else if (a <= 0.3 && a > 0.05)
    return [alpha * 0.3, alpha * 0.5, alpha * 0.8, alpha]
  else if (a <= 0.05)
    return [alpha * 0.1, alpha * 0.3, alpha * 0.9, alpha]
}

const weightedAvg = (trades) => {
  let sum = 0, totalVolume = 0, deltaVolume = 0, buyVolume = 0, sellVolume = 0;
  let totalVolumeUSD = 0, deltaVolumeUSD = 0, buyVolumeUSD = 0, sellVolumeUSD = 0;
  let totalTrades = 0, deltaTrades = 0, buyTrades = 0, sellTrades = 0;
  let minPrice = Infinity, maxPrice = -Infinity;

  trades.forEach(trade => {
    if (trade.p < minPrice) minPrice = trade.p
    if (trade.p > maxPrice) maxPrice = trade.p

    const q = trade.q
    sum += trade.p * q
    totalVolume += q;
    totalVolumeUSD += trade.p * q;
    totalTrades++
    if (trade.m) {
      deltaVolume -= q
      sellVolume += q
      deltaVolumeUSD -= trade.p * q;
      sellVolumeUSD += trade.p * q;
      sellTrades++
      deltaTrades--
    } else {
      deltaVolume += q
      buyVolume += q
      deltaVolumeUSD += trade.p * q;
      buyVolumeUSD += trade.p * q;
      buyTrades++
      deltaTrades++
    }
  });
  const result = sum / totalVolume;
  return {
    lastWeightedAvg: result,
    totalVolume, deltaVolume,
    buyVolume, sellVolume,
    minPrice, maxPrice,
    totalVolumeUSD, deltaVolumeUSD,
    buyVolumeUSD, sellVolumeUSD,
    totalTrades, deltaTrades,
    buyTrades, sellTrades,
  };
};

// *******************************************************
// Geeting Data Points
// *******************************************************
