import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import localforage from 'localforage';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  mainF1PercentState
} from '#state';

export function MainF1Percent() {
  const [percent, setPercent] = useRecoilState(mainF1PercentState);
  const [value, setValue] = useState(percent * 100);

  useEffect(() => {
    localforage.setItem('mainF1PercentState', percent)
  }, [percent]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    const v = parseFloat(newValue);
    if (v >= 0) {
      const p = parseFloat((v / 100).toFixed(4))
      setPercent(p);
    } else {
      setPercent(0.01);
    }
  };

  return (
    <TextField
      sx={{
        width: '5rem',
      }}
      label="M RT %"
      type="number"
      size='small'
      inputProps={{ min: 0, step: 1 }}
      value={value}
      onChange={handleChange}
    />
  );
}

export default MainF1Percent;