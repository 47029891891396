import React, { useEffect, useState } from 'react';
import localforage from 'localforage';
import { BrowserRouter, Link, Routes, Route, Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { CircularProgress, Box } from '@mui/material';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue
} from 'recoil';
import {
  apiKeyState,
  showControlsState,
  currentSymbolState,
  clusterCandleIntervalState,
  unixTimeState,
  enableZoomState,
  loadCandlesState,
  levelsShowedState,
  currentFactorState,
  bookShowLevelsState,
  bookUpdatesResampleState,
  resampleOnServerState,
  resampleOnServerLevelState,
  mainCandleIntervalState,
  mainWindowSizeState,
  mainFilter2DurationState,
  mainF1PercentState,
  mainF2PercentState,
  tempCandleIntervalState,
  tempWindowSizeState,
  tempFilter2DurationState,
  tempF1PercentState,
  tempF2PercentState,
  levelsPercentileState,
  levelsMinimumVolumeState,
  levelsMinimumVolumeSpotState,
  updateRateIntervalState,
  orderBookScaleState,
  recordsModeState,
} from '#state';

import {
  // state
  stateFilterStratState,
  stateFilterRealState,
  // stats
  statsFilterEventState,
  statsFilterStratState,
  statsFilterWRState,
  statsStartTimeState,
  statsEndTimeState,
  // charts
  tradeChartHeightState,
  tradeChartWidthState,
  clusterChartHeightState,
  clusterChartWidthState,
  candleChartHeightState,
  candleChartWidthState,
  indicatorsChartHeightState,
  indicatorsChartWidthState,
} from '#state/gui';

import Auth from './components/Auth';
import Main from './components/Main';

import { validateApiKey } from './utils';

const stateKeys = {
  showControlsState,
  //
  orderBookScaleState,
  updateRateIntervalState,
  levelsPercentileState,
  levelsMinimumVolume: levelsMinimumVolumeState,
  levelsMinimumVolumeSpot: levelsMinimumVolumeSpotState,
  // 
  mainCandleIntervalState,
  mainWindowSizeState,
  mainFilter2DurationState,
  mainF1PercentState,
  mainF2PercentState,
  tempCandleIntervalState,
  tempWindowSizeState,
  tempFilter2DurationState,
  tempF1PercentState,
  tempF2PercentState,
  // 
  startUnixTime: unixTimeState,
  enableZoomState,
  clusterCandleIntervalState,
  bookUpdatesResample: bookUpdatesResampleState,
  bookShowLevels: bookShowLevelsState,
  currentFactor: currentFactorState,
  levelsShowed: levelsShowedState,
  loadCandles: loadCandlesState,
  currentSymbol: currentSymbolState,
  resampleOnServer: resampleOnServerState,
  resampleOnServerLevel: resampleOnServerLevelState,
  recordsModeState,
  // ************************************************
  // gui
  stateFilterStratState,
  stateFilterRealState,
  // stats
  statsFilterEventState,
  statsFilterStratState,
  statsFilterWRState,
  statsStartTimeState,
  statsEndTimeState,
  // charts
  tradeChartHeightState,
  tradeChartWidthState,
  clusterChartHeightState,
  clusterChartWidthState,
  candleChartHeightState,
  candleChartWidthState,
  indicatorsChartHeightState,
  indicatorsChartWidthState,
}


function App(props) {
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useRecoilState(apiKeyState);

  const stateSetters = {}
  const entries = Object.entries(stateKeys).sort()
  for (const [key, value] of entries) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    stateSetters[key] = useSetRecoilState(value);
  }

  useEffect(() => {
    const run = async () => {
      const savedApiKey = await localforage.getItem('apiKey');

      if (savedApiKey !== null) {
        const valid = await validateApiKey(savedApiKey);
        if (!valid) {
          await localforage.removeItem('apiKey');
        } else {
          window.apiKey = savedApiKey;
          setApiKey(savedApiKey);

          const version = await localforage.getItem('version');
          console.log('version', version);
          if (version !== null) {
            const v = parseFloat(version);
            if (v < parseFloat('2.0')) {
              console.log('clearing storage');
              await localforage.clear();
              await localforage.setItem('version', '2.0');
              await localforage.setItem('apiKey', savedApiKey);
            }
          } else {
            console.log('clearing storage');
            await localforage.clear();
            await localforage.setItem('version', '2.0');
            await localforage.setItem('apiKey', savedApiKey);
          }
        }

      }

      for (const key of Object.keys(stateKeys)) {
        await loadAndSetState(key, stateSetters[key])
      }

      setLoading(false);
    };
    run();
  }, []);

  if (apiKey) {
    console.log('apiKey', apiKey);
  }

  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <CircularProgress />
      </Box>
    );
  } else if (!apiKey) {
    return <Auth />
  } else {
    return <BrowserRouter><Main /></BrowserRouter>
  }
}


export default App;

async function loadAndSetState(key, setState) {
  const saved = await localforage.getItem(key);
  if (saved !== null) {
    try {
      const value = JSON.parse(saved);
      setState(value);
    } catch (error) {
      setState(saved);
    }
  }
}
